button,
.button {
    cursor: pointer;
    color: currentColor;

    &,
    &__container {
        &:disabled,
        &.disabled {
            opacity: 0.3;
        }
    }
}

// Handling error of iOS 15.4, iOS 15.5 of hidding disabled buttons
button:disabled {
    border-color: $charcoal;
}

.button {
    position: relative;
    border: 1px solid transparent;
    font-weight: $button-font-weight;
    text-transform: none;
    letter-spacing: 0.04em;
    font-size: rem-calc(14);
    line-height: rem-calc(21);
    text-decoration: none;
    border-radius: rem-calc(2);
    padding: rem-calc(0 24);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: rem-calc(44);

    &__container {
        padding: rem-calc(10 0);
        margin-bottom: rem-calc(8);

        &:disabled {
            cursor: not-allowed;
        }

        &:focus-visible {
            border-radius: rem-calc(1);
            outline: 1px solid $lemon;
        }

        .button-icon {
            &__wrapper {
                transition: background-color 0.25s ease-out, color 0.25s ease-out;
                border: 1px solid transparent;
                display: inline-flex;
                align-items: center;
                padding: rem-calc(4 16);
                border-radius: rem-calc(24);

                &--double {
                    padding: rem-calc(4 8);
                    gap: rem-calc(18);
                }
            }

            &__span {
                line-height: 0;
            }

            &-svg {
                width: rem-calc(16);
                height: rem-calc(16);
            }
        }

        &--primary {
            &,
            &:hover,
            &:disabled,
            &:focus {
                .button-icon__wrapper {
                    color: $white;
                    background-color: $charcoal;
                }
            }

            &.white {
                &,
                &:hover,
                &:disabled,
                &:focus {
                    .button-icon__wrapper {
                        color: $charcoal;
                        background-color: $white;
                    }
                }
            }
        }

        &--secondary {
            &:not(.white) {
                &,
                &:hover,
                &:disabled {
                    &,
                    &:focus-visible {
                        .button-icon__wrapper {
                            color: $charcoal;
                            background-color: transparent;
                        }
                    }
                    .button-icon__wrapper {
                        border-color: $charcoal;
                    }
                }

                &:disabled,
                &.disabled {
                    .button-icon__wrapper {
                        background-color: transparent;
                        color: $charcoal;
                    }
                }

                &:focus:not(:focus-visible) {
                    .button-icon {
                        &__wrapper {
                            background-color: $charcoal;
                            color: $white;
                        }
                    }
                }
            }

            &.white {
                .button-icon__wrapper {
                    border-color: $white;
                    color: $white;
                }

                &,
                &:hover,
                &:disabled {
                    &,
                    &:focus-visible {
                        .button-icon__wrapper {
                            color: $white;
                            background-color: transparent;
                        }
                    }
                    &:not(:focus-visible) {
                        .button-icon__wrapper {
                            border: 1px solid $white;
                        }
                    }
                }

                &:focus:not(:focus-visible) {
                    .button-icon {
                        &__wrapper {
                            background-color: $white;
                            color: $charcoal;
                        }
                    }
                }
            }
        }

        &--tertiary {
            &,
            &:hover,
            &:focus,
            &:disabled {
                .button-icon__wrapper {
                    background-color: transparent;
                }
            }

            &.white {
                .button-icon__wrapper {
                    color: $white;
                }
            }
        }
    }

    &.button--square {
        width: rem-calc(44);
        height: rem-calc(44);
        padding: rem-calc(14);
    }

    .button-icon {
        &-svg {
            width: rem-calc(16);
            height: rem-calc(16);
            margin-right: 0;

            &--left {
                margin-right: rem-calc(12);
            }

            &--right {
                margin: rem-calc(0 0 0 40);
            }

            &--mocha {
                color: $mocha;
            }

            &--porcelain {
                color: $porcelain;
            }
        }
    }

    &:focus-visible {
        border-radius: rem-calc(1);
        outline: 1px solid $lemon;
    }

    // Primary
    &--primary {
        &,
        &:hover,
        &:disabled,
        &:focus {
            color: $white;
            background-color: $charcoal !important; /* Importants are used to override the Foundation Default Style */
        }

        &.white {
            &,
            &:hover,
            &:disabled,
            &:focus {
                color: $charcoal;
                background-color: $white !important;
            }
        }
    }

    // Secondary
    &--secondary {
        &:not(.white) {
            &,
            &:hover,
            &:disabled {
                &,
                &:focus-visible {
                    color: $charcoal;
                    background-color: transparent;
                }
                &:not(:focus-visible) {
                    border: 1px solid $cloud;

                    &.button-icon--rounded {
                        border-color: $charcoal;
                    }
                }
            }

            &:disabled,
            &.disabled {
                background-color: transparent !important;
                color: $charcoal !important;
                border-color: $charcoal;
            }

            &:focus:not(:focus-visible) {
                background-color: $charcoal;
                border-color: $charcoal;
                color: $white;

                .button-icon-svg {
                    &--mocha {
                        color: $porcelain;
                    }
                }
            }
        }

        &.white {
            &:disabled,
            &.disabled {
                background-color: transparent !important;
                color: $white !important;
                border-color: $white;
            }

            &,
            &:hover,
            &:disabled {
                &,
                &:focus-visible {
                    color: $white;
                    background-color: transparent;
                }
                &:not(:focus-visible) {
                    border: 1px solid $white;
                }
            }

            &:focus:not(:focus-visible) {
                background-color: $white;
                color: $charcoal;

                .button-icon-svg {
                    &--porcelain {
                        color: $mocha;
                    }
                }
            }
        }

        &.size-button {
            padding: rem-calc(11 8);
            min-width: rem-calc(80);
            justify-content: center;

            &--unavailable {
                opacity: 1;

                .size-button__span {
                    color: $fossil !important;
                }
            }

            &--low-in-stock {
                background-color: $white;

                &::before {
                    content: '';
                    width: rem-calc(4);
                    height: rem-calc(4);
                    display: inline-block;
                    background-color: $mocha;
                    border-radius: 50%;
                    margin-right: rem-calc(4);
                }
            }

            &--selected {
                &,
                &:hover,
                &:focus:not(:focus-visible) {
                    border: 1px solid $charcoal !important;
                    background-color: $charcoal;

                    .size-button__span {
                        color: $white;
                    }
                }
            }
        }
        .size-button {
            &__span {
                font-size: rem-calc(12);
                line-height: rem-calc(16);
            }

            &__icon {
                width: rem-calc(12);
                height: rem-calc(12);
                margin-left: rem-calc(6);
            }
        }
    }

    // Tertiary / Clear
    &--tertiary {
        &,
        &:hover,
        &:focus,
        &:disabled {
            background-color: transparent !important;
            border: none;
            text-decoration: underline;
            text-underline-offset: 2px;
        }

        &:not(.white) {
            color: $charcoal !important;
        }

        &.white {
            &,
            &:disabled {
                color: $white !important;
            }
        }
    }

    // Colored
    &--promo {
        &,
        &:hover,
        &:focus,
        &:disabled {
            background-color: transparent !important;

            .cc-womanSite & {
                color: $blush;
                border-color: $blush;
            }

            .cc-manSite & {
                color: $rust;
                border-color: $rust;
            }
        }
    }

    &.extended {
        width: 100%;
        min-width: 100%;
    }

    &.text-white {
        color: white;
    }

    &.extended-for-small-only {
        @include breakpoint(small down) {
            width: 100%;
            min-width: 100%;
        }
    }

    &-group {
        display: flex;
        gap: rem-calc(16);
    }

    &.extended-medium-and-for-small {
        width: rem-calc(240);
        @include breakpoint(small down) {
            width: 100%;
            min-width: 100%;
        }
    }
}

@mixin brand-borders($palette) {
    @each $color, $hex in $palette {
        .border-#{$color} {
            border: 1px solid $hex;
        }
    }
}

.navigation {
    gap: rem-calc(24);
    list-style-type: none;
    @include breakpoint(small down) {
        gap: rem-calc(16);
    }

    &__link {
        padding: rem-calc(11 30);
        border-radius: rem-calc(4);
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        border: 1px solid $cloud;
        font-weight: 400;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        &.selected {
            border-color: $charcoal;
        }

        &--has-icon {
            padding: rem-calc(8 30);
        }
    }

    &__icon {
        width: rem-calc(24);
        height: rem-calc(24);
        margin-left: rem-calc(16);
    }
}

.toggleButton {
    align-items: center;

    &__switch {
        position: relative;
        display: inline-flex;
        width: rem-calc(48);
        height: rem-calc(24);
        border-radius: rem-calc(20);
        cursor: pointer;
        background-color: $cloud;
        transition: all 0.6s ease-in-out;

        &:before {
            content: '';
            position: absolute;
            border-radius: rem-calc(20);
            transition: all 0.4s ease-in-out;
            height: rem-calc(20);
            width: rem-calc(20);
            background-color: $white;
            top: 50%;
            transform: translate(0, -50%);
            left: rem-calc(2);
        }
    }

    &__input {
        &:checked + .toggleButton__switch {
            background-color: $charcoal;
            &::before {
                content: '';
                transform: translate(#{rem-calc(24)}, -50%);
            }
        }
    }

    &__label {
        margin-left: rem-calc(8);
    }
}

.btn {
    &--icon {
        display: inline-flex;
        gap: rem-calc(4);
        align-items: center;
    }
}

//LINK
.cc-link {
    display: inline-flex;
    align-items: center;
    column-gap: rem-calc(8);
    text-decoration: underline;
    text-underline-offset: rem-calc(2);
    text-decoration-color: currentColor;
    transition: text-decoration-color 300ms ease-out;

    &:hover:not(.disabled) {
        text-decoration-color: transparent;
    }

    &_btn {
        //cc-link cc-link_btn
        text-decoration-color: transparent;
        &:hover:not(.disabled) {
            text-decoration-color: currentColor;
        }
    }
}
