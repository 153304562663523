strong,
.strong,
.font-strong {
    font-weight: $global-weight-bold;
}

.dark,
.font-dark {
    font-weight: $global-weight-dark;
}

.font-normal {
    font-weight: $global-weight-normal;
}

.text-capitalized {
    text-transform: capitalize !important;
}

// Headings
h1,
.h1 {
    letter-spacing: rem-calc(0);
    line-height: rem-calc(67);

    &.hero {
        font-size: rem-calc(25);
        letter-spacing: rem-calc(1.5);

        @include breakpoint(medium up) {
            font-size: rem-calc(35);
            letter-spacing: rem-calc(1.7);
        }
    }
}

h2,
.h2 {
    letter-spacing: rem-calc(0);
}

h3,
.h3 {
    letter-spacing: rem-calc(0);
}

p,
span {
    &.caption {
        font-size: rem-calc(24);
        line-height: rem-calc(31);

        &--small {
            font-size: rem-calc(18);
            line-height: rem-calc(24);
        }
    }
}

// Subheadings
.subtitle-one {
    font-size: rem-calc(18);
    letter-spacing: rem-calc(0.5);
    font-weight: $global-weight-dark;
}

.subtitle-two {
    font-size: rem-calc(16);
    letter-spacing: rem-calc(0.9);
    font-weight: $global-weight-dark;
    text-transform: uppercase;
}

.subtitle-three {
    font-size: rem-calc(16);
    letter-spacing: rem-calc(0.7);
    font-weight: $global-weight-dark;
}

.subcategory-tab {
    font-size: rem-calc(15);
    letter-spacing: rem-calc(0.3);
    font-weight: $global-weight-dark;

    @include breakpoint(medium up) {
        letter-spacing: rem-calc(0.5);
    }
}

h4,
.h4 {
    letter-spacing: rem-calc(0);
}

h5,
.h5 {
    letter-spacing: rem-calc(0);
}

h6,
.h6 {
    letter-spacing: rem-calc(0);
}

h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-weight: $global-weight-normal;
}

// Header Modifiers
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
    &.inline {
        display: inline;
    }
}

.font-secondary {
    font-family: $body-font-family-cursive;
}

.font-cursive {
    font-family: $body-font-family-cursive;
    font-style: italic;
}

.uppercase {
    text-transform: uppercase;
}

.lowercase {
    text-transform: lowercase;
}

.capitalize {
    text-transform: capitalize;
}

.underline {
    text-decoration: underline;
}

.no-underline {
    text-decoration: none;
}

address {
    font-style: normal;
}

p,
span,
a,
button,
.button,
label,
input,
dl {
    &.verysmall {
        font-size: rem-calc(10);
        line-height: rem-calc(15);
        letter-spacing: 0.04em;
    }

    &.small {
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        letter-spacing: 0.04em;
    }

    &.medium {
        font-size: rem-calc(16);
        letter-spacing: 0.04em;
    }

    &.regular {
        font-size: rem-calc(14);
        line-height: rem-calc(21);
        letter-spacing: 0.04em;
    }

    &.big {
        font-size: rem-calc(16);
        line-height: rem-calc(24);
        letter-spacing: 0.04em;
    }

    &.huge {
        font-size: rem-calc(35);
        letter-spacing: 0.04em;
    }

    &.light {
        font-weight: $global-weight-normal;
    }

    &.no-margin {
        margin: 0;
    }
}

a {
    font-weight: $global-weight-normal;

    // Hover state
    &.force-hover,
    &.underline {
        text-decoration: underline;
        text-underline-offset: rem-calc(2);
        text-decoration-color: $charcoal;
    }
}

// generate colored links
@mixin brand-links($palette) {
    a {
        @each $color, $hex in $palette {
            &.text-#{$color} {
                color: $hex;

                // Hover state
                &:not(.no-hover):hover,
                &.force-hover,
                &.underline {
                    border-color: $hex;
                }
            }
        }
    }
}

.strike-through {
    text-decoration: line-through;
}

a.disabled:not(.button),
.disabled > a:not(.button) {
    opacity: 0.3;
    cursor: not-allowed;
}

// generate colored paragraphs
@mixin brand-text($palette) {
    div,
    p,
    span,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    i,
    label,
    input {
        @each $color, $hex in $palette {
            &.text-#{$color} {
                color: $hex;
            }
        }
    }
}

// NEW TYPOGRAPHY MIXED

$typeStyleList-fontSize: (
    h1: 56,
    h2: 50,
    h3: 44,
    h4: 38,
    h5: 32,
    caption: 24,
    captionS: 18,
    textL: 16,
    text: 14,
    textS: 12,
    micro: 10,
);
$typeStyleList-lineHeight: (
    h1: 67.2,
    h2: 60,
    h3: 55,
    h4: 47.5,
    h5: 41.6,
    caption: 31.2,
    captionS: 24.3,
    textL: 24,
    text: 21,
    textS: 18,
    micro: 15,
);
$typeStyleList-letterSpacing: (
    h1: 0,
    h2: 0,
    h3: 0,
    h4: 0,
    h5: 0,
    caption: 0,
    captionS: 0,
    textL: 0.64,
    text: 0.56,
    textS: 0.48,
    micro: 0.4,
);

@function getFontSize($value) {
    @return map-get($typeStyleList-fontSize, $value);
}

@function getLineHeight($value) {
    @return map-get($typeStyleList-lineHeight, $value);
}

@function getLetterSpacing($value) {
    @return map-get($typeStyleList-letterSpacing, $value);
}

$man_woman_fonts: (h1, h2, h3, h4, h5, caption, captionS);

// Single font size - Same for desktop and mobile device
@each $typeStyle in map-keys($typeStyleList-fontSize) {
    .cc-#{$typeStyle} {
        font-size: rem-calc(getFontSize($typeStyle));
        line-height: rem-calc(getLineHeight($typeStyle));
        letter-spacing: rem-calc(getLetterSpacing($typeStyle));

        @if index($man_woman_fonts, $typeStyle) {
            .cc-womanSite & {
                font-family: $body-font-family-cursive;
            }
        } @else {
            font-family: $body-font-family;
        }
    }
}

$typeStyleList-mixed: (
    h1: (
        'caption',
        'captionS',
        'textL',
        'text',
        'textS',
        'micro',
    ),
    h2: (
        'caption',
        'captionS',
        'textL',
        'text',
        'textS',
        'micro',
    ),
    h3: (
        'caption',
        'captionS',
        'textL',
        'text',
        'textS',
        'micro',
    ),
    h4: (
        'h5',
        'caption',
        'captionS',
        'textL',
        'text',
        'textS',
        'micro',
    ),
    h5: (
        'caption',
        'captionS',
        'textL',
        'text',
        'textS',
        'micro',
    ),
    caption: (
        'caption',
        'captionS',
        'textL',
        'text',
        'textS',
        'micro',
    ),
    captionS: (
        'caption',
        'captionS',
        'textL',
        'text',
        'textS',
        'micro',
    ),
    textL: (
        'caption',
        'captionS',
        'textL',
        'text',
        'textS',
        'micro',
    ),
    text: (
        'caption',
        'captionS',
        'textL',
        'text',
        'textS',
        'micro',
    ),
    textS: (
        'caption',
        'captionS',
        'textL',
        'text',
        'textS',
        'micro',
    ),
    micro: (
        'caption',
        'captionS',
        'textL',
        'text',
        'textS',
        'micro',
    ),
);

@function getFontStyleMixedList($value) {
    @return map-get($typeStyleList-mixed, $value);
}

// Mixed font-size - Changes from desktop to mobile
@each $typeStyle in map-keys($typeStyleList-fontSize) {
    @each $typeStyleList-mixed in getFontStyleMixedList($typeStyle) {
        @if $typeStyleList-mixed {
            // .cc-desktopFontSize_mobileFontSize
            .cc-#{$typeStyle}_#{$typeStyleList-mixed} {
                font-size: rem-calc(getFontSize($typeStyleList-mixed));
                line-height: rem-calc(getLineHeight($typeStyleList-mixed));
                letter-spacing: rem-calc(getLetterSpacing($typeStyleList-mixed));

                @include breakpoint(large up) {
                    font-size: rem-calc(getFontSize($typeStyle));
                    line-height: rem-calc(getLineHeight($typeStyle));
                    letter-spacing: rem-calc(getLetterSpacing($typeStyle));
                }

                @if index($man_woman_fonts, $typeStyle) {
                    .cc-womanSite & {
                        font-family: $body-font-family-cursive;
                    }
                } @else {
                    font-family: $body-font-family;
                }
            }
        }
    }
}

.cc-womanSite,
.cc-manSite {
    .cc-bodyFontFamily {
        font-family: $body-font-family;
    }
}

.cc-font0 {
    font-size: 0;
}

.cc-flex {
    display: flex;
}
