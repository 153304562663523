@import '../subComponent/drawer/privacyPopUpFlag';

.privacy-policy-marketing-popup {
    z-index: 1051;

    @include breakpoint(medium down) {
        .form-request-title {
            width: 85%;
            padding-right: rem-calc(21);
        }
    }
    .tingle-modal-box{
        @include breakpoint (medium down){
            width: 100vw;
            max-height: 80vh;
            margin-bottom: 0;
        }
        .mobilePadding {
            @include breakpoint (medium down){
                padding: 0 !important;
            }
        }
    }
    .tingle-modal-box__content{
        padding: rem-calc(32);
        @include breakpoint (medium down){
            padding: rem-calc(16);
        }
    }
    .cc-borderFrame{
        @include breakpoint (medium down){
            height: rem-calc(2);
            width: rem-calc(100);
            border-radius: rem-calc(5);
            position: fixed;
            top: rem-calc(16);
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #E9E9E9;
        }
    }
    .cc-backStepOnePointOne{
        background-color: white;
        border-radius: rem-calc(2);
        margin-bottom: rem-calc(16);
        padding: 0 !important;
        width: rem-calc(15) !important;
        height: rem-calc(44);
        @include breakpoint (medium down){
            background-color: white;
            border: rem-calc(1) solid $charcoal;
            border-radius: rem-calc(2);
            margin: rem-calc(32 0);
            padding: 0 !important;
            width: rem-calc(40) !important;
            height: rem-calc(40);
        }
    }
    .cc-backStepOnePointTwo{
        display: none;
        background-color: white;
        border-radius: rem-calc(2);
        margin-bottom: rem-calc(16);
        padding: 0 !important;
        width: rem-calc(15) !important;
        height: rem-calc(44);
        @include breakpoint (medium down){
            background-color: white;
            border: rem-calc(1) solid $charcoal;
            border-radius: rem-calc(2);
            margin: rem-calc(32 0);
            padding: 0 !important;
            width: rem-calc(40) !important;
            height: rem-calc(40);
        }
        &-closeNewsletterModal{
            display: block !important;
            position: absolute;
            top: rem-calc(10);
            right: rem-calc(10);
            width: rem-calc(24);
        }
    }
    .image-back-button{
        content: url("../../images/int-arrow-left.svg");
    }

    .content-box-wrapper{
        .privacy-policy-content{
            padding: 0;
        }
    }
    .form-request-title{
        margin-bottom: rem-calc(16);
    }

    a {
        text-decoration: underline;
    }

    .cc-backStepOnePointTwo {
        display: none;
    }
}

.privacy-policy-profiling-popup {
    z-index: 1051;

    @include breakpoint(medium down) {
        .form-request-title {
            width: 85%;
            padding-right: rem-calc(21);
        }
    }
    .tingle-modal-box{
        @include breakpoint (medium down){
            width: 100vw;
            max-height: 80vh;
            margin-bottom: 0;
        }
        .mobilePadding {
            @include breakpoint (medium down){
                padding: 0 !important;
            }
        }
    }
    .tingle-modal-box__content{
        padding: rem-calc(32);
        @include breakpoint (medium down){
            padding: rem-calc(16);
        }
    }
    .cc-borderFrame{
        @include breakpoint (medium down){
            height: rem-calc(2);
            width: rem-calc(100);
            border-radius: rem-calc(5);
            position: fixed;
            top: rem-calc(16);
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #E9E9E9;
        }
    }
    .cc-backStepOnePointOne{
        background-color: white;
        border-radius: rem-calc(2);
        margin-bottom: rem-calc(16);
        padding: 0 !important;
        width: rem-calc(15) !important;
        height: rem-calc(44);
        @include breakpoint (medium down){
            background-color: white;
            border: rem-calc(1) solid $charcoal;
            border-radius: rem-calc(2);
            margin: rem-calc(32 0);
            padding: 0 !important;
            width: rem-calc(40) !important;
            height: rem-calc(40);
        }
    }
    .cc-backStepOnePointTwo{
        background-color: white;
        border-radius: rem-calc(2);
        margin-bottom: rem-calc(16);
        padding: 0 !important;
        width: rem-calc(15) !important;
        height: rem-calc(44);
        @include breakpoint (medium down){
            background-color: white;
            border: rem-calc(1) solid $charcoal;
            border-radius: rem-calc(2);
            margin: rem-calc(32 0);
            padding: 0 !important;
            width: rem-calc(40) !important;
            height: rem-calc(40);
        }
        &-closeNewsletterModal{
            position: absolute;
            top: rem-calc(10);
            right: rem-calc(10);
            width: rem-calc(24);
        }
    }
    .content-box-wrapper{
        .privacy-policy-content{
            padding: 0;
        }
    }
    .form-request-title{
        margin-bottom: rem-calc(16);
    }

    .cc-backStepOnePointTwo {
        display: none;
    }
}

.form-request-title {
    font-size: rem-calc(20);
    line-height: rem-calc(26);
    margin-bottom: rem-calc(24);
    font-weight: 400;

    @include breakpoint(medium down) {
        font-size: rem-calc(16);
        line-height: rem-calc(22);
    }
}

.form-request-paragraph {
    font-size: rem-calc(14);
    margin-bottom: rem-calc(40);
}

.form-request-paragraph-no-margin {
    font-size: rem-calc(16);

    @include breakpoint(medium down) {
        font-size: rem-calc(14);
    }
}

.privacy-style {
    .content-page-wrapper {
        @include breakpoint(medium down) {
            padding: rem-calc(0 20);
        }

        @include breakpoint(large up) {
            padding-right: 5%;
        }
    }
}

.intro-content {
    text-align: justify;
}
