.cc-site-search {
    @include flexGenerator(column);
    display: none;

    background-color: $white;
    bottom: 0;
    height: 100vh;
    left: 100vw;
    overflow-y: auto;
    position: fixed;
    top: 0;
    transition: all 0.2s ease;
    width: 100vw;
    z-index: $zIndex-searchBar;

    &_open {
        display: flex;
        left: 0;
    }

    &-head {
        border-bottom: rem-calc(1) solid $cloud;
        height: rem-calc(56);
        padding: rem-calc(16);
        width: 100%;

        @include breakpoint(large up) {
            @include flexGenerator(row, center, center);
            padding: rem-calc(16 32);
        }

        &-logo {
            height: rem-calc(37);
            width: rem-calc(130);
        }
        @include breakpoint(large up) {
            &-button {
                position: absolute;
                right: rem-calc(32);
            }
        }
    }

    &-searchBar {
        margin: rem-calc(24 16 32);
        position: relative;

        @include breakpoint(large up) {
            margin: 0;
        }

        &-inputSearch {
            border-radius: 0;
            margin-bottom: 0;
            padding: rem-calc(15 48);

            @include breakpoint(large up) {
                border-width: rem-calc(0 0 1 0);
                padding: rem-calc(16 72 15);

                &:focus {
                    border-color: $cloud;
                    border-width: rem-calc(0 0 1 0);
                }
            }
        }

        &-searchIcon {
            color: $mocha;
            left: rem-calc(16);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            @include breakpoint(large up) {
                .small {
                    height: rem-calc(24);
                    width: rem-calc(24);
                }
            }
        }

        &-clear {
            font-size: 0;
            line-height: 0;
            position: absolute;
            right: rem-calc(14);
            top: 50%;
            transform: translateY(-50%);
            right: rem-calc(24);
            &_icon {
                font-size: 0;
                line-height: 0;
                opacity: 1;
            }

            &_text {

                color: $fossil;
                &:disabled {
                    color: $off-white;
                    opacity: 1;
                }
            }
        }
    }

    &-suggestions {
        //BLOCK ELEMENT
        &-words {
            margin-bottom: rem-calc(32);
            max-width: 100%;
            padding: rem-calc(0 16);

            @include breakpoint(large up) {
                @include flexGenerator(row, flex-start, center);
                margin: 0;
                padding: rem-calc(16 32);
            }

            &-headLabel {
                @include truncateRow();
                color: $fossil;
                margin-bottom: rem-calc(16);

                @include breakpoint(large up) {
                    overflow: unset;
                    margin-right: rem-calc(48);
                    margin-bottom: 0;
                }
            }

            &-itemContainer {
                @include flexGenerator(column, flex-start, flex-start);
                max-width: 100%;
                row-gap: rem-calc(16);

                @include breakpoint(large up) {
                    column-gap: rem-calc(32);
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin: 0;
                    row-gap: rem-calc(16);
                }
            }

            &-link {
                @include truncateRow();
                max-width: 100%;

                @include breakpoint(large up) {
                    max-width: unset;
                    overflow: unset;
                    padding: 0;
                }
            }
        }

        &-products {
            background-color: $off-white;
            padding: rem-calc(32 16 80);
            @include breakpoint (small down){
                padding: rem-calc(32 0 80 16);
            }
            &-head {
                @include flexGenerator(row, space-between, center);
                column-gap: rem-calc(16);
                max-width: 100%;
                margin-bottom: rem-calc(24);
                @include breakpoint (small down){
                    margin-right: rem-calc(16);
                }
            }

            &-headLabel {
                @include truncateRow(2);
                font-weight: 300; //not exist in typography page
                margin-bottom: rem-calc(0);
                i {
                    position: relative;
                    top: rem-calc(3);
                }
            }

            &-headBody {
                margin-top: rem-calc(4);
                margin-bottom: rem-calc(0);
            }

            &-headIcon {
                width: rem-calc(82);
                height: rem-calc(22.8);
                top: rem-calc(3);
            }

            &-headLink {
                white-space: nowrap;
                right: rem-calc(8);
                position: relative;
                @include breakpoint (small down){
                    left: 0
                }
            }

            &-itemContainer {
                @include flexGenerator(row, flex-start, flex-start);
                margin-left: rem-calc(-4);
                overflow-x: hidden;

                @include breakpoint(large up) {
                    flex-wrap: wrap;
                    margin-left: rem-calc(-8);
                    row-gap: rem-calc(24);
                }

                .cc-prodTile {
                    @include fixWidth(rem-calc(184));
                    margin: rem-calc(0 4);

                    @include breakpoint(large up) {
                        @include fixWidth(25%);
                        margin: 0;
                        padding: rem-calc(0 8);
                    }
                }
            }
        }

        //variant
        &_defaultSuggestion & {
            &-words {
                &-itemContainer {
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin: rem-calc(0 -8);
                }

                &-link {
                    padding: rem-calc(0 8);
                    width: 50%;

                    @include breakpoint(large up) {
                        padding: 0;
                        width: unset;
                    }
                }
            }
        }
    }
}
@include breakpoint(medium down) {
    .search-is-open .cc-prodTile-quickAdd-modal {
        z-index: 2005;
    }
}
