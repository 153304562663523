$flag-icon-css-path: '../images';
@import 'flag-icon.scss';

.hidden {
    display: none;
}

.cc-geoblock {
    position: fixed;
    width: 100%;
    z-index: 9999999;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: rem-calc(76);
    background-color: $charcoal;
    padding: rem-calc(16 32);
    @include breakpoint (medium down) {
        padding: rem-calc(16);
    }

    &-container {
        width: 100%;
        align-items: center;
        @include breakpoint (large up) {
            justify-content: center;
        }

        &_text{
            margin-right: rem-calc(24);
            color: $white;
            font-size: rem-calc(16);
            @include breakpoint (medium down) {
                width: 33.6vw;
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                margin-right: rem-calc(16);
            }
        }
        &_close{
            padding-left: rem-calc(24);
            &:focus {
                @include shadow(none);
                outline: none;
            }
            @include breakpoint (medium down) {
                padding-left: 0;
                position: absolute;
                right: rem-calc(16);
            }
        }
        &_button {
            margin-bottom: 0;
            padding: rem-calc(0 24);
            height: rem-calc(44);
            &_flag{
                margin-right: rem-calc(12);
            }
        }   
    }
}
