i {
    display: inline-flex;
    font-style: normal;
    font-weight: normal;
}

svg {
    display: inline-block;
    fill: currentColor;
    height: 100%;
    position: relative;
    top: 0;
    width: 100%;

    &:not(:root) {
        overflow: hidden;
    }

    &.tiny {
        width: rem-calc(10);
        height: rem-calc(10);
    }

    &.micro {
        width: rem-calc(12);
        height: rem-calc(12);
    }

    &.verysmall {
        width: rem-calc(14);
        height: rem-calc(14);
    }

    &.small {
        width: rem-calc(16);
        height: rem-calc(16);
    }

    &.medium {
        width: rem-calc(20);
        height: rem-calc(20);
    }

    &.large {
        width: rem-calc(24);
        height: rem-calc(24);
    }

    &.huge {
        width: rem-calc(30);
        height: rem-calc(30);
    }
}
