@import "../setup/settings";

.overlay-bubble-chat{
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 900;
}



.bubble-element{
    position: fixed;
    bottom: 9%;
    z-index: 901;
    &.footer-links-add{
        @include breakpoint (xlarge up){
            margin: auto;
            width: rem-calc(60);
            padding: rem-calc(10);
            margin-right: rem-calc(250);
            padding-right: rem-calc(450);
            padding-left: rem-calc(250);
        }
    }
    @include breakpoint (large up){
        right: 7%;
    }
    @include breakpoint (xlarge up){
        right: 4%;
    }
    @include breakpoint (medium down){
        z-index: 1000;
        right: 5%;
    }
    @include breakpoint (small down) {
        z-index: 1000;
        right: 5%;
        bottom: rem-calc(55);
    }

    .botton-bubble{
        float: right;
        background: $white;
        width: rem-calc(50);
        height: rem-calc(50);
        border: 1px solid $border-gray;
        border-radius: 50%;
        display: block;
        position: relative;
        box-shadow: rem-calc(2 2 10) rgba(0,0,0,0.3);
        img{
            width: rem-calc(32);
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            margin: 0 auto;
            margin-top: rem-calc(-13);
        }
    }
    .bubble-block{
        padding: rem-calc(18 38);
        border: 1px solid $border-gray;
        display: none;
        background: $white;
        min-width: rem-calc(260);
        position: relative;
        margin-bottom: rem-calc(5);
        box-shadow: rem-calc(2 2 10) rgba(0,0,0,0.4);
        &.show{
            display: block;
        }

        .bubble-service-list{
            margin-left: 0;
            list-style-type: none;
            margin-bottom: 0;
            li{
                padding: rem-calc(10 0);
                a{
                    &.disabled{
                        color: $black;
                        text-decoration: none;
                        opacity: 0.4;
                    }
                }
            }
        }
        .close-button-bubble{
            position: absolute;
            right: rem-calc(15);
            top: rem-calc(18);
            width: rem-calc(24);
            span{
                font-size: rem-calc(18);
                float: right;
            }
        }

        .icon-bubble-service{
            img{
                width: rem-calc(18);
                margin-right:rem-calc(8);
            }
        }
        .not-available-text{
            margin: 0;
            font-size: rem-calc(12);
            margin-left: rem-calc(25);
            display: block;
        }
        .not-available-chat{
            margin: 0;
            margin-left: rem-calc(25);
            color: red;
        }
        .available-chat{
            margin: 0;
            margin-left: rem-calc(25);
            color: #8ac492;
        }
        .loader {
            margin-left: rem-calc(25);
            margin-top: rem-calc(3);
            margin-right: rem-calc(9);
            border: 2px solid #f3f3f3; /* Light grey */
            border-top: 2px solid #000000; /* Blue */
            border-radius: 50%;
            width: 15px;
            height: 15px;
            animation: spin 2s linear infinite;
          }
          
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }

        .availabilitySection{

        }
        .availabilityContainer{
            text-align:center;
            display: flex;
        }
        .availabilityText{
              
        }
          
        .mobile-version-phone{
            background: $light-gray;
            padding:rem-calc(3);
            border: 0;
        }
    }

}

.page[data-action="Product-Show"],.page[data-action="Cart-Show"] {
    .bubble-element {
        display: none;
    }
}

.page[data-action="Search-Show"] {
    .bubble-element {
        display: inherit;
    }
}