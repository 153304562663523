// Layer login
.layer-login-modal {
    background: rgba(0,0,0,0.75);

    .tingle-modal-box {
        max-width: rem-calc(430);

        @include breakpoint(medium up) {
            transform: translate(100%, 0);
        }
    }
    &.tingle-modal--visible .tingle-modal-box {
        @include breakpoint(medium up) {
            transform: translate(0, 0);
        }
    }
    .cc-remembermeToggler {
        flex: initial;
        padding-right: 0;

        .toggleButton__label {
            letter-spacing: 0.04em;
        }
    }
    .login-form-container {
        padding: 0 !important;
    }
    .cc-loginLayer-close {
        @include increaseButtonSize();
        display: block;
        height: rem-calc(16);
        line-height: 0;
        padding: 0;
        position: absolute;
        right: rem-calc(16);
        top: rem-calc(16);
        width: rem-calc(16);

        @include breakpoint(large up) {
            cursor: pointer;
            height: rem-calc(24);
            right: rem-calc(8);
            top: rem-calc(8);
            width: rem-calc(24);
        }

        &-icon {
            width: rem-calc(24);
            height: rem-calc(24);
            @include breakpoint(large up) {
                width: rem-calc(16);
                height: rem-calc(16);
            }
        }
    }
}

// Layer forgot password
.layer-password-modal {
    .modal-content {
        position: initial;
        width: 100%;
    }

    .tingle-modal-box {
        @include breakpoint(large up) {
            width: rem-calc(508);
            border-radius: rem-calc(4);
        }
        
        @include breakpoint(medium down) {
            position: absolute;
            min-width: 50vw;
            max-height: 75vh;
            min-height: unset;
            bottom: unset;
            display: flex;
            top: 34%;
        }

        @include breakpoint(small down) {
            min-width: 100vw;
            max-height: 100vh;
            bottom: 0;
            top: 0;
        }
    }
}
.layer-login-modal,
.login-form-container {
    .remember-me-container {
        position: relative;
        display: flex;
        gap: rem-calc(8);
        div.popover-remember-me.for-modal {
            width: rem-calc(366);
            color: $white;
            background: $emerald;
            position: absolute;
            transform: translateY(-100%);
            top: rem-calc(-15);
            padding: rem-calc(20 33 30);
            right: 0;
            filter: drop-shadow(rem-calc(1 2 6) rgba($color: $black, $alpha: 0.16));
            font-size: rem-calc(12);
            line-height: rem-calc(17);
            @include breakpoint(medium down) {
                left: 0;
                width: rem-calc(300);
            }
            &:after,
            &:before {
                bottom: rem-calc(-13.5);
                right: rem-calc(335);
                border: solid transparent;
                content: ' ';
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-left: rem-calc(8) solid transparent;
                border-right: rem-calc(8) solid transparent;
                border-top: rem-calc(13) solid $emerald;
                @include breakpoint(medium down) {
                    right: rem-calc(264) !important;
                }
            }
            .close-button-info-popover {
                position: absolute;
                right: rem-calc(20);
                cursor: pointer;
            }
            .tooltip-title {
                margin-bottom: rem-calc(10);
                font-size: rem-calc(18);
                line-height: rem-calc(17);
            }
            .tooltip-subtitle {
                font-size: rem-calc(12);
                line-height: rem-calc(17);
                margin-top: 0;
                margin-bottom: 0;
            }
            .cookie-policy-tooltip {
                font-size: rem-calc(12);
                text-decoration: underline;
                text-transform: uppercase;
                margin-left: rem-calc(3);
                border-bottom: none;
                color: white;
            }
        }
        .popover-remember-me.for-modal {
            @include breakpoint(medium down) {
                left: rem-calc(-15);
                width: rem-calc(300);
                z-index: 1000;
            }
            &:after,
            &:before {
                @include breakpoint(medium down) {
                    right: rem-calc(250);
                }
            }
        }
        .info-icon {
            .tooltip {
                left: rem-calc(-80);
                top: rem-calc(-4);
                width: rem-calc(267);
                padding: rem-calc(25);
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
                @include breakpoint(medium down) {
                    left: rem-calc(-100);
                    top: rem-calc(-7);
                }
                .tooltip-title {
                    font-size: rem-calc(12);
                    line-height: 150%;
                }
                .tooltip-subtitle {
                    font-size: rem-calc(10);
                    line-height: rem-calc(17);
                    margin: 0;
                }
                .close-button-info-tooltip {
                    display: none;
                }
                .cookie-policy-tooltip {
                    font-size: rem-calc(10);
                    text-decoration: underline;
                    text-transform: unset;
                    border-bottom: none;
                    color: $white;
                    display: block;
                    margin-left: 0;
                }
            }
            .tooltip.for-modal {
                left: rem-calc(-100);
                top: rem-calc(16);
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
                width: rem-calc(297);
                padding: rem-calc(24);
                @include breakpoint(medium down) {
                    top: rem-calc(-5);
                    left: rem-calc(-115);
                }
            }
            .icon-info-black {
                margin-top: rem-calc(7.5);
                width: rem-calc(10.32);
                height: rem-calc(10.32);
                line-height: rem-calc(8);
                margin-left: rem-calc(3);
            }
        }
        .checkbox-label {
            font-size: rem-calc(14);
            font-weight: 500;
            position: relative;
            top: rem-calc(1);
            padding-left: rem-calc(8);
            cursor: unset;
        }
    }
    /* The switch - the box around the slider */
    .switch {
        position: relative;
        display: inline-flex;
        width: rem-calc(40);
        height: rem-calc(23);
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        width: rem-calc(48);
        height: rem-calc(24);
        bottom: 0;
        background-color: $medium-more-gray;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: '';
        color: $cloud;
        font-size: rem-calc(20);
        text-align: center;
        line-height: rem-calc(17);
        height: rem-calc(18);
        width: rem-calc(18);
        left: rem-calc(5);
        bottom: rem-calc(3);
        background-color: $white;
        transition: 0.4s;
    }

    input:checked + .slider {
        background-color: $charcoal;
    }

    input:checked + .slider:before {
        transform: translateX(rem-calc(22));
        background-color: $white;
        content: '';
        left: rem-calc(2);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: rem-calc(34);
    }

    .slider.round:before {
        border-radius: 50%;
    }
}

.layer-password-modal {
    &.step-2 {
        .tingle-modal-box__content {
            text-align: center;
            padding: rem-calc(0 32 32);
            @include breakpoint(medium down) {
                padding: rem-calc(15);
            }
            .modal-title {
                margin-bottom: rem-calc(24);
                font-size: rem-calc(21);
                line-height: rem-calc(27);
                display: none;
            }
            .reset-password-form {
                padding: 0;
            }
            .request-password-body p {
                margin-top: rem-calc(32);
                margin-bottom: rem-calc(32);
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                letter-spacing: 0.04em;
                @include breakpoint(medium down) {
                    margin-bottom: rem-calc(24);
                    line-height: re-calc(21);
                    margin-top: rem-calc(40);
                }
            }
            .ico-center-send-mail {
                margin-bottom: rem-calc(21);
            }
            .request-password-body-description {
                display: none;
            }
        }
    }
    .tingle-modal-box__content {
        padding: rem-calc(32);
        @include breakpoint(medium down) {
            padding: rem-calc(15);
        }
        .close {
            font-size: rem-calc(31);
            font-weight: 300;
            margin: 0;
            top: rem-calc(8);
            right: rem-calc(8);

            @include breakpoint(medium down) {
                top: rem-calc(16);
                right: rem-calc(16); 
            }
        }
        .reset-password-form {
            .request-password-body {
                margin-bottom: rem-calc(32);
            }
            .request-password-body input {
                margin-bottom: rem-calc(4);
            }
        }
        .modal-title {
            font-size: rem-calc(24);
            line-height: rem-calc(29);
            margin-top: 0;
            margin-bottom: rem-calc(24);
            font-weight: 500;
            text-align: left;
            font-family: $body-font-family;
            @include breakpoint(medium down) {
                margin-top: rem-calc(46);
                margin-bottom: rem-calc(10);
                line-height: rem-calc(27);
                font-size: rem-calc(25);
            }
        }
        .modal-description {
            margin-bottom: rem-calc(24);
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            letter-spacing: 0.04em;
            text-align: left;
            @include breakpoint(medium down) {
                margin-bottom: rem-calc(25);
            }
        }
    }
}
.wrapperModalLogin {
    .tingle-modal-box__content {
        padding: rem-calc(48 32);
        @include breakpoint(medium down) {
            padding: rem-calc(124 16 48 16);
        }
        .modal-title-for-login {
            font-family: $body-font-family;
            margin-bottom: rem-calc(32);
            text-align: left;
            @include breakpoint(medium down) {
                padding: 0;
            }
        }
        .modal-subtitle-for-login {
            margin-bottom: rem-calc(32);
            @include breakpoint(large up) {
                margin-bottom: rem-calc(24);
            }
        }
        hr {
            margin-bottom: rem-calc(40);
            @include breakpoint(large up) {
                margin-bottom: rem-calc(32);
            }
        }
        form {
            .login-save-access {
                margin: 0;
            }
            .login-forgot-password-save-access {
                margin: rem-calc(0 0 24);
                @include breakpoint(medium down) {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .checkbox-input {
                        padding-left: rem-calc(17);
                        &::before {
                            width: rem-calc(11);
                            height: rem-calc(11);
                        }
                    }
                    .checkbox-label {
                        line-height: rem-calc(16);
                    }
                    a {
                        flex: 0 0 auto;
                    }
                    label {
                        justify-content: flex-end;
                    }
                    label,
                    a {
                        font-size: rem-calc(12);
                        line-height: rem-calc(18);
                    }
                }
                position: relative;
            }
            .login-form-error:not(:empty),
            .social-login-form-error:not(:empty) {
                border: rem-calc(1) solid $coral;
                border-radius: rem-calc(4);
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                letter-spacing: 0.04em;
                text-align: center;
                padding: rem-calc(26 16 8);
                position: relative;
                margin: rem-calc(0 0 32);

                @include breakpoint(large up) {
                    padding: rem-calc(4 46);
                    margin: rem-calc(0 0 24);
                }

                a {
                    color: inherit;
                    font: inherit;
                }

                &::before {
                    content: '';
                    background: url('../images/error.svg');
                    width: rem-calc(14);
                    height: rem-calc(14);
                    background-size: rem-calc(14);
                    background-repeat: no-repeat;
                    display: inline-block;
                    top: rem-calc(8);
                    position: absolute;
                    left: 50%;
                    margin: rem-calc(0 0 0 -7);

                    @include breakpoint(large up) {
                        top: 50%;
                        left: rem-calc(16);
                        margin: rem-calc(-7 0 0);
                    }
                }
            }
            .login-form-error {
                color: $apple;
            }
            .login-form-container {
                @include breakpoint(medium down) {
                    padding: rem-calc(0 14);
                }
            }
        }

        .gigyaLoginCell {
            margin-bottom: rem-calc(40);
            @include breakpoint(large up) {
                margin-bottom: rem-calc(32);
            }
        }
        .login-popup-footer {
            font-size: rem-calc(14);
            line-height: rem-calc(18);

            .new-customer-text {
                margin-bottom: rem-calc(24);
            }

            .cc-modal-registration-button{
                width: 100%;
                margin-bottom: rem-calc(48);
            }
        }
    }
}

.social-login-account-accept {
    margin-top: rem-calc(16);
}
/*
#password-reset{
    width: 100%;
    margin-top: rem-calc(-4);
}
*/
/*
.socialLoginContainer{
    margin-left: rem-calc(37.5);
    @include breakpoint (medium down){
        margin-left: rem-calc(75);
    }
}
*/

.fixed-image-login-no-scrollX {
    display: inline;
    position: fixed;
    margin-right: 41.46%;
    margin-left: 6.6%;
    margin-top: rem-calc(29.79);
    height: 100%;
    max-height: rem-calc(621);
    max-width: rem-calc(581);
}

.close {
    &-black {
        height: rem-calc(16);
        width: rem-calc(16);
    }
}

// .modal-title-for-login {
//     margin-left: rem-calc(65);
// }



.modal-registration-flow-custom {
    .registrationModal-box {
        &[data-registration-modal-stage="email"] {
            .registration-modal-phone,
            .registration-modal-personal-info,
            .registration-modal-waiting,
            .registration-modal-email-only {
                display: none;
            }
        }
        &[data-registration-modal-stage="phone"]{
            .registration-modal-email,
            .registration-modal-personal-info,
            .registration-modal-waiting,
            .registration-modal-email-only {
                display: none;
            }
        }
        &[data-registration-modal-stage="personal-info"]{
            .registration-modal-email,
            .registration-modal-phone,
            .registration-modal-waiting,
            .registration-modal-email-only {
                display: none;
            }
        }
        &[data-registration-modal-stage="waiting-room"]{
            .registration-modal-email,
            .registration-modal-phone,
            .registration-modal-personal-info,
            .cc-registrationModal__back,
            .registration-modal-email-only {
                display: none;
            }

            .modal-title-for-login.cc-registrationModal__headTitle {
                padding-left: 0 !important;
            }
        }
        &[data-registration-modal-stage="email_only"] {
            .registration-modal-phone,
            .registration-modal-personal-info,
            .registration-modal-waiting,
            .registration-modal-email {
                display: none;
            }
        }
    }
}

.modal-loyalty-registration-flow-custom {
    .registrationModal-box {
        &[data-registration-modal-stage="content"] {
            .loyalty-registration-modal-registration,
            .loyalty-registration-modal-loyaltythankyoupage,
            .cc-registrationModal__back {
                display: none;
            }

            .modal-title-for-login.cc-registrationModal__headTitle {
                padding-left: 0 !important;
            }
        }
        &[data-registration-modal-stage="registration"]{
            .loyalty-registration-modal-content,
            .loyalty-registration-modal-loyaltythankyoupage {
                display: none;
            }
        }
        &[data-registration-modal-stage="loyalty-thankyou-page"] {
            .loyalty-registration-modal-registration,
            .loyalty-registration-modal-content,
            .cc-registrationModal__back {
                display: none;
            }

            .modal-title-for-login.cc-registrationModal__headTitle {
                display: none !important;
            }
        }
    }
}

.cc-layerLogin {
    &__confirmRegister {
        .cc-titleWrp {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: rem-calc(16);

            .cc-icon {
                font-size: 0;
            }
        }

        &__title {
            font-weight: $global-weight-dark;
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            letter-spacing: rem-calc(0.2);
            text-align: left;
            margin: 0;
            padding: rem-calc(0 0 0 16);
        }

        &__text {
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            letter-spacing: rem-calc(0.2);
            text-align: left;
            margin: rem-calc(0 0 16);
            padding: 0;
        }
    }
}

.cc-margin {
    &-x {
        &-medium {
            margin: rem-calc(0 20); 
        }
    }
    &-t {
        &-xs {
            margin-top: rem-calc(6);
        }
        &-md {
            margin-top: rem-calc(30);
        }
    }
}

.relative {
    position: relative;
}

.invisible {
    visibility: hidden;
}