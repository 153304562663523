.sub-title-header, .sub-header {
    width: 100%;
    padding-bottom: rem-calc(15);
    margin-bottom: rem-calc(15);
    min-height: rem-calc(50);
    line-height: rem-calc(50);

    &.hide-hairline {
        border-bottom: none;
    }

    @include breakpoint (small down) {
        padding-left: rem-calc(10);
        padding-right: rem-calc(10);
        padding-top: rem-calc(15);
        margin-top: rem-calc(65);
        margin-bottom: 0;
    }

    h1, h3, a {
        display: block;
        margin: 0;
    }

    .title-container {
        display: flex;
        align-items: baseline;

        .left, .right {
            width: 50%;
            float: left;
        }

        .right {
            text-align: right;
        }
    }

    .caret-left {
        float: left;
        // margin-left: rem-calc(5);
        // max-height: rem-calc(20);
        display: block;
        svg {
            top: 0;
            margin: 0;
            max-height: rem-calc(20);
            width: rem-calc(10);
        }
        // opacity: 0.3;
    }

    span.title {
        text-decoration: none;
        font-size: rem-calc(23);
        font-weight: $global-weight-dark;
        // margin-left: rem-calc(15);
        vertical-align: middle;

        .image-container {
            position: relative;
            width: rem-calc(30);
            height: rem-calc(30);

            margin-right: rem-calc(20);
            margin-left: rem-calc(20);

            .image {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .image {
            vertical-align: middle;
            max-width: rem-calc(20);
            max-height: rem-calc(20);
            width: auto;
            height: auto;
            margin-top: rem-calc(2);
        }
    }
}

.sub-header {

    &.detail {
        margin-top: rem-calc(-2);
        padding-bottom: rem-calc(2);
    }

    @include breakpoint (small down) {
        padding-left: rem-calc(10);
        padding-right: rem-calc(10);
        padding-top: rem-calc(15);
        margin-top: rem-calc(65);
        margin-bottom: 0;

        &.detail {
            background: $white;
            position: fixed;
            top: calc(var(--main-header));
            right: 0;
            width: 100%;
            max-width: 100%;
            min-height: rem-calc(50);
            margin: 0;
            padding: 0;
            border-bottom: 1px solid $border-gray;
            z-index: 2;
            padding-left: rem-calc(20);
            padding-right: rem-calc(20);
        }
    }

}
.sub-title-header {
    border-bottom: rem-calc(1) solid $border-gray;
}