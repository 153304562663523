$include-fonts: false;
@import "../setup/settings";

.prehome-img{
    width:100%;
    object-fit: cover;
    height: 100%;
    object-position: top;
}
.grid-full-viewport{
    @include breakpoint (large up){
        height:100vh;
        overflow: hidden;
    }
    .content-asset{
        width:100%;
        height:100%;
        .max-height-prehome-grid{
            height:100%;
        }
    }
    .small-viewport-cell{
        height:100%;
        position: relative;
        @include breakpoint (medium down){
            height:47vh;
        }

        .category-name-prehome{
            text-align: center;
            line-height: rem-calc(22);
            font-size:rem-calc(35);
            text-transform: uppercase;
            color: $white;
            position: absolute;
            top:50%;
            left:50%;
            bottom: 50%;
            margin-left:rem-calc(-70);
            @include breakpoint (medium down){
                font-size:rem-calc(22);
                margin-left:rem-calc(-44);
            }
        }
    }
}

.cc-prehome {
    &__header {
        padding: rem-calc(24 16);
        background: $white;

        &__title {
            display: block;
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            letter-spacing: 0.04em;
            color: $charcoal;
            text-align: center;
            margin: 0;

            @include breakpoint(large up) {
                font-size: rem-calc(14);
                line-height: rem-calc(21);
            }
        }
    }

    &__main {
        padding: rem-calc(0 16);

        @include breakpoint(large up) {
            padding-bottom: rem-calc(16);
        }

        &__wrp {
            @include breakpoint(large up) {
                display: flex;
                flex-direction: row;
                overflow: hidden;
                border-radius: rem-calc(4);
                position: relative;

                aspect-ratio: 1408 / 745;
            }
        }
    }

    &__item {
        display: block;
        position: relative;
        border-radius: rem-calc(4);
        overflow: hidden;
        margin: rem-calc(0 0 16);
        text-decoration: none;
        color: inherit;

        @include breakpoint(large up) {
            border-radius: 0;
            width: 50%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            margin: 0;
            transition: all 0.8s cubic-bezier(0, 0, 0.2, 1);
            z-index: 0;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $black;
            z-index: 2;
            opacity: 0;
            pointer-events: none;
            transition: all 0.8s ease-in-out;
        }

        &:last-child {
            margin-bottom: 0;

            @include breakpoint(large up) {
                margin-bottom: rem-calc(16);
                left: 50%;
            }
        }

        &--man {
            .cc-prehome__info__logo img {
                @include breakpoint(large up) {
                    width: rem-calc(154);
                    height: rem-calc(45);
                }
            }
        }

        &--notActive {
            @include breakpoint(large up) {
                width: 40.6%;
            }

            &:after {
                @include breakpoint(large up) {
                    opacity: 0.3;
                }
            }

            &.cc-prehome__item--man {
                @include breakpoint(large up) {
                    left: 59.4%;
                }
            }
        }

        &--active {
            @include breakpoint(large up) {
                width: 59.4%;
            }

            &.cc-prehome__item--man {
                @include breakpoint(large up) {
                    left: 40.6%;
                }
            }
        }
    }

    &__info {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: rem-calc(16);

        &__title {
            display: block;
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            letter-spacing: 0.04em;
            color: $white;
            text-align: center;
            margin: rem-calc(0 0 4);

            @include breakpoint(large up) {
                font-size: rem-calc(16);
                line-height: rem-calc(24);
            }
        }

        &__logo {
            img {
                display: block;
                width: rem-calc(120);
                height: rem-calc(34);
                margin: 0 auto;

                @include breakpoint(large up) {
                    width: rem-calc(158);
                    height: rem-calc(45);
                }
            }
        }
    }

    &__img {
        @include breakpoint(large up) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        img {
            display: block;
            width: 100%;
            margin: 0;

            @include breakpoint(large up) {
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
