$include-fonts: false;

@import './setup/settings';
@import './setup/colorclasses';
@import 'foundation';

@include foundation-global-styles;
@include foundation-xy-grid-classes;
@include foundation-forms;
@include foundation-button;
@include foundation-typography;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-sticky;
@include foundation-flex-classes;
// @include foundation-reveal;

@import 'mixins/helpers';

@include extend-palette($brand-palette);
@include helpers();
// @include full-container();

@import 'layout/layout';

@include brand-text($brand-palette);
@include brand-links($brand-palette);
@include brand-borders($brand-palette);

@import 'components/components';
@import 'components/prehome';

@import 'plugins/foundation-datepicker';
@import 'pages/content';
@import 'plugins/slick';
@import 'plugins/woosmap';
// @import "pages/plp/categoryTopBanner";

.tingle-modal.tingle-modal--visible.multilanguage-popup {
    z-index: 99999999;
}

* {
    @media screen and (max-width: 1024px) {
        -webkit-tap-highlight-color: transparent;
    }
}