.info-icon {
    display: inline-block;
    position: relative;
    line-height: rem-calc(17);

    &-pos-absolute {
        position: absolute;
        top: rem-calc(23);
        right: rem-calc(10);
    }

    .icon {
        display: inline-block;
        background: $cornflower;
        color: $white;
        font-style: normal;
        height: rem-calc(12);
        width: rem-calc(12);
        line-height: rem-calc(12);
        font-size: rem-calc(8);
        vertical-align: top;
        text-align: center;
        border-radius: 50%;
        cursor: pointer;

        &:hover + .tooltip {
            opacity: 1;
            visibility: visible;
        }
    }

    .tooltip-info-icon {
        &-black {
            background: $black;
            color: $white;
        }
        &-white {
            background: transparent;
            color: $black;
        }
        border: rem-calc(1) solid $black;
        display: inline-block;
        font-style: normal;
        height: rem-calc(12);
        width: rem-calc(12);
        line-height: rem-calc(10);
        font-size: rem-calc(8);
        vertical-align: top;
        text-align: center;
        border-radius: 50%;
        cursor: pointer;

        &:hover + .tooltip {
            opacity: 1;
            visibility: visible;
            z-index: 99999;
        }
    }

    .icon-info-gray {
        border: 1px solid #828282;
        display: inline-block;
        color: #828282;
        font-style: normal;
        height: rem-calc(13);
        width: rem-calc(13);
        line-height: rem-calc(10);
        font-size: rem-calc(8);
        vertical-align: top;
        text-align: center;
        border-radius: 50%;
        cursor: pointer;
        font-weight: 600;
        letter-spacing: 0;

        &:hover + .tooltip {
            opacity: 1;
            visibility: visible;
            z-index: 99999;
        }
    }

    .icon-info-black {
        cursor: pointer;

        &:hover + .tooltip {
            opacity: 1;
            visibility: visible;
            z-index: 99999;
        }
    }

    .tooltip {
        position: absolute;
        top: 0;
        left: 0;
        width: rem-calc(297);
        transform: translate(-10%, -110%);
        background: $charcoal;
        color: $white;
        padding: rem-calc(24);
        opacity: 0;
        visibility: hidden;
        transition: opacity .25s ease;
        pointer-events: none;
        border-radius: rem-calc(4);

        &.info-tooltip-shipping{
            top: rem-calc(70);
            left: rem-calc(50);
            @include breakpoint(medium down) {
                top: rem-calc(9);
                left: rem-calc(-94);
            }
            @include breakpoint(small down) {
                top: rem-calc(64);
                left: rem-calc(-220);
            }
        }
    }
}

.cc-estimation-icon {
        display: inline-block;
        position: relative;
        line-height: normal;
        margin-left: rem-calc(7);
}

.cc-text-small {
    font-size: small;
}








// .info-icon {
//     display: inline-block;
//     position: relative;
//     line-height: rem-calc(24);

//     .icon {
//         display: inline-block;
//         background: $info;
//         color: $white;
//         font-style: normal;
//         height: rem-calc(12);
//         width: rem-calc(12);
//         line-height: rem-calc(12);
//         font-size: rem-calc(8);
//         vertical-align: top;
//         text-align: center;
//         border-radius: 50%;
//         cursor: pointer;

//         &:hover + .tooltip {
//             opacity: 1;
//             visibility: visible;
//         }
//     }

//     .icon-info-black {
//         display: inline-block;
//         background: $black;
//         color: $white;
//         font-style: normal;
//         height: rem-calc(12);
//         width: rem-calc(12);
//         line-height: rem-calc(12);
//         font-size: rem-calc(8);
//         vertical-align: top;
//         text-align: center;
//         border-radius: 50%;
//         cursor: pointer;

//         &:hover + .tooltip {
//             opacity: 1;
//             visibility: visible;
//             z-index: 99999;
//         }
//     }

//     .tooltip {
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: rem-calc(240);
//         transform: translate(-10%, -110%);
//         background: rgba($black, 0.7);
//         color: $white;
//         padding: rem-calc(8);
//         opacity: 0;
//         visibility: hidden;
//         transition: opacity .25s ease;
//         pointer-events: none;

//         &.info-tooltip-shipping{
//             top: rem-calc(70);
//             left: rem-calc(50);
//             @include breakpoint(medium down) {
//                 top: rem-calc(9);
//                 left: rem-calc(-94);
//             }
//             @include breakpoint(small down) {
//                 top: rem-calc(64);
//                 left: rem-calc(-220);
//             }
//         }
//     }
// }
