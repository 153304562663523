/// Generate font-color & bg-color class helpers
///
/// @param {Map} $palette - colors map
@mixin extend-palette($palette) {
    @each $color, $hex in $palette {
        .color-#{$color} {
            color: $hex !important;
        }

        .bg-#{$color} {
            background-color: $hex !important;
        }
    }
}

.transparent {
    opacity: 0.5;
}
