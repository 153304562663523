
/*.add-to-wishlist-messages {
    transform: translate(-50%, -50%);
    position: fixed;
    top: 15%;
    left: 50%;
}

.add-to-wishlist-alert {
    animation: fade 5s linear forwards;
    box-shadow: 1px 1px 5px grey;
    padding: 1em;

    @keyframes fade {
        0% { opacity: 0; }
        10% { opacity: 1; }
        90% { opacity: 1; }
        100% { opacity: 0; }
    }

    &.show {
        display: block;
    }
}

.wishlistTile {
    i {
        &.fa-heart-o {
            color: rgba(0, 0, 0, 0.7);
        }

        &.fa-circle {
            color: rgba(255, 255, 255, 0.7);
            text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        }
    }

    span {
        &.fa-stack {
            position: absolute;
            top: 8px;
            right: 16px;
        }
    }
}

.wishlistTile {
    .fa-heart {
        color: black;
    }
}
*/

.cc-add-wishlist-notification {
    position: fixed;
    z-index: 100;
    right: rem-calc(16);
    border-radius: rem-calc(4);
    box-shadow: 0 rem-calc(9) rem-calc(17) 0 rgba(0, 0, 0, 0.15);
    width: calc(100vw - 32px);
    overflow: hidden;
    @include breakpoint(large up) {
        width: rem-calc(343);
        right: rem-calc(24);
    }

    &--top {
        background: $off-white;
        padding: rem-calc(8 16 8 16);
        display: flex;
        align-items: center;
        &-title {
            flex: 1 0 auto;
            text-align: center;
        }

        &-close {
            cursor: pointer;
        }
    }

    &--product {
        padding: rem-calc(16);
        background: $white;
        display: flex;
        gap: rem-calc(16);

        &-image {
            flex: 0 0 auto;
            aspect-ratio: 2/3;
            width: rem-calc(35);
        }
        &-info {
            flex: 1 1 auto;
            &-size {
                color: $fossil;
            }
        }
    }

    &--feedback {
        align-self: center;
        &-tick {
            border-radius: 50%;
            background: $charcoal;
        }
    }
}
