.tingle-modal-form-request {
    .tingle-modal-box {
        width: rem-calc(500);
        @include breakpoint(medium down) {
            box-shadow: 0 0 rem-calc(1) rgba(0, 0, 0, 0.5);
            width: 100vw;
            max-width: unset;
        }
        .cc-orderModalCLose{
            position: absolute;
            top: rem-calc(6);
            right: rem-calc(-6);
            width: rem-calc(24);
            max-height: rem-calc(24);
            @include breakpoint (large up){
                right: rem-calc(-4);
            }
        }
        .cc-orderStatusPage{
            &-form{
                .grid-margin-x{
                    margin-left: 0;
                    margin-right: 0;
                }
                .form-fields-item{
                    width: 100%;
                    margin-left: 0;
                    margin-right: 0;
                    margin-bottom: rem-calc(8);
                }
            }
        }
        .tingle-modal-box__content {
            padding: rem-calc(32);
            @include breakpoint(medium down) {
                padding: rem-calc(30 20);
                .close {
                    margin: 0;
                    right: rem-calc(20);
                    top: rem-calc(10);
                    font-size: rem-calc(34);
                }
            }
            .fix-width-cell {
                @include breakpoint(medium up) {
                    width: calc(100% - #{rem-calc(76)});
                }
            }
            .cc-orderStatusPage{
                &-formTitle{
                    padding-top: 0;
                    @include breakpoint (large up){
                        font-size: rem-calc(24);
                        line-height: rem-calc(31.2);
                    }
                }
                &-form{
                    @include breakpoint(medium down) {
                        display: flex;
                        flex-direction: column;
                        &-textIntroOrderStatus{
                            margin-bottom: rem-calc(40);
                        }
                        &-seeOrderBtnCell {
                            margin-top: auto;
                            button {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                &-form{
                        &-seeOrderBtnCell{
                        margin-top: rem-calc(24);
                    }
                }
            }
        }
    }
}

.cc-orderStatusPage,
.tingle-modal-form-request {
    .cc-orderStatusPage{
        &-form{
            &-title {
                color: $charcoal;
                text-align: center;
                font-family: $body-font-family;
                font-size: rem-calc(32);
                font-style: normal;
                font-weight: $global-weight-normal;
                line-height: 130%;
                padding-top: rem-calc(40);
                margin-bottom: rem-calc(16);
            }
            &-textIntroOrderStatus {
                width: 100%;
                margin: rem-calc(0 0 40 0);
                span{
                    text-align: center;
                }
                @include breakpoint(medium up) {
                    width: 100%;
                    text-align: center;
                }
                @include breakpoint(medium down) {
                    margin-bottom: rem-calc(40);
                }
            }
            &-seeOrderBtnCell{
                margin-top: rem-calc(32);
                button{
                    height: rem-calc(44);
                    padding:rem-calc(0 24);
                    border-radius: rem-calc(2);
                    background: $charcoal;
                }
                @include breakpoint(medium down) {
                    margin-top: 0;
                    button.see-order {
                        width: 100%;
                    }
                }
            }
        }
    }
    .fix-width-cell{
        margin-right: 0;
        @include breakpoint (medium down){
            margin-left: 0;
        .form-fields-item__input-field{
            margin-bottom: rem-calc(40);
        }
        }
    }
    .form-fields-item__label{
        margin-bottom: 0;
    }
}

.cc-orderStatusPage {
    margin-top: rem-calc(40);
    width: 56.6867%;
    &-container{
        width: 100%;
    }
    &-form{
            &-textIntroOrderStatus{
            display: flex;
            text-align: justify;
            width: 100%;
        }
    }
    &-formTitle{
        margin-bottom: rem-calc(16);
        @include breakpoint (medium down){
            margin: rem-calc(40 0 16 0);
        }
    }
}
.page[data-action="OrderStatus-Status"] {
    .fix-height{
        height: calc(100vh - #{rem-calc(110)});
            .height-100{
                height: 100%;
            }
        @include breakpoint(large up){
            height: calc(100vh - #{rem-calc(102)});
            .height-100{
                height: 100%;
            }
        }
    }
}
@include breakpoint(medium down) {
    .page[data-action="OrderStatus-Status"] {
        .fix-height {
            .height-100 {
                height: 100%;
            }
        }
        .cc-orderStatusPage{
            width: unset;
            margin-top: 0;
            padding-top: 0;
            &-form {
                display: flex;
                flex-direction: column;
                height: 100%;
                &-seeOrderBtnCell {
                    @include breakpoint (medium down){
                        padding-bottom: rem-calc(35);
                        background-color: $white;
                        z-index: 1;
                    }
                    button {
                        margin-top: rem-calc(32);
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
