.marketing-budge {
    font-size: rem-calc(getFontSize('micro'));
    letter-spacing: rem-calc(getLetterSpacing('micro'));
    line-height: rem-calc(getLineHeight('micro'));

    border-radius: rem-calc(2);
    color: $white;
    display: inline-block;
    margin: 0;
    padding: rem-calc(2 6);

    &-outline {
        font-size: rem-calc(getFontSize('textS'));
        letter-spacing: rem-calc(getLetterSpacing('textS'));
        line-height: rem-calc(getLineHeight('textS'));

        background-color: transparent;
        padding: rem-calc(0 8);
    }

    &-container {
        @include flexGenerator(row, flex-start, center);
        flex-wrap: wrap;
        gap: rem-calc(8);

        p {
            margin-bottom: 0;
        }
    }
    &_promo {
        background-color: $rust; // MAN COLOR
    }
}

.cc-womanSite {
    .marketing-budge_promo {
        background-color: $blush;
    }
}
