.cc-productWrapper{ 
    position: relative;
    border: rem-calc(1) solid $cloud;
    border-radius: rem-calc(4);
    padding: rem-calc(16);
    margin-top: rem-calc(24);
    @include breakpoint(medium down) {
        margin-top: rem-calc(16);
    }
    &_completed{
        .cc-womanSite & {
            border-color: $promo;
        }
        .cc-manSite & {
            border-color: $rust;
        }
        &.cc-productWrapper_completed_woman{
            border-color: $promo;
        }
        &.cc-productWrapper_completed_man{
            border-color: $rust;
        }          
        &.cc-productWrapper_completed_loyal{
            border-color: $dijon;
        }      
    }
    &-image{
        max-width: rem-calc(109);
        width: rem-calc(109);
        max-height: rem-calc(162);
        height: rem-calc(162);
        object-fit: cover;
        @include breakpoint(large up) {
            max-width: rem-calc(130);
            width: rem-calc(130);
            max-height: rem-calc(193);
            height: rem-calc(193);
        }
    }
    &-productInfo{
        &-marketingBadgesAll{
            @include breakpoint(medium down) {
                margin-top: 0;
            }
        }
        & .line-item-name{
            @include breakpoint(medium down) {
                margin-bottom: rem-calc(4);
            }
        }
        & .line-item-price-info{
            margin-bottom: rem-calc(4);
        }
    }
    & .cc-productPromo{
        padding: 0;
        margin: 0;
        border: none;
        &-textWrapper{
            display: flex;
            padding: 0;
            flex-direction: column;
            .cc-womanSite & {
                color: $promo;
            }
            .cc-manSite & {
                color: $rust;
            }
            &_name{
                margin-bottom: rem-calc(12);
                max-width: fit-content;
                &:last-child{
                    &:not(.cc-productPromo-completeYourPromo) {
                        margin-bottom: 0;
                    }
                }
            }
            &_completed{
                margin: 0;
            }
        }
        &-completeYourPromo{
            &-text{
                display: flex;
                align-items: center;
                text-decoration: underline;
                text-underline-offset: rem-calc(2);
                margin-top: 0;
                .cc-womanSite & {
                    color: $promo;
                }
                .cc-manSite & {
                    color: $rust;
                }
                &_icon{
                    margin-left: rem-calc(8);
                }
            }
        }
    }
    & .product-wrapper {
        padding: rem-calc(16 0 0 0);
        border-top: rem-calc(1) solid $cloud;
        margin-top: rem-calc(16);
        position: relative;
        & .cc-content-action-item-cart{
            & .line-item-action{
                right: 0;
            }
        }
    }
    .cc-minicart{
        &-productWrapper {
            margin-bottom: 0;
            &-container{
                padding-top: rem-calc(16);
                border-top: rem-calc(1) solid $cloud;
                margin-top: rem-calc(16);
            }
            &-delete{
                position: relative;
                transform: none;
            }
            &-productInfo{
                & p{
                    &.cc-productName{
                        max-width: rem-calc(218);
                        @include breakpoint(large up) {
                            max-width: rem-calc(180);
                        }
                    }
                }
            }
        }
    }
}
.cc-bonusProductLabel{
    &-budge{
        margin-bottom: rem-calc(12);
    }
}
.cc-cart {
    .cc-productWrapper{ 
        .cc-productPromo{
            margin: 0;
        }
        &-image{
            max-width: rem-calc(109);
            width: rem-calc(109);
            @include breakpoint(large up) {
                max-width: rem-calc(130);
                width: rem-calc(130);
            }
        }
    }
}
.cc-minicart{
    overflow: hidden;
    .tingle-modal-box__content{
        overflow-y: auto;
        position: relative;
        &::-webkit-scrollbar {
            width: rem-calc(2);
            background-color: $cloud;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $fossil;
            height: rem-calc(65);
        }
    }
    .minicart-title{
        @include breakpoint(medium down) {
            margin-top: rem-calc(40);
        }
    }
    .cc-productWrapper{
        &:first-of-type{
            @include breakpoint(medium down) {
                margin-top: 0;
            }
        }
        .cc-productPromo{
            &-textWrapper{
                &-text{
                    &:not(.cc-productPromo-textWrapper_completed){
                        display: none;
                    }
                }
                &_name{
                    margin-bottom: 0;
                }
                &_completed{
                    margin-top: rem-calc(8);
                    margin-bottom: rem-calc(-8);   
                    &:last-of-type{
                        margin-bottom: 0;   
                    }     
                }
            }
            &-completeYourPromo{
                &-text{
                    margin-top: rem-calc(8);
                }
            }
        }
        & .wrapper-bonus-product-button{
            &.cc-bonusProduct{
                margin: 0;
                padding: 0;
                border: none;
            }
        }
    }
}
.cc-cart-prodSummary{
    & > .cc-minicart-productWrapper{
        position: relative;
        border: rem-calc(1) solid $cloud;
        border-radius: rem-calc(4);
        padding: rem-calc(16);
        & .cc-minicart-productWrapper{
            &-container{
            position: relative;
            }
            &-productInfo{
                & .cc-productName{
                    max-width: rem-calc(190);
                }
            }
        }
    }
}
.cc-cartPromotionModal{
    background: transparent;
    .container-complete-your-promo-modal{
        max-width: rem-calc(430);
        top: 0;
        right: 0;
        @include breakpoint(medium down) {
            height: 100%;
            min-height: 100%;
        }
    }
    .tingle-modal-box__content{
        height: 100%;
        max-height: 100%;
        padding: rem-calc(0 1 0 0);
        .cc-cartPromotionModal-closeButton{
            right: rem-calc(16);
            top: rem-calc(16);
        }
        .cc-completeYourPromo-closeButton-icon{
            @include breakpoint(medium down) {
                width: rem-calc(24);
                height: rem-calc(24);
            }
        }
    }
    &-container{
        position: relative;
        padding: rem-calc(48 16 0 16);
        @include breakpoint(large up) {
            padding: rem-calc(48 32 0 32);
        }
        height: calc(100% - #{rem-calc(130)});
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: rem-calc(2);
            background-color: $cloud;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $fossil;
            height: rem-calc(65);
        }
    }
    input.cc-complete-the-promo-status[value="NaN"] {
        ~ .cc-cartPromotionModal-container { 
            height: calc(100% - #{rem-calc(111)});
        }
    }
    &-header{
        &-budge{
            margin-bottom: rem-calc(12);
        }
    }
    .cc-cartPromotionModal-productCards{
        flex-direction: column;
        gap: rem-calc(6);
        &.cc-cartPromotionModal-productCards-wishlist{
            margin-bottom: rem-calc(6);
            gap: 0;
            border: rem-calc(1) solid $cloud;
            border-radius: rem-calc(4);
            overflow: hidden;
            & .cc-cartPromotionModal-productCards-wishlist_text{
                padding: rem-calc(16 40 16 27);
                margin: 0;
                &_icon{
                    display: flex;
                    padding-bottom: rem-calc(8);
                    justify-content: center;
                    .cc-manSite & {
                        filter: brightness(0) saturate(100%) invert(35%) sepia(47%) saturate(1057%) hue-rotate(332deg) brightness(110%) contrast(96%);
                    }
                }
                .cc-womanSite & {
                    color: $promo;
                    &.cc-dashboardPromo__item__label{
                        &--woman{
                            color: $blush;
                        }
                        &--man{
                            color: $rust;
                            & .cc-cartPromotionModal-productCards-wishlist_text_icon{
                                filter: brightness(0) saturate(100%) invert(35%) sepia(47%) saturate(1057%) hue-rotate(332deg) brightness(110%) contrast(96%);
                            }
                        }
                        &--loyal{
                            color: $dijon;
                            & .cc-cartPromotionModal-productCards-wishlist_text_icon{
                                filter: brightness(0) saturate(100%) invert(37%) sepia(59%) saturate(2300%) hue-rotate(29deg) brightness(90%) contrast(101%);
                            }
                        }
                    }
                }
                .cc-manSite & {
                    color: $rust;
                    &.cc-dashboardPromo__item__label{
                        &--woman{
                            color: $blush;
                            & .cc-cartPromotionModal-productCards-wishlist_text_icon{
                                filter: none;
                            }
                        }
                        &--man{
                            color: $rust;
                            & .cc-cartPromotionModal-productCards-wishlist_text_icon{
                                filter: brightness(0) saturate(100%) invert(35%) sepia(47%) saturate(1057%) hue-rotate(332deg) brightness(110%) contrast(96%);
                            }
                        }
                        &--loyal{
                            color: $dijon;
                            & .cc-cartPromotionModal-productCards-wishlist_text_icon{
                                filter: brightness(0) saturate(100%) invert(37%) sepia(59%) saturate(2300%) hue-rotate(29deg) brightness(90%) contrast(101%);
                            }
                        }
                    }
                }
            }
            & .cc-cartPromotionModal-productCards-product-wishlist{
                border: none;
                border-radius: 0;
                border-top: rem-calc(1) solid $cloud;
                & .cc-prodTile-imageContainer{
                    margin: rem-calc(8 0);
                }
                &:nth-of-type(2){
                    & .cc-prodTile-imageContainer{
                        margin-top: 0;
                    }
                }
                &:last-of-type{
                    & .cc-prodTile-imageContainer{
                        margin-bottom: 0;
                    }
                }
                & .cc-productActionButtons-container {
                    display: flex;
                }
                & .cc-productPromo-completeYourPromo-link, & .cc-prodTile-link{
                    display: none;
                }
            }
        } 
        .cc-cartPromotionModal-productCards-product{
            display: flex;
            width: 100%;
            border: rem-calc(1) solid $cloud;
            border-radius: rem-calc(4);
            overflow: hidden;
            max-width: 100%;
            &_active{
                .cc-womanSite & {
                    border: rem-calc(1) solid $promo;
                }
                .cc-manSite & {
                    border: rem-calc(1) solid $rust;
                }
            }
            & .cc-intProductDetail-colors-heading, & .cc-intProductDetail-size-heading{
                margin-bottom: 0;
                color: $fossil;
                display: flex;
                gap: rem-calc(4);
            }
            & .cc-intProductDetail-colors-heading{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
            }
            &>.cc-prodTile{
                max-width: rem-calc(109);
                border: none;
                pointer-events: none;
                .cc-prodTile-imageContainer{
                    height: 100%;
                    max-height: rem-calc(162);
                    max-width: rem-calc(109);
                    @include breakpoint(medium down) {
                        width: auto;
                        min-width: rem-calc(109);
                    }
                    .cc-prodTile-imageContainer-image{
                        height: 100%;
                        border-radius: 0;
                        min-height: rem-calc(162);
                    }
                }
            }
            .cc-prodTile-link{
                position: absolute;
                width: 100%;
                height: 100%;
                display: block;
                padding: 0;
                z-index: 1; 
            }
            .cc-cartPromotionModal-productCards-body{
                padding: rem-calc(16 39 0 16);
                max-width: calc(100% - #{rem-calc(109)});
                @include breakpoint(large up) {
                    padding: rem-calc(16 62 0 16);
                }
                &_size{
                    gap: rem-calc(4);
                }
                &-confirmation{
                    display: flex;
                    align-items: center;
                    .cc-womanSite & {
                        color: $promo;
                        &.cc-dashboardPromo__item__label--woman {
                            background-color: $blush;
                        }
                        &.cc-dashboardPromo__item__label--man {
                            background-color: $rust;
                        }
                    }
                    .cc-manSite & {
                        color: $rust;
                        &.cc-dashboardPromo__item__label--woman {
                            background-color: $blush;
                        }
                        &.cc-dashboardPromo__item__label--man {
                            background-color: $rust;
                        }
                    }
                    &_icon{
                        margin-right: rem-calc(8);
                        .cc-manSite & {
                            filter: brightness(0) saturate(100%) invert(35%) sepia(47%) saturate(1057%) hue-rotate(332deg) brightness(110%) contrast(96%);
                        }
                    }
                }
                .cc-productPromo-completeYourPromo-link{
                    position: absolute;
                    bottom: rem-calc(16);
                    right: rem-calc(16);
                    max-width: rem-calc(24);
                    max-height: rem-calc(24);
                    svg.small{
                        width: rem-calc(12);
                        height: rem-calc(12);
                    }
                } 
                .cc-prodTile{
                    gap: rem-calc(4);
                    pointer-events: none;
                    & .cc-wishlist-add-to-cart{
                        pointer-events: visible;
                    }
                    @include breakpoint(medium down) {
                        flex-direction: column;
                        border: none;
                        border-radius: 0;
                    }
                    &-name{
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                    }
                }
            }
        }
        &:not(.cc-cartPromotionModal-productCards-wishlist){
            .cc-cartPromotionModal-productCards-product{
                &:last-of-type{
                 margin-bottom: rem-calc(40);
                }
            }
        }
    }
    &-footer{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        border-top: rem-calc(1) solid $cloud;
        padding: rem-calc(16);
        background-color: $white;
        &-tracker{
            width: 100%;
            gap: rem-calc(8);
            padding: rem-calc(16 0);
            &_item{
                flex-grow: 1;
                height: rem-calc(3);
                background-color: $cloud;
                border-radius: rem-calc(3);
                &_active{
                    .cc-womanSite & {
                        background-color: $promo;
                    }
                    .cc-manSite & {
                        background-color: $rust;
                    }
                    .cc-dashboardPromo__item__label--woman & {
                        background-color: $blush;
                    }
                    .cc-dashboardPromo__item__label--man & {
                        background-color: $rust;
                    }
                    .cc-dashboardPromo__item__label--loyal & {
                        background-color: $dijon;
                    }
                }
            }
        }
        &-button{
            width: 100%;
        }
        &_completed{
            .cc-womanSite & {
                color: $promo;
            }
            .cc-manSite & {
                color: $rust;
            }   
            .cc-dashboardPromo__item__label--woman & {
                .cc-womanSite &, .cc-manSite & {
                    color: $promo;
                }  
            }
            .cc-dashboardPromo__item__label--man & {
                .cc-womanSite &, .cc-manSite & {
                    color: $rust;
                }  
            }     
            .cc-dashboardPromo__item__label--loyal & {
                .cc-womanSite &, .cc-manSite & {
                    color: $dijon;
                }  
            }     
        }
    }
}
.cc-completePromotionModal{
    z-index: 2008;
    @include breakpoint(large up) {
        background: transparent;
    }
    &.quickview-modal{
        &.quickview-modal--intimissimi{
            .close-custom {
                display: none;
                &.cc-completePromotionModal-backCustom {
                    display: block;
                    left: rem-calc(16);
                }
            }
            .tingle-modal-box{
                @include breakpoint(medium down) {
                    max-height: 75%;
                    height: 75%;
                    top: unset;
                    bottom: 0;
                    min-width: 100%;
                    width: 100%;
                }
                &__content{
                    padding-bottom: 0;
                    padding-top: 0;
                    .product-quickview--get-product{
                        &.product-quickview{
                            padding-bottom: 0;
                            padding-top: rem-calc(56);
                            position: relative;      
                            &::-webkit-scrollbar {
                                width: rem-calc(2);
                                background-color: $cloud;
                            }
                            &::-webkit-scrollbar-thumb {
                                background-color: $fossil;
                            }
                            @include breakpoint(large up) {
                                padding-bottom: rem-calc(80);
                            }
                            .wrapper-quickview{
                                .wrapper-product{
                                    padding-bottom: 0;
                                    .wrapper-details{
                                        padding-bottom: rem-calc(32);
                                    }
                                    .wrapper-inner-quick-bottom{
                                        position: fixed;
                                        @include breakpoint(medium down) {
                                            position: relative;
                                            margin-left: rem-calc(-32);
                                            width: calc(100vw - #{rem-calc(2)});
                                        }
                                        @include breakpoint(small down) {
                                            margin-left: rem-calc(-16);
                                        }
                                        .wrapper-inner-quick-quantity{
                                            .cc-quick-quantity{
                                                pointer-events: none;
                                            }
                                        }
                                    }
                                }
                                .primary-images {
                                    .carousel-inner{
                                        .carousel-item{
                                            img{
                                                min-height: rem-calc(221);
                                                width: 100%;
                                                object-fit: cover;
                                                @include breakpoint(large up) {
                                                    min-height: rem-calc(250);
                                                    max-height: rem-calc(250);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .pdp-size-box{
                                .selectSizeQuickView{
                                    h4{
                                        margin: rem-calc(24 0);
                                        font-size: rem-calc(14);
                                        font-weight: $global-weight-normal;
                                        @include breakpoint(large up) {
                                            margin: rem-calc(16 0);
                                        }
                                    }
                                }
                            }
                            .carousel-select-size{
                                .product-content-size{
                                    gap: rem-calc(6 8);
                                    width: 100%;
                                    float: left;
                                    display: flex;
                                    flex-wrap: wrap;
                                    .label-wrapper{
                                        width: rem-calc(79);
                                        height: rem-calc(40);
                                        flex: none;
                                        @include breakpoint(large up) {
                                            width: rem-calc(85);
                                        }
                                        & label{
                                            padding: 0;
                                            float: left;
                                            &:last-of-type{
                                                display: flex;
                                            }
                                        }
                                    }
                                }
                            }
                            .cc-intProductDetail-attributes-wrapper{
                                display: none;
                                .carousel-select-color{
                                    margin-bottom: 0;
                                }
                            }
                        }
                        .cc-intProductDetail-colors-heading{
                            .selected-color-value{
                                margin-top: rem-calc(4);
                            }
                        }
                    }
                }
            }
        }
    }
    .add-to-cart-global{
        width: 100%;
    }
    .see-product-details{
        display: none;
    }
}
.product-content-size {
    label {
        &.swatch-boolean {
            .radio-label:not(.notifyMe){
                .alertNotify{
                    display: none;
                }
            }
            & input[type='radio']:checked + .radio-label {
                text-decoration: none;
                background-color: $charcoal;
                color: $white;
            }
        }
    }
}
.cc-cartQuickView_selectedSize {
    pointer-events: none;
    cursor: default;
}
.cc-manSite, .cc-womanSite{
    .cc-dashboardPromo__item__label--woman{
        &.cc-productPromo-textWrapper, .cc-productPromo-completeYourPromo-text{
            color: $promo;
            & .cc-productPromo-textWrapper_name{
                background: $promo;
            }
        }
        & .cc-cartPromotionModal-productCards {
            & .cc-cartPromotionModal-productCards-product_active{
                border-color: $promo;
            }
            & .cc-cartPromotionModal-productCards-product{
                & .cc-cartPromotionModal-productCards-body-confirmation{
                    color: $promo;
                    &_icon{
                        filter: none;
                    }
                }
            }
        }
        & .marketing-budge {
            &.cc-cartPromotionModal-header-budge{
                background-color: $promo;     
            }
        }
    }
    .cc-dashboardPromo__item__label--man{
        &.cc-productPromo-textWrapper, .cc-productPromo-completeYourPromo-text{
            color: $rust;
            & .cc-productPromo-textWrapper_name{
                background: $rust;
            }
        }
        & .cc-cartPromotionModal-productCards {
            & .cc-cartPromotionModal-productCards-product_active{
                border-color: $rust;
            }
            & .cc-cartPromotionModal-productCards-product{
                & .cc-cartPromotionModal-productCards-body-confirmation{
                    color: $rust;
                    &_icon{
                        filter: brightness(0) saturate(100%) invert(35%) sepia(47%) saturate(1057%) hue-rotate(332deg) brightness(110%) contrast(96%);
                    }
                }
            }
        }
        & .marketing-budge {
            &.cc-cartPromotionModal-header-budge{
                background-color: $rust;     
            }
        }
    }
    .cc-dashboardPromo__item__label--loyal {
        &.cc-productPromo-textWrapper, .cc-productPromo-completeYourPromo-text{
            color: $dijon;
            & .cc-productPromo-textWrapper_name{
                background: $dijon;
            }
        }
        & .cc-cartPromotionModal-productCards {
            & .cc-cartPromotionModal-productCards-product_active{
                border-color: $dijon;
            }
            & .cc-cartPromotionModal-productCards-product{
                & .cc-cartPromotionModal-productCards-body-confirmation{
                    color: $dijon;
                    &_icon{
                        filter: brightness(0) saturate(100%) invert(37%) sepia(59%) saturate(2300%) hue-rotate(29deg) brightness(90%) contrast(101%);
                    }
                }
            }
        }
        & .marketing-budge {
            &.cc-cartPromotionModal-header-budge{
                background-color: $dijon;     
            }
        }
    }
}
.tingle-modal {
    &.cc-notifyMeModal {
        z-index: 2009;
    }
}