//BUBBLE CHAT BUTTON Z-INDEX 1000

// Z-INDEX CONSTANT GENERIC
$zIndex-hidden: -10;
$zIndex-carouselArrow: 3; //arrow carousel
$zIndex-simpleUpper: 5;
$zIndex-upper: 10;
$zIndex-upperHeader: 15;
$zIndex-bodyVeiled: 50;

//MENU
$zIndex-menu-overlay: 1995;
$zIndex-menu-subcategoryThird: 1998;
$zIndex-menu-subcategorySecond: 2000;
$zIndex-menu: 2005;
$zIndex-searchBar: 2005;

//SEARCH FILE
$zIndex-search-refineBar: $zIndex-upper;
$zIndex-search-overlay: 1010;
$zIndex-searchRefinement-menu: 1020;

//PRODUCT TILE
$zIndex-quickAddModal: 1010;

//LAYER ABOVE MODAL WITH RIGHTMODALATTRIBUTE CLASS
$zIndex-layerAboveRightModal: 2060;
$zIndex-quickAddAboveRightModal: 2070;