.boolean-field {
  @include flexGenerator(row, flex-start, center);
  cursor: pointer;
  margin-bottom: $global-margin;
  position: relative;
  &.disabled {
    cursor: not-allowed;
  }
  padding-right: rem-calc(16);
  @include breakpoint(large up) {
    padding-right: rem-calc(30);
  }

  //COMMON PART
  input {
    height: 0;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 0;

    &:checked {
      & ~ .checkbox-input {
        &::before {
          border: 0;
        }
        &::after {
          opacity: 1;
          transform: scale3d(1, 1, 1);
        }
      }
      & ~ .radio-input {
        &:before {
          border-color: $black;
        }
        &::after {
          opacity: 1;
          transform: scale3d(1, 1, 1) translate(50%, 50%);
        }
      }
    }

    &:focus-visible {
      & ~ .checkbox-input,
      & ~ .radio-input {
        &::before {
          border-color: $alert;
        }
      }
    }

    &:disabled {
      & ~ .checkbox-input,
      & ~ .checkbox-label {
        opacity: 0.3;
      }
      & ~ .radio-label {
        color: $charcoal;
        opacity: 0.5;
      }

      & ~ .radio-input::before {
        border-color: $charcoal;
        opacity: 0.3;
      }
    }
  }

  .checkbox-label,
  .radio-label {
    color: $charcoal;
    flex: 1 1 0px;
  }

  .checkbox-input,
  .radio-input {
    display: inline-block;
    flex: 0 0 auto;
    margin-right: rem-calc(8);
    position: relative;
    text-transform: none;

    &:before,
    &:after {
      content: "";
      position: absolute;
    }

    &:before {
      background-color: transparent;
      border: rem-calc(1) solid $charcoal;
      left: 0;
      top: 0;
      transition: none;
    }

    &:after {
      left: 0;
      opacity: 0;
      transform: scale3d(0, 0, 0);
      transition: all 0.1s;
    }
  }

  &.is-invalid-input {
    background-color: transparent;
    input:not(:focus):not(:checked) + {
      .radio-input:before,
      .checkbox-input:before {
        border-color: $cherry;
      }
    }
  }

  .checkbox-input {
    border-radius: rem-calc(4);
    height: rem-calc(20);
    width: rem-calc(20);
    @include breakpoint(large up) {
      height: rem-calc(18);
      width: rem-calc(18);
    }
  
    &:before {
      border-radius: rem-calc(4);
      height: rem-calc(20);
      width: rem-calc(20);
      @include breakpoint(large up) {
        height: rem-calc(18);
        width: rem-calc(18);
      }
    }
  
    &:after {
      background-image: url("../images/checkbox-sprite.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: rem-calc(20);
      height: rem-calc(20);
      width: rem-calc(20);
      @include breakpoint(large up) {
        height: rem-calc(18);
        width: rem-calc(18);
        background-size: rem-calc(18);
      }
    }
  }
  
  .radio-input {
    height: rem-calc(24);
    width: rem-calc(24);
    @include breakpoint(large up) {
      height: rem-calc(18);
      width: rem-calc(18);
    }
  
    &:before {
      border-radius: 50%;
      height: rem-calc(24);
      width: rem-calc(24);
      @include breakpoint(large up) {
        height: rem-calc(18);
        width: rem-calc(18);
      }
    }
  
    &:after {
      background-color: $charcoal;
      border-radius: 50%;
      height: rem-calc(12);
      width: rem-calc(12);
      @include breakpoint(large up) {
        height: rem-calc(8);
        left: rem-calc(1);
        top: rem-calc(1);
        width: rem-calc(8);
      }
    }
  
    &.error-refund {
      &:before {
        border-color: $alert;
      }
      &:after {
        background-color: $alert;
      }
    }
  }
}

//COLOR REFINEMENT
.checkbox-input-round {
  display: inline-block;
  margin: 0;
  padding-left: rem-calc(20);
  position: relative;
  text-transform: none;

  &:before,
  &:after {
    content: "";
    position: absolute;
  }

  &:before {
    top: rem-calc(2);
    left: rem-calc(1);
    width: rem-calc(12);
    height: rem-calc(12);
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid transparent;
    transition: all 0.2s;
  }

  &:after {
    opacity: 0;
    top: 0;
    left: rem-calc(-1);
    width: rem-calc(16);
    height: rem-calc(16);
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid transparent;
    transition: all 0.2s;
  }
}
