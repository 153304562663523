.cc-categoryThanks{
    position: relative;
    padding: rem-calc(0 16 40 16);
    @include breakpoint (large up){
        padding: rem-calc(0 32 60 32);
    }
    &_title{
        margin-bottom: rem-calc(32);
        @include breakpoint (large up){
            margin-bottom: rem-calc(48);
        }
    }
    &_subtitle{
        margin-bottom: rem-calc(8);
    }
    &-card{
        width: 100%;
        min-height: rem-calc(240);
        height: 29.55665024630542vh;
        margin-bottom: rem-calc(16);
        border-radius: rem-calc(4);
        overflow: hidden;
    }
    &-container{
        gap: rem-calc(32);
        @include breakpoint (medium down){
            gap: rem-calc(8);
            overflow-x: scroll;
            overflow-y: hidden;
            align-items: center;
            flex-wrap: nowrap;
        }
        &-card{
            border-radius: rem-calc(4);
            overflow: hidden;
            @include breakpoint (medium down){
                width: 39.46666666666667vw;
            }
            &_link{
                position: relative;
                height: 100%;
                display: flex;
                &_image{
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                }
                &_text{
                    width: 100%;
                    position: absolute;
                    bottom: rem-calc(16);
                    margin: 0;
                    display: block;
                    font-size: rem-calc(18);
                    left: rem-calc(16);
                    color: $white;
                    line-height: 135%;
                }
            }
        }
    }

}