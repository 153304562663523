$brand-palette: (
    promo: #D7375E,
    promo-men: #BE4C28,
    newin: #D72359,
    gray: #7F7F7F,
    dark-gray: #333333,
    medium-gray: #E8E8E8,
    gray-balance: #ebece6,
    gray-yellow: #F8F7F2,
    medium-more-gray: #C8C8C8,
    darkish-gray: #6d6d6d,
    medium-light-gray: #999999,
    more-light-gray: #F7F7F7,
    light-gray: #F2F0EB,
    bg-bra-gray: #f7f6f2,
    border-table-gray: #E0E0E0,
    component-grey: #F7F7F7,
    price-banner: #F9F8F6,
    very-light-gray: #CCCCCC,
    over-gray:#F9F9F9,
    light-over-gray:#F1F1F1,
    ivory: #F6F6F5,
    white-rgba: rgba(255, 255, 255, 0.7),
    alert-light: #FDF4F3,
    christmas: #ca1617,
    brand-green: #8AC492,
    free-shipping: #6295F7,
    brand-blue: #2C73D2,
    orange: #FA9917,
    note-blue: #D2DEF5,

    /* Restyle 2023 */
    black: #000000,
    white: #FFFFFF,
    dark-grey: #4E4E53,
    dark-blue: #283049,
    cherry: #CC0D14,
    lemon: #FFCA8C,
    honey: #A7671C,
    coral: #FF949E,
    olive: #A5BF87,
    cornflower: #D4DEF3,
    sapphire: #52699A,
    dijon: #A36A00,
    cloud: #E9E9E9,
    charcoal: #3C3738,
    mocha: #8F6660,
    fossil: #757575,
    pebble: #BCBCBC,
    apple: #DB0F22,
    off-white: #F9F8F6,
    beige: #D7C4B7,
    porcelain: #DDBBAF,
    blush: #D7375E,
    mauve: #954150,
    peacock: #396A89,
    rust: #D4562E,
    jade: #367364,
    emerald: #3A8352,
    darkgreen: #1C5C59,
    whisper: #ECECEC,
    paypalYellow: #FFC43A,
    klarnaPink: #FFB3C7,
    amazonGradient1: #FFE7A8,
    amazonGradient2: #F6C84B,
    infiniteInventory: #A75910
);

$foundation-palette: (
    primary: #3C3738,
    secondary: #85715D,
    success: #8AB349,
    warning: #FFA12E,
    alert: #DD2B2E,
);

$buttons-palette-black-text: (
    primary: map-get($brand-palette, white),
    christmas: map-get($brand-palette, christmas),
    secondary: map-get($brand-palette, light-gray)
);

$buttons-palette-white-text: (
    black: map-get($brand-palette, black)
);

$buttons-palette: (
    button-promo-copy: #F2F0EC
);

$button-promo-copy: map-get($buttons-palette, button-promo-copy);

$cherry: map-get($brand-palette, cherry);
$primary: $cherry;
$sales: $primary;
$secondary: map-get($brand-palette, promo);
$promo: $secondary;
$tertiary: map-get($brand-palette, newin);
$newin: $tertiary;
$shadow-text: map-get($brand-palette, shadow-text);
$black: map-get($brand-palette, black);
$gray: map-get($brand-palette, gray);
$very-light-gray: map-get($brand-palette, very-light-gray);
$ivory: map-get($brand-palette, ivory);
$dark-gray: map-get($brand-palette, dark-gray);
$medium-dark-gray: map-get($brand-palette, darkish-gray);
$medium-gray: map-get($brand-palette, medium-gray);
$gray-balance: map-get($brand-palette, gray-balance);
$bg-bra-gray: map-get($brand-palette, bg-bra-gray);
$gray-yellow: map-get($brand-palette, gray-yellow);
$medium-more-gray: map-get($brand-palette, medium-more-gray);
$medium-light-gray: map-get($brand-palette, medium-light-gray);
$more-light-gray: map-get($brand-palette, more-light-gray);
$light-gray: map-get($brand-palette, light-gray);
$border-table-gray: map-get($brand-palette, border-table-gray);
$border-gray: $medium-gray;
$border-lighter-gray: #E9E9E9;
$border-light-gray: lighten($more-light-gray, 10%);
$white: map-get($brand-palette, white);
$white-rgba: map-get($brand-palette, white-rgba);
$note-blue: map-get($brand-palette, note-blue);
$cloud: map-get($brand-palette, cloud);
$charcoal: map-get($brand-palette, charcoal);
$mocha: map-get($brand-palette, mocha);
$dark-blue: map-get($brand-palette, dark-blue);
$dark-grey: map-get($brand-palette, dark-grey);
$fossil: map-get($brand-palette, fossil);
$pebble: map-get($brand-palette, pebble);
$apple: map-get($brand-palette, apple);
$failed: $apple;
$off-white: map-get($brand-palette, off-white);
$beige: map-get($brand-palette, beige);
$porcelain: map-get($brand-palette, porcelain);
$blush: map-get($brand-palette, blush);
$mauve: map-get($brand-palette, mauve);
$jade: map-get($brand-palette, jade);
$darkgreen: map-get($brand-palette, darkgreen);
$whisper: map-get($brand-palette, whisper);
$paypalYellow: map-get($brand-palette, paypalYellow);
$klarnaPink: map-get($brand-palette, klarnaPink);
$amazonGradient1: map-get($brand-palette, amazonGradient1);
$amazonGradient2: map-get($brand-palette, amazonGradient2);
$infiniteInventory: map-get($brand-palette, infiniteInventory);
// $teal: map-get($brand-palette, teal);
// $plum: map-get($brand-palette, plum);
// $pine: map-get($brand-palette, pine);
$peacock: map-get($brand-palette, peacock);
$rust: map-get($brand-palette, rust);
// $intimissimi-cares: map-get($brand-palette, intimissimi-cares);
$promo-men: map-get($brand-palette, promo-men);
$honey: map-get($brand-palette, honey);
$sapphire: map-get($brand-palette, sapphire);
$coral: map-get($brand-palette, coral);
$error: $coral;
$emerald: map-get($brand-palette, emerald);
$olive: map-get($brand-palette, olive);
$success: $olive;
$dijon: map-get($brand-palette, dijon);
$loyalty: map-get($brand-palette, dijon);
$cornflower: map-get($brand-palette, cornflower);
$lemon: map-get($brand-palette, lemon);
$alert: $lemon;
$warning: $lemon;
$alert-light: map-get($brand-palette, alert-light);
$christmas: map-get($brand-palette, christmas);
$brand-green: map-get($brand-palette, brand-green);
$brand-blue: map-get($brand-palette, brand-blue);
$over-gray: map-get($brand-palette, over-gray);
$light-over-gray: map-get($brand-palette, light-over-gray);

$component-grey: map-get($brand-palette, component-grey);
$pink-banner: map-get($brand-palette, pink-banner);