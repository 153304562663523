.nav {
    margin: 0;

    &-tabs {
        list-style-type: none;
        display: flex;
    }

    &-item {
        flex: 1 1;
    }
}


/* TABS PAGE  */
.container-tabs {
    width: 100%;

    ul {
        padding: 0;
        margin: 0;
        &.tabs{
            margin: rem-calc(0 0 35 0);
            padding: 0;
            list-style: none;
            //background-image: url('../images/pixel-grey.png');  
            background-position: bottom; 
            background-repeat: repeat-x;

        
            li {
                background: none;
                color: $medium-light-gray;
                display: inline-block;
                border-bottom: rem-calc(4) solid transparent;
                padding: rem-calc(5 10 0 10);
                font-weight: 500;
                font-size: rem-calc(15);
                cursor: pointer;
                margin-right: rem-calc(5);
                @include breakpoint(small down) {
                    margin-right: rem-calc(0);
                }
                &:last-child {
                    margin-right:0;
                }
            
                &.current {
                    background:none;
                    color: $black;
                }
            }
            &.tabs-center{
                text-align: center;
            }
        }
    }

    .tab-content {
        display: none;
        background: none;
        padding: rem-calc(15 0);
    

        &.current {
            display: inherit;
        }
    }
}

