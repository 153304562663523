@import '../setup/settings';
$slide-out-animation-timing: 0.6s ease-in-out;
$slide-out-animation: left $slide-out-animation-timing, right $slide-out-animation-timing;

html {
    &.cc-menu-isOpen {
        overflow: hidden;
        @include breakpoint(medium down) {
            .cc-layer-login {
                z-index: 2006;
            }
        }
    }
}

.cc-intMenu {
    &-overlay {
        @include breakpoint(large up) {
            position: fixed;
            display: none;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.4);
            z-index: $zIndex-menu-overlay;
        }
    }

    &-svgIcon {
        &-i {
            line-height: 0;
        }

        &_footer {
            color: $mocha;
            margin-right: 0;
            @include fixHeight(16px);
            @include fixWidth(16px);
            stroke-width: rem-calc(2);

            &.ca-intMenu-svgIcon_footer {
                padding: rem-calc(1);
            }
        }

        &_close {
            @include fixHeight(24px);
            @include fixWidth(24px);
            cursor: pointer;
        }
    }

    &-top {
        border-bottom: rem-calc(1) solid $cloud;
        flex: 0 0 auto;
        display: inline-flex;
        background-color: $white;
        z-index: $zIndex-menu;
        padding: rem-calc(16);
        right: -100%;
        transition: $slide-out-animation;
        @include breakpoint(small up) {
            height: rem-calc(56);
            position: fixed;
            top: 0;
            width: 100%;

            &.cc-open-close-container {
                right: 0;
            }
        }
        @include breakpoint(large up) {
            padding: rem-calc(21 40);
            height: rem-calc(66);
            position: sticky;
            top: 0;
        }

        .cc-intMenu_isOpen & {
            right: 0;
        }

        &-text {
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            margin-left: rem-calc(8);
            display: inline-block;
        }
    }

    &-category,
    &-subCategory,
    &-thirdLevel {
        &-container {
            position: fixed;
            left: -100%;
            top: 0;
            bottom: 0;
            transition: $slide-out-animation;
            display: block;
            background: $white;
            z-index: $zIndex-menu;
            top: rem-calc(56);
            overflow-y: auto;
            overflow-x: hidden;
            @include flexGenerator(column);

            @include breakpoint(small up) {
                left: 100vw;
                width: 100vw;
            }
            @include breakpoint(large up) {
                left: -33.3333vw;
                width: 33.3333vw;
                top: 0;
                overflow-y: hidden;
            }
            @include breakpoint(xlarge up) {
                left: -25vw;
                width: 25vw;
            }

            &.cc-removeTransition {
                transition: none;
            }
        }
    }

    &-category {
        position: relative;
        flex-grow: 1;
        @include breakpoint(large up) {
            display: flex;
            padding: rem-calc(40 24 40 40);
            overflow-y: auto;
        }

        &-block {
            padding: rem-calc(32 16 48);

            @include breakpoint(large up) {
                display: none;
                height: unset;
                overflow-y: auto;
                overflow-x: hidden;
                padding: 0;
                &::-webkit-scrollbar {
                    width: 0;
                }
                &:hover {
                    @include customScrollbar;
                }
            }

            &_active {
                @include flexGenerator(column);
                flex-grow: 1;
            }

            @include breakpoint(medium down) {
                position: absolute;
                top: 0;
                width: 100%;
                transition: left 0.8s ease, opacity 0.7s ease;
                opacity: 0;

                &-INT_Woman {
                    left: -100%;
                }

                &-INT_Man {
                    left: 100%;
                }

                &_active {
                    opacity: 1;
                    left: 0;
                }
            }

            &-container {
                margin-bottom: rem-calc(40);
                gap: rem-calc(24);
                @include flexGenerator(column);
                @include breakpoint(large up) {
                    gap: rem-calc(12);
                }
            }

            &-link {
                font-size: rem-calc(16);
                line-height: rem-calc(24);

                &_notClickable {
                    pointer-events: none;
                }

                &_hasBackground {
                    /*
                    &, &:hover {
                        color: $white;
                    }
                    */
                    & > span {
                        padding: rem-calc(2 10);
                        border-radius: rem-calc(2);
                        /* background: $cherry; */
                    }
                }
            }
        }
    }

    &-subCategory,
    &-thirdLevel {
        padding: rem-calc(16);
        @include breakpoint(large up) {
            padding: rem-calc(40 24 40 40);
            height: 100%;
            border-top: rem-calc(1) solid $cloud;
            border-left: rem-calc(1) solid $cloud;
        }

        &-name {
            padding-bottom: rem-calc(16);
            margin-bottom: rem-calc(32);
            display: flex;
            align-items: center;

            &::before {
                content: '';
                height: rem-calc(20);
                width: rem-calc(20);
                transform: rotate(180deg);
                display: inline-block;
                margin-right: rem-calc(12);
                background-image: url('../../images/arrow-right-steps.svg');
                background-size: rem-calc(20);
            }
        }

        &-content {
            @include breakpoint(large up) {
                overflow-y: auto;
                overflow-x: hidden;
                max-height: 100%;
                &::-webkit-scrollbar {
                    width: 0;
                }
                &:hover {
                    @include customScrollbar;
                }
            }
        }

        &-block {
            @include flexGenerator(column);
            gap: rem-calc(4);
            &:not(:last-child) {
                margin-bottom: rem-calc(32);
            }
            @include breakpoint(large up) {
                margin-bottom: rem-calc(24);
                gap: 0;
            }
        }

        &-title {
            color: $fossil;
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            margin-bottom: rem-calc(8);
        }

        &-title,
        &-item {
            padding: rem-calc(8 0);
        }

        &-item {
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            transition: text-decoration 0.8s ease;
            text-decoration-color: transparent;

            &:hover {
                text-decoration: underline;
            }
        }

        &-container {
            z-index: $zIndex-menu;
            @include breakpoint(large up) {
                padding-top: rem-calc(65);
                z-index: $zIndex-menu-subcategorySecond;
            }
        }

        &-thirdColumn {
            &:not(:empty) {
                padding: rem-calc(8 16 48);
            }
        }
    }

    &-thirdLevel {
        @include breakpoint(large up) {
            overflow: auto;

            &-container {
                z-index: $zIndex-menu-subcategoryThird;
            }
        }
    }

    /* Switch Gender Content Asset - Mobile */
    &-switchGender {
        display: flex;
        height: 36.26666666666667vw;

        &-content {
            position: relative;
            height: 100%;

            &-image {
                height: 100%;
                object-fit: cover;
                object-position: center;
            }

            &-text {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: $white;
                text-align: center;
                font-size: rem-calc(10);
                line-height: rem-calc(15);
                width: 100%;
            }

            &-logo {
                height: rem-calc(25);
                margin-top: rem-calc(4);
            }
        }

        &-link {
            width: 41.6%;
            transition: width 0.7s ease;
            position: relative;
            flex-grow: 1;

            & > div {
                height: 100%;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: rgba($color: $black, $alpha: 0.4);
                z-index: $zIndex-simpleUpper;
                opacity: 1;
                transition: opacity 0.7s ease;
            }

            &-isActive {
                width: 58.4%;
                flex: 0 0 auto;

                &::before {
                    opacity: 0;
                }
            }
        }
    }

    &-footer {
        margin-top: auto;

        &-block {
            display: flex;
            flex-direction: column;
            padding: rem-calc(32 0);
            border-top: rem-calc(1) solid $cloud;
            @include breakpoint(large up) {
                padding: 0;
                border: 0;
            }

            &:first-child {
                @include breakpoint(large up) {
                    margin-bottom: rem-calc(24);
                }
            }

            &:last-child {
                padding-bottom: 0;
            }
        }

        &-login {
            @include flexGenerator(row, space-between, center);

            &-item {
                width: 100%;
                display: flex;
                a:not(.js-profileText){
                    color: $fossil;
                    margin-top: rem-calc(8);
                    margin-bottom: rem-calc(8);
                }
                .cc-profileText-header {
                    margin: rem-calc(0 0 8 24);
                    display: block;
                }
            }
        }

        &-item {
            @include flexGenerator(row, flex-start, center);
            flex-flow: row wrap;
            &:not(:last-child) {
                margin-bottom: rem-calc(16);
                @include breakpoint(large up) {
                    margin-bottom: rem-calc(10);
                }
            }

            &-link {
                margin-left: rem-calc(8);
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                @include breakpoint(large up) {
                    font-size: rem-calc(12);
                    line-height: rem-calc(18);
                }
            }

            .cc-mainHeader {
                &-svgIcon {
                    margin: 0;
                    color: $mocha;
                    @include fixHeight(16px);
                    @include fixWidth(16px);
                    stroke-width: rem-calc(2);

                    &_logged {
                        @include fixHeight(20px);
                        @include fixWidth(20px);
                    }
                }

                &-wishlist {
                    &-label {
                        margin-left: rem-calc(8);
                        display: inline-block;
                        font-size: rem-calc(14);
                        line-height: rem-calc(21);
                    }
                }

                &-login {
                    & > a {
                        display: flex;
                        align-items: center;
                    }

                    &-label {
                        margin-left: rem-calc(8);
                        display: inline-block;
                        font-size: rem-calc(14);
                        line-height: rem-calc(21);
                        @include breakpoint(large up) {
                            font-size: rem-calc(12);
                            line-height: rem-calc(18);
                        }

                        &_logged {
                            margin-left: rem-calc(4);
                        }
                    }
                }

                &-wishlist {
                    margin-right: 0;
                }
            }
        }
    }
}

.cc-intCat {
    &-menuImage,
    &-trendingCategory {
        @include breakpoint(large up) {
            height: 100%;
            overflow: auto;
            padding-right: rem-calc(16);
            &::-webkit-scrollbar {
                width: 0;
            }
            &:hover {
                @include customScrollbar;
            }
        }
    }
}

.cc-open-firstLvlCat {
    display: flex;
    align-items: center;

    @include breakpoint(large up) {
        .cc-intMenu-category-block-link-icon {
            display: block;
            opacity: 0;
            line-height: 0;
            margin-left: rem-calc(5);
            transition: all 0.4s ease;
        }

        &:hover,
        &.active {
            .cc-intMenu-category-block-link-icon {
                margin-left: rem-calc(10);
                opacity: 1;
                display: block;
            }
        }
    }
}

.cc-trendingProducts {
    display: flex;
    flex-flow: nowrap;
    overflow-x: auto;
    gap: rem-calc(8);
    width: calc(100% + #{rem-calc(16)});
    scrollbar-width: none;
    @include breakpoint(large up) {
        flex-flow: row wrap;
        gap: rem-calc(60 16);
        width: 100%;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    &-tile {
        width: 49.06666666666667vw;
        flex: 0 0 auto;
        @include breakpoint(large up) {
            width: calc(50% - #{rem-calc(8)});
        }

        &:last-child {
            margin-right: rem-calc(16);
            @include breakpoint(large up) {
                margin-right: 0;
            }
        }

        .cc-prodTile-quickAdd-button {
            @include breakpoint(large up) {
                display: none;
            }
        }
    }

    &-title {
        margin-bottom: rem-calc(16);
        padding: rem-calc(8 0);
        color: $fossil;
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        @include breakpoint(large up) {
            margin-bottom: rem-calc(24);
        }
    }
}

.cc-categoryPromotions {
    @include flexGenerator(column);
    gap: rem-calc(16);

    &-item {
        position: relative;
        background-color: $off-white;
        display: flex;
        &::after {
            content: url('../../images/arrow-right-steps.svg');
            position: absolute;
            bottom: rem-calc(16);
            right: rem-calc(16);
            @include fixHeight(16px);
            @include fixWidth(16px);
        }

        &-image {
            width: 26.66666666666667vw;
            flex: 0 0 auto;
            @include breakpoint(large up) {
                width: 27.77777777777778%;
            }
        }

        &-text {
            flex: 1 1 0;
            padding: rem-calc(16);
            font-size: rem-calc(getFontSize('textL'));
            line-height: rem-calc(getLineHeight('textL'));
            letter-spacing: rem-calc(getLetterSpacing('textL'));
            @include breakpoint(large up) {
                font-size: rem-calc(getFontSize('text'));
                line-height: rem-calc(getLineHeight('text'));
                letter-spacing: rem-calc(getLetterSpacing('text'));
            }
        }
    }
}
