html {
    overflow-x: hidden;
}

::selection {
    background-color: $black;
    color: $white;
}

// Footer Fixes
.page {
    min-height: 100vh;

    &.cc-inHomepage {
        @include breakpoint(large up) {
            padding-top: rem-calc(16);
        }
    }
}
body {
    &::before {
        background-color: transparent;
        content: '';
        display: block;
        position: fixed;
        z-index: $zIndex-bodyVeiled;
        transition: background-color;
        transition-duration: 0.3s;
    }
    &.veiled::before {
        /* Necessario per avere l'animazione */
        right: 0;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.4);
    }
    &.veiled-for-small-only {
        @include breakpoint(medium down) {
            &_higher {
                &::before {
                    z-index: $zIndex-quickAddAboveRightModal;
                }
            }
            &::before {
                /* Necessario per avere l'animazione */
                right: 0;
                top: 0;
                left: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.4);
            }
        }
    }
}
