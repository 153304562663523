.alert[role="alert"], .cart-alert[role="alert"] {
    padding: rem-calc(20);
    border: none;

    &.alert-danger {
        background: rgba($alert, 0.06);
        color: $alert;
    }

    &.alert-warning {
        background: rgba($warning, 0.06);
        color: darken($warning, 10%);
    }

    &.alert-blue {
        background: #D2DEF5;
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.not-available-banner {
    padding: rem-calc(20);
    border: none;
    color: $alert;
}

.out-of-stock-line-item {
    background: rgba($alert, 0.06);
}