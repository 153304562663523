@import '../components/privacyPolicy';
@import '../mixins/helpers';

.cc-mainHeader {
    background: transparent;
    position: relative;
    padding-top: rem-calc(16);
    padding-bottom: rem-calc(16);
    position: relative;
    transition: all 0.5s ease;
    opacity: 1;
    @include fixHeight(rem-calc(66));
    @include flexGenerator(row, space-between, center);

    &_default {
        @include breakpoint(large up) {
            top: rem-calc(16);
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            background: white;
            height: 100%;
            z-index: -1;
            top: calc(-100% - #{rem-calc(16)});
            transition: top 0.5s ease;
        }
    }

    &.cc-light {
        &:not(.cc-solid) {
            .cc-mainHeader-svgIcon,
            .cc-mainHeader-label,
            .cc-mainHeader-counter,
            .cc-mainHeader-search {
                color: $white;
            }
        }
    }

    &.cc-solid {
        &,
        &::before {
            top: 0;
        }

        .cc-mainHeader-login-onHover {
            top: rem-calc(66);
        }
    }

    &_checkout,
    &_prehome,
    &_banner,
    &_orderConfirm,
    &_registration,
    &_splashPage {
        background: $white;
        border-bottom: rem-calc(1) solid $cloud;
        justify-content: center;
        @include breakpoint(medium down) {
            padding: rem-calc(16);
        }
    }

    &-label {
        transition: color 0.5s ease;
    }

    &-svgIcon {
        @include fixWidth(rem-calc(24));
        @include fixHeight(rem-calc(24));
        transition: color 0.5s ease;

        &_login {
            color: $mocha;
            margin-right: rem-calc(8);
            top: rem-calc(2);
            @include fixWidth(rem-calc(16));
            @include fixHeight(rem-calc(16));
            &_myIntimissimi {
                margin-right: 0;
            }
        }

        &_services {
            margin: rem-calc(0 5);
        }

        &_logged {
            @include fixWidth(rem-calc(29));
            @include fixHeight(rem-calc(29));
            margin-right: rem-calc(2);
        }

        &_tabGender {
            margin-left: rem-calc(12);
            @include fixWidth(rem-calc(72));
            @include fixHeight(rem-calc(21));
        }

        &_logo {
            @include fixWidth(rem-calc(130));
            @include fixHeight(rem-calc(37));
            top: 0;
        }

        &_search {
            &-container {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(-100%, -50%);
            }
        }

        &_back {
            position: absolute;
            top: 50%;
            left: rem-calc(40);
            transform: rotate(180deg) translateY(50%);
            margin: 0;
            @include breakpoint(large up) {
                @include fixWidth(rem-calc(16));
                @include fixHeight(rem-calc(16));
            }
            @include breakpoint(medium down) {
                left: rem-calc(16);
            }
        }
    }

    &-item {
        flex: 1;
        display: inline-flex;
        align-items: center;

        &_logo {
            justify-content: center;
        }

        &_tabGender {
            display: none;
            @include breakpoint(large up) {
                display: flex;
                margin-left: rem-calc(25);
            }
            @include breakpoint(xlarge up) {
                margin-left: rem-calc(55);
            }
        }

        &_services {
            justify-content: flex-end;
        }
    }

    &-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 2;
        transition: z-index 0.4s ease;
        @include breakpoint(large up) {
            z-index: $zIndex-upperHeader;
        }

        .page[data-action='Search-Show']:not(.cc-inHomepage) & {
            position: relative;
        }

        @include breakpoint(medium down) {
            .page[data-action='Product-Show'] & {
                position: relative;
                .cc-mainHeader-sovraheader {
                    display: none;
                }
            }
        }

        .cc-menu-isOpen & {
            transition: none;
            z-index: $zIndex-menu;
        }
    }

    &-wishlist {
        display: flex;
        align-items: center;
        @include breakpoint(medium down) {
            margin-right: rem-calc(14);
        }
    }

    &-wishlist,
    &-minicart {
        position: relative;
        height: rem-calc(24);
        &_full {
            &::after {
                content: '';
                position: absolute;
                width: rem-calc(4);
                height: rem-calc(4);
                background: $failed;
                top: 0;
                right: 0;
                border-radius: 50%;

                .cc-intMenu-footer-item & {
                    content: none;
                }
            }

            &.cc-noContent {
                &::after {
                    content: none;
                }
            }
        }
    }

    &-minicart {
        margin-left: rem-calc(15);
        margin-top: rem-calc(1);
        font-size: 0;
        @include breakpoint(large up) {
            margin: 0;
        }
        &_full {
            &::after {
                right: rem-calc(4);
            }
        }
    }

    &-counter {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -51%);
        font-size: rem-calc(8);
        position: absolute;
        line-height: rem-calc(12);

        &_minicart {
            margin-top: rem-calc(2);
        }

        .cc-intMenu-footer-item & {
            display: none;
        }
    }

    &-search {
        //search button in header
        margin-left: rem-calc(20);
        color: $charcoal;
        @include breakpoint(large up) {
            @include flexGenerator(row, flex-start, center, 0 0 auto);
            column-gap: rem-calc(4);
            margin: rem-calc(0 40 0 0);
        }
    }

    &-menu {
        display: flex;
        align-items: center;
        gap: rem-calc(8);
        color: $charcoal;

        &-label {
            margin-left: rem-calc(8);
        }
    }

    &-login {
        position: relative;
        display: inline-flex;
        &-link {
            font-size: 0;
        }
        &:hover {
            @include breakpoint(large up) {
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: rem-calc(34);
                    height: rem-calc(60);
                    cursor: pointer;
                    top: rem-calc(20);
                }
            }
            .cc-mainHeader-login-onHover {
                visibility: visible;
                opacity: 1;
            }
        }

        &-onHover {
            position: fixed;
            background: $white;
            transition: all 0.3s ease;
            visibility: hidden;
            opacity: 0;
            z-index: 2;
            text-align: left;
            width: rem-calc(289);
            right: 0;
            top: rem-calc(82);
            list-style: none;
            margin: 0;
            border-bottom-left-radius: rem-calc(4);
            display: none;
            box-shadow: rem-calc(0 2 8 0) rgba($black, 0.1);

            // Popup hides for mobile and tablet, shows on hover only for desktop
            @include breakpoint(xlarge up) {
                display: block;
            }
        }

        &-item {
            padding: rem-calc(24);

            &_first {
                @include flexGenerator(row, space-between, center);

                .cc-manSite &:not(.cc-mainHeader-login-item_logged) {
                    background: $charcoal;
                    &,
                    a,
                    svg {
                        color: $white;
                    }
                }

                .cc-womanSite &:not(.cc-mainHeader-login-item_logged) {
                    background: $beige;
                    svg {
                        color: $charcoal;
                    }
                }
            }

            &:not(:last-child):not(:first-child) {
                border-bottom: rem-calc(1) solid $cloud;
            }
        }

        &-link {
            display: flex;
        }

        &-span {
            margin-top: rem-calc(3);
        }

        &-text {
            &_logout {
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                text-decoration: underline;
                color: $fossil;
                @include breakpoint(large up) {
                    font-size: rem-calc(10);
                    line-height: rem-calc(15);
                    color: $charcoal;
                }
            }

            &_loyalty {
                color: $fossil;
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                display: block;
                margin-top: rem-calc(4);
                @include breakpoint(large up) {
                    font-size: rem-calc(12);
                    line-height: rem-calc(18);
                }
                & .underline {
                    text-underline-offset: rem-calc(2);
                }
            }
        }

        &-label {
            display: none;
        }
    }

    &-services {
        @include flexGenerator(row, flex-end, center);
        margin-right: rem-calc(-5);
    }
}

.login-cta.for-modal {
    margin-bottom: rem-calc(16);
}
.password-reset {
    display: flex;
    justify-content: center;
    margin-bottom: rem-calc(22);
    border-bottom: 0 !important;
    text-decoration: underline !important;
}

.page--checkout {
    .cc-mainHeader {
        a {
            svg {
                width: rem-calc(10);
            }
        }
    }
    nav {
        padding: rem-calc(14 0);
    }
}

.wrapperModalLogin {
    .tingle-modal-box__content {
        padding: rem-calc(73 90 27);
        @include breakpoint(medium down) {
            padding: rem-calc(50 24 22);
            height: 100vh;
        }
        .modal-title-for-login {
            margin-bottom: rem-calc(34);
            @include breakpoint(medium down) {
                margin-bottom: rem-calc(32);
                padding: rem-calc(0 30);
            }
        }
        hr {
            width: 100%;
        }
        .close-button {
            top: rem-calc(16);
            right: rem-calc(16);
            text-align: right;
            @include breakpoint(medium down) {
                width: unset;
                height: unset;
                top: rem-calc(124);
            }
            span {
                font-size: rem-calc(34);
                color: black;
                line-height: rem-calc(20);
            }
        }
        form {
            margin-bottom: 0;
            .login-save-access {
                margin: 0;
            }
            .login-forgot-password-save-access {
                margin: rem-calc(4 0 44);
                @include breakpoint(medium down) {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: rem-calc(-2 0 37);
                    .checkbox-input {
                        padding-left: rem-calc(17);
                        &::before {
                            width: rem-calc(11);
                            height: rem-calc(11);
                        }
                    }
                    .checkbox-label {
                        line-height: rem-calc(16);
                    }
                    a {
                        flex: 0 0 auto;
                    }
                    label {
                        justify-content: flex-end;
                    }
                    label,
                    a {
                        font-size: rem-calc(12);
                        line-height: rem-calc(14);
                        @include breakpoint(medium down) {
                            font-size: rem-calc(9);
                            line-height: rem-calc(10);
                        }
                    }
                }
                position: relative;
            }
            .login-form-error:not(:empty) {
                @include breakpoint(medium down) {
                    padding: rem-calc(0 14);
                }
            }
            .social-login-form-error:not(:empty) {
                margin: rem-calc(-17 0 17);
                background: #ccd9f3;
                padding: rem-calc(14 14 11 17);
                font-size: rem-calc(10);
                line-height: rem-calc(19);
            }
            .login-form-error:not(:empty),
            .social-login-form-error:not(:empty) {
                @include breakpoint(large up) {
                    margin: rem-calc(16 0 16);
                }
            }
            .login-form-error {
                color: $apple;
            }
            .login-form-container {
                padding: rem-calc(0 95);
                @include breakpoint(medium down) {
                    padding: rem-calc(0 14);
                }
                &_input{
                    margin-bottom: rem-calc(24);
                    @include breakpoint(large up) {
                        margin-bottom: rem-calc(16);
                    }
                }
                &_button{
                    padding: 0;
                    height: auto;
                    margin-bottom: rem-calc(24);
                }
            }
        }
        .gigyaLoginCell {
            margin-bottom: rem-calc(30);
        }
        .login-popup-footer {
            margin-top: rem-calc(24);
            font-size: rem-calc(14);
            line-height: rem-calc(18);

            .new-customer-text {
                padding-right: rem-calc(9);
            }
        }
    }
}
.login-form-error:empty {
    margin: 0;
}
.gigyaLoginCell {
    margin: 0 auto;
    .login-register-gigya {
        font-size: rem-calc(14);
        line-height: rem-calc(18);
        margin-bottom: rem-calc(20);
        @include breakpoint(medium down) {
            font-size: rem-calc(12);
            line-height: rem-calc(20);
        }
    }
    .social-login-account-accept {
        margin-top: rem-calc(16);
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        letter-spacing: 0.04em;
        color: $fossil;
        .underline{
            text-decoration-color: $fossil;
        }
    }
}
.socialLoginContainer {
    margin: 0 auto;
    tr {
        background-color: white !important;
    }
    table[align='center'],
    td[align='center'] {
        width: 100%;
    }
    td[align='right'],
    td[align='left'] {
        display: none;
    }
}
.layer-password-modal {
    &.step-2 {
        .tingle-modal-box__content {
            text-align: center;
            padding: rem-calc(100 160);
            @include breakpoint(medium down) {
                padding: rem-calc(98 38);
            }
            .modal-title {
                margin-bottom: rem-calc(24);
                font-size: rem-calc(21);
                line-height: rem-calc(27);
            }
            .reset-password-form {
                padding: 0;
            }
            .request-password-body p {
                margin-bottom: rem-calc(35);
                @include breakpoint(medium down) {
                    margin-bottom: rem-calc(48);
                }
            }
            .ico-center-send-mail {
                margin-bottom: rem-calc(21);
            }
        }
    }
    .tingle-modal-box__content {
        padding: rem-calc(72 165 62);
        @include breakpoint(medium down) {
            padding: rem-calc(89 38 59);
        }
        .close {
            font-size: rem-calc(31);
            font-weight: 300;
            margin: 0;
            right: rem-calc(20);
            top: rem-calc(18);
        }
        .reset-password-form {
            .request-password-body input {
                margin-bottom: rem-calc(48);
            }
        }
        .modal-title {
            margin-bottom: rem-calc(37);
            text-align: center;
            @include breakpoint(medium down) {
                margin-bottom: rem-calc(31);
            }
        }
        .modal-description {
            margin-bottom: rem-calc(45);
            text-align: center;
            @include breakpoint(medium down) {
                margin-bottom: rem-calc(60);
            }
        }
    }
}

.cc-layer-login {
    z-index: $zIndex-layerAboveRightModal + 2;
    @include breakpoint(medium down) {
        .tingle-modal-box {
            width: 100%;
            position: absolute;
            bottom: 0;
            max-height: 100vh;
        }
    }
}

.layer-login-modal,
.login-form-container {
    .cc-remember-me-toggle {
        flex: 0 0 auto;
    }
    .cc-info-icon-container {
        display: flex;
        margin-top: rem-calc(5);
    }
    .cc-info-icon {
        cursor: pointer;
        &:hover + .tooltip {
            display: block !important;
        }
    }

    .cc-info-icon-gray {
        position: absolute;
        top: rem-calc(6);

        &:hover + .tooltip {
            display: block !important;
        }
    }

    .remember-me-container {
        position: relative;
        display: flex;
        gap: rem-calc(8);
        .popover-remember-me {
            width: rem-calc(370);
            color: $white;
            background: #89c493;
            position: absolute;
            transform: translateY(-100%);
            top: rem-calc(-15);
            padding: rem-calc(20 33 30);
            right: rem-calc(60);
            filter: drop-shadow(1px 2px 6px rgba($color: #000000, $alpha: 0.16));
            font-size: rem-calc(12);
            line-height: rem-calc(17);
            @include breakpoint(medium down) {
                left: 0;
                width: rem-calc(300);
            }
            &:after,
            &:before {
                bottom: rem-calc(-13.5);
                right: rem-calc(250);
                border: solid transparent;
                content: ' ';
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-left: rem-calc(8) solid transparent;
                border-right: rem-calc(8) solid transparent;
                border-top: rem-calc(13) solid #89c493;
                @include breakpoint(medium down) {
                    right: rem-calc(264) !important;
                }
            }
            .close-button-info-popover {
                position: absolute;
                right: rem-calc(20);
                cursor: pointer;
            }
            .tooltip-title {
                margin-bottom: rem-calc(10);
                font-size: rem-calc(18);
                line-height: rem-calc(17);
            }
            .tooltip-subtitle {
                font-size: rem-calc(12);
                line-height: rem-calc(17);
                margin-top: 0;
            }
            .cookie-policy-tooltip {
                font-size: rem-calc(12);
                text-decoration: underline;
                text-transform: uppercase;
                margin-left: rem-calc(3);
                border-bottom: none;
                color: white;
            }
        }
        .popover-remember-me.for-modal {
            @include breakpoint(medium down) {
                left: rem-calc(-15);
                width: rem-calc(300);
                z-index: 1000;
            }
            &:after,
            &:before {
                @media (min-width: 320px) and (max-width: 360px) {
                    right: rem-calc(194) !important;
                }
                @include breakpoint(medium down) {
                    right: rem-calc(250);
                }
            }
            @media (max-width: 320px) and (max-width: 360px) {
                width: rem-calc(230);
                right: rem-calc(-60);
            }
        }
        .info-icon {
            .tooltip {
                left: rem-calc(-80);
                top: rem-calc(-4);
                width: rem-calc(267);
                padding: rem-calc(25);
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
                @include breakpoint(medium down) {
                    left: rem-calc(-100);
                    top: rem-calc(-7);
                }
                .tooltip-title {
                    font-size: rem-calc(18);
                    line-height: rem-calc(17);
                }
                .tooltip-subtitle {
                    font-size: rem-calc(12);
                    line-height: rem-calc(17);
                    margin-top: 0;
                }
                .close-button-info-tooltip {
                    position: absolute;
                    right: rem-calc(25);
                    cursor: pointer;
                }
                .cookie-policy-tooltip {
                    font-size: rem-calc(12);
                    text-decoration: underline;
                    text-transform: uppercase;
                    margin-left: rem-calc(3);
                    border-bottom: none;
                    color: white;
                }
            }
            .tooltip.for-modal {
                left: rem-calc(-170);
                top: rem-calc(16);
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
                @include breakpoint(medium down) {
                    top: rem-calc(-5);
                    left: rem-calc(-115);
                    width: rem-calc(230);
                }
            }
            .icon-info-black {
                margin-top: rem-calc(7.5);
                width: rem-calc(10.32);
                height: rem-calc(10.32);
                line-height: rem-calc(8);
                margin-left: rem-calc(3);
            }
        }
        .checkbox-label {
            font-size: rem-calc(14);
            font-weight: 500;
            position: relative;
            top: rem-calc(1);
            padding-left: rem-calc(8);
            cursor: unset;
        }
    }
    /* The switch - the box around the slider */
    .switch {
        position: relative;
        display: inline-flex;
        width: rem-calc(40);
        height: rem-calc(23);
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        width: rem-calc(48);
        height: rem-calc(24);
        bottom: 0;
        background-color: $medium-more-gray;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: '';
        color: $cloud;
        font-size: rem-calc(20);
        text-align: center;
        line-height: rem-calc(17);
        height: rem-calc(18);
        width: rem-calc(18);
        left: rem-calc(5);
        bottom: rem-calc(3);
        background-color: $white;
        transition: 0.4s;
    }

    input:checked + .slider {
        background-color: $charcoal;
    }

    input:checked + .slider:before {
        transform: translateX(rem-calc(22));
        background-color: $white;
        content: '';
        left: rem-calc(2);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: rem-calc(34);
    }

    .slider.round:before {
        border-radius: 50%;
    }
}

.omnibus-tooltip.info-icon {
    left: rem-calc(3);
    top: 0;
    vertical-align: text-top;

    .icon-info-fossil {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: solid 1px $fossil;
        width: rem-calc(10);
        height: rem-calc(10);
        color: $fossil;
    }

    .tooltip {
        transform: translate(-50%, -110%);
        z-index: 20;
        @include breakpoint(medium down) {
            transform: translate(-25%, -110%);
        }
    }
}

.cc-mainHeader-container.has-refinements {
    z-index: 20;

    .cc-mainHeader {
        width: 100vw;

        &.z-top {
            z-index: 20;
        }
    
        &.z-bottom {
            z-index: 16;
        }
    }
}

.cc-plp-refineBar {
    &.z-top {
        z-index: 20;
    }

    &.z-bottom {
        z-index: 16;
    }

    &.z-0 {
        z-index: 0; 
     }
}
