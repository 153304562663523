.coupon-landing-title{
    font-size: rem-calc(20);
    font-weight: $global-weight-dark;
    margin: rem-calc(30 40 20);
    @include breakpoint (large up){
        color: $charcoal;
        text-align: center;
        font-family: $body-font-family;
        font-size: rem-calc(32);
        font-style: normal;
        font-weight: $global-weight-normal;
        line-height: 130%;
        margin: rem-calc(56 0 32);
    }
    @include breakpoint (small down){
        font-size: rem-calc(24);
        margin: rem-calc(40 0 32);
    }
}

.coupon-landing{
    width: 56.6868%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    @include breakpoint (small down){
        width: unset;
        margin: unset;
        margin-bottom: rem-calc(40);
    }
    .cell{
        &:nth-child(3){
            display: flex;
        }
    }
    .box-text-information{
        border: rem-calc(1) solid $border-gray;
        border-radius: rem-calc(5);
        padding: rem-calc(24);
        font-size: rem-calc(16);
        text-align: center;
        &.right{
            @include breakpoint (medium down){
                margin-top: rem-calc(40);
            }
        }
        @include breakpoint (large up){
            height: auto;
            padding: rem-calc(24);
            margin-right: rem-calc(20);
            &.right{
                margin-right: 0;
                margin-left: rem-calc(20);
                flex: 1;
            }
        }
        .box-title{
            color: $charcoal;
            text-align: center;
            font-family: $body-font-family;
            font-size: rem-calc(18);
            font-style: normal;
            font-weight: $global-weight-normal;
            line-height: 135%;
            margin-bottom: rem-calc(8);
            @include breakpoint (small down){
                font-size: rem-calc(14);
                line-height: 150%;
                letter-spacing: rem-calc(0.56);
            }
        }
        .text-center{
            color: $charcoal;
            text-align: center;
            font-family: $body-font-family;
            font-size: rem-calc(14);
            font-style: normal;
            font-weight: $global-weight-normal;
            line-height: 150%;
            letter-spacing: rem-calc(0.56);
            @include breakpoint (small down){
                font-size: rem-calc(12);
                letter-spacing: rem-calc(0.48);
            }
        }
    }

    .legal-note-box{
        margin: rem-calc(0);
        border-top: rem-calc(1) solid $border-gray;
        padding: rem-calc(40 0);
        margin-top: rem-calc(40);
        @include breakpoint (large up){
            padding: rem-calc(40 0);
            margin: rem-calc(40 0 0 0);
        }
        p{
            &:first-child{
                text-transform: lowercase;
                color: $charcoal;
                font-family: $body-font-family;
                font-size: rem-calc(18);
                font-style: normal;
                font-weight: $global-weight-normal;
                line-height: 135%;
                margin-bottom: rem-calc(8);
                &:first-letter {
                    text-transform: uppercase;
                  }
                @include breakpoint (small down){
                    font-size: rem-calc(14);
                    line-height: 150%;
                    letter-spacing: rem-calc(0.56);
                }
            }
            color: $charcoal;
            font-family: $body-font-family;
            font-size: rem-calc(14);
            font-style: normal;
            font-weight: $global-weight-normal;
            line-height: 150%;
            letter-spacing: rem-calc(0.56);
            &:last-child{
                margin-bottom: 0;
            }
            @include breakpoint (small down){
                font-size: rem-calc(12);
                line-height: 150%;
                letter-spacing: rem-calc(0.48);
            }
        }
        br{
            &:first-child{
                display: none;
            }
        }
    }
    .button.extended{
        height: rem-calc(44);
        border-radius: rem-calc(2);
        background: $charcoal;
        color: $white;
        font-family: $body-font-family;
        font-size: rem-calc(14);
        font-style: normal;
        font-weight: $global-weight-normal;
        line-height: 150%;
        letter-spacing: rem-calc(0.56);
        margin-bottom: 0;
        @include breakpoint (small down){
            margin-bottom: rem-calc(16);
        }
    }
    .button.button-secondary{
        border-radius: rem-calc(2);
        border: rem-calc(1) solid $cloud;
        background: $white;
        color: $charcoal;
        font-family: $body-font-family;
        font-size: rem-calc(14);
        font-style: normal;
        font-weight: $global-weight-normal;
        line-height: 150%;
        letter-spacing: rem-calc(0.56);
        margin-bottom: 0;
    }

    .unsubscribe-info-box{
        margin: rem-calc(40 0 0);
        border-top: rem-calc(1) solid $border-gray;
        font-size: rem-calc(14);
        @include breakpoint (large up){
            padding-top: rem-calc(40);
            margin: rem-calc(40 0 80);
            font-size: rem-calc(12);
            padding-bottom: rem-calc(32);
        }
        h5{
            text-transform: lowercase;
            color: $charcoal;
            font-family: $body-font-family;
            font-size: rem-calc(18);
            font-style: normal;
            font-weight: $global-weight-normal;
            line-height: 135%;
            margin-bottom: rem-calc(24);
            @include breakpoint (small down){
                font-size: rem-calc(14);
                line-height: 150%;
                letter-spacing: rem-calc(0.56);
                padding-top: rem-calc(40);
            }
        }
        h5:first-letter {
            text-transform: uppercase;
          }
        p{
            color: $charcoal;
            font-family: $body-font-family;
            font-size: rem-calc(14);
            font-style: normal;
            font-weight: $global-weight-normal;
            line-height: 150%;
            letter-spacing: rem-calc(0.56);
            margin-bottom: rem-calc(24);
            @include breakpoint (small down){
            font-size: rem-calc(12);
            }
            &:nth-child(5){
                display: inline-flex;
                margin-left: rem-calc(13);
                &::before {
                    content: '\2022'; /* Unicode for a bullet point • */
                    display: inline-block;
                    position: relative;
                    right: rem-calc(8);
                }
            }
            &:nth-child(6){
                display: inline-flex;
                margin-left: rem-calc(13);
                &::before {
                    content: '\2022'; /* Unicode for a bullet point • */
                    display: inline-block;
                    position: relative;
                    right: rem-calc(8);
                }
                span {
                    display: contents;
                }
            }
        }
        .dark.no-margin{
            margin-bottom: rem-calc(24) !important;
            @include breakpoint (small down){
                font-size: rem-calc(16);
            }
        }
        .grid-x a{
            height: rem-calc(44);
            border-radius: rem-calc(2);
            background: $charcoal;
            color: $white;
            font-family: $body-font-family;
            font-size: rem-calc(14);
            font-style: normal;
            font-weight: $global-weight-normal;
            line-height: 150%;
            letter-spacing: rem-calc(0.56);
        }
        .link-regulation{
            font-weight: $global-weight-normal;
            &:hover{
                text-decoration: none;
            }
        }
    }
    .mobile-padding-cell-info-coupon{
        .grid-x{
            justify-content: left;
        }
        @include breakpoint (medium down){
            padding: 0;
        }
    }
}
