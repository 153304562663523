.cc-promoStrip {
    background: $dark-blue;
    padding: 0;
    position: relative;

    &__slide__wrp {
        width: 100%;
        opacity: 0;
        transition: all 0.5s ease-in-out;
        display: flex;
        flex-direction: row;
        align-items: center;
        border: none;
        padding: rem-calc(11 48 11 16);

        @include breakpoint(large up) {
            padding: rem-calc(2 48);
        }
    }

    &__slider:not(.slick-initialized) {
        display: flex;
        flex-direction: row;

        > .cc-promoStrip__slide {
            min-width: 100%;
            width: 100%;

            .cc-promoStrip__slide__wrp {
                opacity: 1;
            }
        }
    }

    .slick-active,
    .slick-cloned {
        .cc-promoStrip__slide__wrp {
            opacity: 1;
        }
    }

    .slick-slider {
        transform: rotate(180deg);

        .slick-slide {
            transform: rotate(-180deg);
            border: none;
        }
    }

    &__text {
        display: block;
        width: 100%;
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        color: $white;
        letter-spacing: 0.04em;
        text-align: left;
        margin: 0;

        @include breakpoint(large up) {
            text-align: center;
        }

        a {
            display: inline;
            text-decoration: underline;
            color: inherit;
        }
    }

    &__close {
        display: block;
        margin: 0;
        padding: 0;
        border: none;
        background: transparent;
        position: absolute;
        z-index: 1;
        top: 50%;
        margin-top: rem-calc(-8);
        right: rem-calc(12);
        color: $white;
        font-size: 0;

        svg {
            display: block;
            width: rem-calc(16);
            height: rem-calc(16);
        }
    }
}

.cc-womanSite {
    .cc-promoStrip {
        background: $mocha;
    }
}
