.privacyFlagEnable {
    background-color: rgba($color: $black, $alpha: .4);

    .tingle-modal-box {
        box-shadow: none;
        border-radius: rem-calc(4);

        @include breakpoint(medium down) {
            margin-bottom: 0;
            max-height: 75%;
            max-width: 100%;
            overflow-y: auto;
            width: 100%;
        }

        @include breakpoint(large up) {
            width: rem-calc(500);
        }

        .tingle-modal-box__content {
            padding: rem-calc(32);
            @include breakpoint(medium down) {
                overflow-x: hidden;
                padding: rem-calc(32 16);
            }

            .cc-borderFrame {
                @include breakpoint(medium down) {
                    height: rem-calc(2);
                    width: rem-calc(100);
                    border-radius: rem-calc(5);
                    position: fixed;
                    top: rem-calc(16);
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: $cloud;
                }
            }

            .cc-closePrivacyPopUp {
                @include increaseButtonSize();
                position: absolute;
                top: rem-calc(16);
                right: rem-calc(16);
                line-height: 0;

                &-icon {
                    width: rem-calc(16);
                    height: rem-calc(16);
                    @include breakpoint(large up) {
                        width: rem-calc(24);
                        height: rem-calc(24);
                    }
                }
            }

            .title {
                font-size: rem-calc(24);
                font-style: normal;
                font-weight: $global-weight-normal;
                line-height: 130%;
                color: $charcoal;
                max-width: 100%;

                @include breakpoint(large up) {
                    max-width: rem-calc(350);
                }
            }

            .cc-privacyFlagPopupTextFirstStep {
                color: $charcoal;
                font-size: rem-calc(14);
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                letter-spacing: rem-calc(0.56);
                margin-bottom: rem-calc(20);
                @include breakpoint(medium down) {
                    margin-bottom: rem-calc(20);
                    padding-right: rem-calc(23) !important;
                }
            }

            .cc-privacyFlagPopupText {
                color: $charcoal;
                font-size: rem-calc(14);
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                letter-spacing: rem-calc(0.56);
                margin-bottom: rem-calc(24);
                @include breakpoint(medium down) {
                    margin-bottom: rem-calc(24);
                    padding-right: rem-calc(15) !important;
                }
            }

            .boolean-field {
                input[type='checkbox'] {
                    height: rem-calc(20);
                    width: rem-calc(50);
                    z-index: 1;
                    left: rem-calc(35);

                    @include breakpoint(large up) {
                        left: unset;
                        right: 0;
                    }
                }
            }

            .sliderToggle {
                @include breakpoint(large up) {
                    left: unset;
                }

                @include breakpoint(medium down) {
                    width: rem-calc(48);
                    height: rem-calc(24);
                    left: rem-calc(38);
                    top: rem-calc(2);
                }
                @supports (-webkit-touch-callout: none) {
                    /* CSS specific to iOS devices */
                    top: rem-calc(-8);
                }
            }
        }

        a.disabled:not(.button),
        .disabled > a:not(.button) {
            color: $charcoal;
            cursor: not-allowed;
        }

        .cc-editPrivacyPolicyForm {
            margin-top: rem-calc(16);

            .cc-newsletterPref {
                margin-top: rem-calc(24);
                margin-left: rem-calc(-42);
                @include breakpoint(medium down) {
                    margin-top: rem-calc(30);
                }
            }

            .cc-profilingPref {
                margin-top: rem-calc(24);
                margin-left: rem-calc(-42);
                @include breakpoint(medium down) {
                    margin-top: rem-calc(15);
                    margin-bottom: rem-calc(2);
                }

                .cc-preferencesText {
                    color: $charcoal;
                    font-size: rem-calc(14);
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                    letter-spacing: rem-calc(0.56);
                    a {
                        color: $charcoal;
                    }
                    @include breakpoint(large up) {
                        margin-bottom: 0;
                    }
                }
            }

            .cc-preferencesText {
                margin-left: rem-calc(-9);
                @include breakpoint(medium down) {
                    margin-bottom: 0;
                    margin-left: rem-calc(17);
                }

                &.disabled {
                    color: $pebble;
                }

                .privacy-policy-short-profiling,
                .privacy-policy-short-marketing {
                    color: $charcoal;
                    font-weight: 400;
                    text-decoration: none;
                }
            }
        }

        .cc-privacyResponseOK {
            .cc-privacyFlagPopupText {
                color: $charcoal;
                font-size: rem-calc(24);
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
                @include breakpoint(medium down) {
                    margin-bottom: rem-calc(20);
                }
            }
            .cc-successParagraph {
                color: $success;
                text-align: center;
                font-size: rem-calc(12);
                font-style: normal;
                font-weight: $global-weight-normal;
                line-height: 150%;
                letter-spacing: rem-calc(0.48);
                margin-bottom: 0;
                @include breakpoint(medium down) {
                    width: 80%;
                }
                i {
                    margin-right: rem-calc(12);
                }
            }
        }
        .button {
            &.cc-activableAfterChange {
                @include flexGenerator(row, center, center);
                width: 100%;
                height: rem-calc(44);
                border-radius: rem-calc(2);
                text-transform: lowercase;
                &::first-letter {
                    text-transform: uppercase;
                }
                @include breakpoint(medium down) {
                    width: 100%;
                }
            }
        }
    }
    .cc-checkIconPopup {
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
    .cc-checkConfirmationIcon {
        width: rem-calc(14);
        height: rem-calc(14);
        content: url('../../images/int-check-confirmation.svg');
    }
    .cc-thankYouMessage {
        @include flexGenerator(row, center, center);
        width: 100%;
        border-radius: rem-calc(4);
        border: rem-calc(1) solid $success;
        padding: rem-calc(4 16);
        .cc-successMessage {
            @include flexGenerator(row, center, center);
            flex-wrap: wrap;
        }
        .cc-checkIconPopupFrame {
            width: rem-calc(14);
            height: rem-calc(14);
        }
    }
    .cc-privacyPopUpTitle {
        margin-bottom: rem-calc(24);
        @include breakpoint(medium down) {
            margin-bottom: rem-calc(20);
        }
        &-Modify {
            &.cc-privacyFlagPopupTextFirstStep {
                margin-bottom: rem-calc(30);
            }
            @include breakpoint(medium down) {
                margin-bottom: rem-calc(24);
            }
        }
    }

    .cc-buttons-modifyConsent {
        font-size: rem-calc(14);
        font-weight: 400;
        line-height: rem-calc(20);
        letter-spacing: rem-calc(0.35);
        margin-bottom: 0;
        margin-top: rem-calc(32);
        button {
            border-radius: rem-calc(2);
            background: $charcoal;
            height: rem-calc(44);
            width: 100%;
        }
        @include breakpoint(medium down) {
            button {
                width: 100%;
            }
        }
    }
    .cc-continueConsent {
        text-transform: lowercase;
        width: 100%;
        height: rem-calc(44);
        background: $charcoal;
        margin-top: rem-calc(32);
        &::first-letter {
            text-transform: uppercase;
        }
        @include breakpoint(medium down) {
            width: 100%;
        }
    }

    .button:disabled {
        background-color: $pebble;
        color: $white;
        width: 100%;
    }

    .cc-profilingPreferencesSwitches {
        display: flex;
        justify-content: flex-end;
    }

    .cc-spacerPopupIntimissimi {
        height: rem-calc(32);
    }
}
