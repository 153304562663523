@import "chosen-js/chosen";
/* FIX FOCUS VISIBLE ON CHOSEN */
.chosen-single {
    .chosen-container-single & {
        background-color: $white;
        border: 1px solid $cloud;
        border-radius: rem-calc(2);
        padding: rem-calc(16 24);
        height: unset;
        transition: all 0.25s ease-in-out;
        font-size: rem-calc(16);
        line-height: rem-calc(24);
        color: $charcoal;
        font-weight: 400;
        background: unset;
        box-shadow: unset;

        &:not(.no-hover):hover {
            text-decoration: none;
        }
    }

    .chosen-container-active.chosen-with-drop & {
        box-shadow: unset;
        border-color: $charcoal;
        background-image: unset;
        border-bottom-right-radius: rem-calc(4);
        border-bottom-left-radius: rem-calc(4);
    }

    .chosen-container-active:not(.chosen-with-drop) &{
        border: 1px solid $charcoal;
    }

    .chosen-disabled & {
        cursor: $input-cursor-disabled;
        background-color: $cloud;
        color: $pebble;
    }
}

.chosen-disabled {
    opacity: 1 !important;
}

.chosen-container {
    width: 100% !important;
    margin-bottom: rem-calc(8);

    .chosen-drop {
        border: 0;
        border-radius: rem-calc(4);
        top: calc(100% + #{rem-calc(8)});
        box-shadow: rem-calc(0 2 8) rgba($black, 0.1);
        padding: rem-calc(16 16 16 0);
    }

    .chosen-results {
        padding: 0;
        margin: 0;
        max-height: rem-calc(200);

        &::-webkit-scrollbar {
            width: rem-calc(2);
        }

        &::-webkit-scrollbar-thumb {
            background-color: $fossil;
            border-radius: rem-calc(3);
        }

        &::-webkit-scrollbar-track {
            background-color: $cloud;
            border-radius: rem-calc(3);
        }

        li {
            padding: rem-calc(8 16);
            font-size: rem-calc(12);
            line-height: rem-calc(18);

            &.highlighted {
                color: $charcoal;
                background: $off-white;
            }
        }
    }
}


// Chosen images override
.chosen-rtl .chosen-search input[type="text"],
.chosen-container-single .chosen-single abbr,
.chosen-container-single .chosen-single div b,
.chosen-container-single .chosen-search input[type="text"],
.chosen-container-multi .chosen-choices .search-choice .search-choice-close,
.chosen-container .chosen-results-scroll-down span,
.chosen-container .chosen-results-scroll-up span {
    background-image: url("../images/chevron-down.svg") !important;
    background-size: 15px 15px !important;
    background-repeat: no-repeat !important;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
    .chosen-rtl .chosen-search input[type="text"],
    .chosen-container-single .chosen-single abbr,
    .chosen-container-single .chosen-single div b,
    .chosen-container-single .chosen-search input[type="text"],
    .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
    .chosen-container .chosen-results-scroll-down span,
    .chosen-container .chosen-results-scroll-up span {
      background-image: url("../images/chevron-down.svg") !important;
      background-size: 15px 15px !important;
      background-repeat: no-repeat !important;
    }
}

.chosen-container-single .chosen-single div b {
    background-position: center;
    position: relative;
    right: rem-calc(24);
}

.chosen-container-active.chosen-with-drop .chosen-single div b {
    background-position: center;
    transform: rotate(180deg);
}

select {
    background-image: url('../images/chevron-down.svg');
    background-size: rem-calc(18) auto;
    background-position: right;
    letter-spacing: 0.64px;

    &:focus {
        border: 1px solid $charcoal;
    }
}