.grid-container {
    padding-left: calc(#{map-get($grid-margin-gutters, large)} * 2);
    padding-right: calc(#{map-get($grid-margin-gutters, large)} * 2);

    @include breakpoint (medium down) {
        padding-left: calc(#{map-get($grid-margin-gutters, small)} * 2);
        padding-right: calc(#{map-get($grid-margin-gutters, small)} * 2);
    }

    &--narrow {
        @include breakpoint (medium up) {
            padding-left: calc(#{map-get($grid-margin-gutters, small)} * 2);
            padding-right: calc(#{map-get($grid-margin-gutters, small)} * 2);
        }
    }
}