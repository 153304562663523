

	.bras{
		width: 100%;
		text-align: center;
		margin-top: rem-calc(40);
		padding: rem-calc(0 16);

		@include breakpoint (large up) {
			padding: rem-calc(0)
		}
	}
	
	.bras-title{
		font-family: $body-font-family-cursive;
		text-align: center;
        font-size: rem-calc(32); 
        line-height: 130%;
		margin-bottom: rem-calc(8);
		width: 100%;
    }
    .wrapper-bg-bras-title{
    background-color: $bg-bra-gray;
}
	.bras-subtitle{
		text-align: center;
        font-size: rem-calc(14); 
        line-height: 150%;
		letter-spacing: rem-calc(0.56);
		width: 100%;
	}
	
	.bras .wrapper-bg-bras-title .bras-table{
		padding: rem-calc(40 16);
		@include breakpoint (large up) {
			padding: rem-calc(80 16);
		}
	}
	
	.bras .bras-table .filters{
        margin: rem-calc(32 0 40 0);
		overflow-x: auto;   
        @include breakpoint (large up) {     
			white-space: normal;
			overflow: initial;
}
	}
	
	.bras .bras-table #still{
		text-align: left;
		display: inline-table;
		margin: auto;
	}
	
	.bras .bras-table .filters .btn{
		border: rem-calc(1) solid #cccccc;
		text-align: center;
        font-size: rem-calc(13);
        line-height:  rem-calc(19);
		padding: rem-calc(8 20);
		margin:rem-calc(10 7);
		opacity: 1;
        background-color: transparent;
        @include breakpoint (small down) { 
        margin:rem-calc(7 5);
        }
	}

	
	.bras .bras-table .filters .active-filter{
		background-color: $medium-gray;
		color: $black;
	}
	
	.bras .bras-table .filters .active-filter:after{
		content: "x";
		display: inline-block;
		color: $black;
		font-size: rem-calc(20);
        margin-left: rem-calc(8);
        line-height: rem-calc(10);
	}
	
	.bras .bras-table .no-bras{
	  opacity: 0.2!important;
	}
	
	.bras .bras-table .bras-box{
		text-align: center;
		width: 14.28%;
		display: inline-block;
		float: left;
		margin-bottom: rem-calc(16);
	}
	
	.bras .bras-table .bras-box p{
		text-transform: capitalize;
		font-size: rem-calc(12);
		line-height: 150%;
		letter-spacing: rem-calc(0.48);
		width: 100%;
		text-align: center;
        margin: 0 auto 0;
	}
	
	.bras .bras-table .bras-box.active-box{
		opacity:1;
	}
	
	.bras .bras-table .bras-box a.disabled{
		cursor: auto;
	}
    
    @include breakpoint (large down) {     
		.bras .bras-table{
			width: 100%;
        }
        .bras .bras-table .bras-box{
			width: 33%;
			transition: 0.2s linear;		
		}
		
	}
	
