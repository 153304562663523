/*
.content-asset class dynamically generated by salesforce for content assets
*/
.content-asset {
    &.table-root {
        overflow: hidden;
    }

    h1.title {
        padding-top: 0;
        padding-bottom: 0;
        font-weight: $global-weight-bold;
        margin: 0;
        text-align: left !important;
        width: 100%;
        height: rem-calc(56);
        border-bottom: rem-calc(1) solid $medium-gray;
        background-size: rem-calc(20);
        background-position: rem-calc(0 8);
        background-repeat: no-repeat;
        margin-bottom: 0;

        @include breakpoint (small down) {
            height: rem-calc(56);

        }
    }

    .wrapper-gray-fullwidth {
        width: 100%;
        background-color: $more-light-gray;
        padding: rem-calc(40);

        @include breakpoint (small down) {
            padding: rem-calc(20);
        }
    }

    .boxGrayMoreInformation {
        width: rem-calc(979);
        margin: 0 auto rem-calc(80) auto;
        border-radius: rem-calc(4);
        background: $off-white;
        text-align: center;
        padding: rem-calc(24);
        @include breakpoint (small down){
            width: unset;
            margin-bottom: 0;
        }
        h2,
        p{
            color:$charcoal;
            font-family: $body-font-family;
            font-weight: $global-weight-normal;
            font-style: normal;
            text-align: center;

        }
        h2{
            font-size: rem-calc(16);
            line-height: 150%;
            letter-spacing: rem-calc(0.64);
            margin-bottom: rem-calc(16);
        }
        p {
            font-size: rem-calc(14);
            line-height: 150%;
            letter-spacing: rem-calc(0.56);
            margin: 0 auto;
            padding-bottom: rem-calc(16);
        }
        .force-hover {
            text-transform: lowercase;
          }
          .force-hover::first-letter {
            text-transform: uppercase;
          }
    }

    .careOfTheLeaders {
        width: 100vw;
        background: $porcelain;
        left: rem-calc(-16);
        text-align: center;
        padding: rem-calc(48 16);
        position: relative;
        @include breakpoint (large up){
            left: rem-calc(-32);
            padding: rem-calc(48);
        }

        h2 {
            font-family: $body-font-family-cursive;
            font-size: rem-calc(24);
            line-height: 130%;
            margin-bottom: rem-calc(16);
        }

        p {
            font-size: rem-calc(12);
            line-height: 150%;
            letter-spacing: rem-calc(0.48);
            margin-bottom: rem-calc(16);
        }

        a {
            font-size: rem-calc(12);
            line-height: 150%;
            letter-spacing: rem-calc(0.48);
            text-decoration-line: underline;
        }
    }

    .boxGrayMoreInformationPdp {
        background-color: $more-light-gray;
        padding: rem-calc(7 37);
        text-align: center;
        margin-bottom: rem-calc(20);

        p {
            margin: 0 auto;
            padding-bottom: 0;
        }

        a {
            font-weight: $global-weight-normal;
        }
    }


    .component-find-your-size {
        text-align: center;
        padding: 0;
        .align-middle{
            margin: rem-calc(0 16);
            @include breakpoint (small down){
                margin: 0;
            }
        }
        .title {
            display: none;
        }
        .wrapper-box {
            padding: rem-calc(32);
            margin-right: rem-calc(16);
            height: 50.375vh;
            border-radius: rem-calc(4);
            background: $off-white;
            @include breakpoint (small down){
                padding: rem-calc(32 32 32 16);
                margin-right: 0;
                height: unset;
            }

            h1,
            p{
                color: $charcoal;
                font-family: $body-font-family-cursive;
                font-weight: $global-weight-normal;
                font-style: normal;
                text-align: left;
            }

            h1 {
                width: 74%;
                margin-bottom: rem-calc(12);
                font-size: rem-calc(38);
                line-height: 125%;
                @include breakpoint (small down){
                    width: 100%;
                    font-size: rem-calc(24);
                    line-height: 130%;
                }
            }
            p {
                width: 50%;
                font-size: rem-calc(14);
                line-height: 150%;
                letter-spacing: rem-calc(0.56);
                padding-bottom: rem-calc(30);
                margin: 0;
                font-family: $body-font-family;
                @include breakpoint (small down){
                    width: 100%;
                    font-size: rem-calc(14);
                    padding-bottom: 0;
                }
            }
        }

        .subtitle,
        .p26{
            color: $charcoal;
            font-family: $body-font-family-cursive;
            font-style: normal;
            font-weight: $global-weight-normal;
            text-align: left;
        }
        .subtitle {
            padding-top: rem-calc(80);
            font-size: rem-calc(24);
            line-height: 150%;
            @include breakpoint (small down) {
                padding-top: rem-calc(32);
                margin-bottom: rem-calc(16);
            }
        }
        .large-offset-1{
            width: 100%;
            margin-left: 0;
            @include breakpoint (small down){
                padding: rem-calc(0 16);
            }
        }
        .grid-margin-x{
            width: rem-calc(974);
            display: inline-flex;
            justify-content: center;
            @include breakpoint (small down){
                width: 100%;
            }
            .cell{
                &:first-child{
                    margin-left: 0;
                }
                &:last-child{
                    margin-right: 0;
                    @include breakpoint (small down){
                        margin-left: 0;
                    }
                }
            }
            img{
                width: rem-calc(479);
                height: rem-calc(397);
                border-radius: rem-calc(4);
                @include breakpoint (small down){
                    width: unset;
                    height: unset;
                }
            }
        }

        .p26 {
            margin-top: rem-calc(16);
            width: 100%;
            font-size: rem-calc(12);
            line-height: 150%;
            letter-spacing: rem-calc(0.48);
            font-family: $body-font-family;
            @include breakpoint (small down) {
                padding: 0;
                margin-bottom: 0;
            }
        }
    }
}

.table-scroll {
    overflow-x: hidden;

    @include breakpoint (small down) {
        padding-bottom: rem-calc(24);
        overflow-x: auto;
    }

    &::-webkit-scrollbar {
        height: rem-calc(5);
    }

    &::-webkit-scrollbar-thumb {
        background-color: $charcoal;
        border-radius: rem-calc(5/2);
    }

    .table-find-your-size,
    .table-find-your-size-table {
        border-spacing: 0;
        min-width: rem-calc(380);
        width: 100%;
        text-align: center;

        tr {
            height: rem-calc(40);

            th,
            td {
                width: calc(100% / 7);
                text-align: center;
                border-bottom: rem-calc(1) solid $border-table-gray;

                &.size-legend {
                    background-color: $off-white;
                }

                &.size-active {
                    background-color: $charcoal;
                    color: $white;
                }

                &.size-same {
                    background-color: $cloud;
                }
            }

            th {
                line-height: rem-calc(22);
            }

            td {
                line-height: rem-calc(38);
            }
        }
    }

    .table-find-your-size{
        @include breakpoint (small down){
            &::-webkit-scrollbar {
                height: rem-calc(4);
              }
            &::-webkit-scrollbar-track {
                background-color: $cloud;
                border-radius: rem-calc(8);
              }
            &::-webkit-scrollbar-thumb {
                background-color: $charcoal;
                border-radius: rem-calc(6);
              }
        }
    }

    .table-find-your-size-table {
        margin-top: rem-calc(20);
        margin-bottom: rem-calc(30);
        text-align: center;

        tr {

            th,
            td {
                width: auto;





            }

            th {
                font-size: rem-calc(11);

                @include breakpoint (small down) {
                    font-size: rem-calc(10);
                }
            }
        }


    }

}

.marT41 {
    margin-top: rem-calc(41);

}


.todays-promo-right-slider-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    .todays-promo-left-title {
        position: absolute;
        top: rem-calc(30);
        margin-left: 20%;
    }
    .todays-promo-bottom-title {
        position: absolute;
        top: rem-calc(100);
        margin-left: 40%;
    }
    .todays-promo-left-subtitle {
        position: absolute;
        top: rem-calc(40);
        margin-left: 4%;
    }
}


.todays-promo-left-slider-title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    .todays-promo-left-title {
        position: absolute;
        top: rem-calc(30);
        margin-left: 20%;
    }
    .todays-promo-bottom-title {
        position: absolute;
        top: rem-calc(100);
        margin-left: 40%;
    }
    .todays-promo-left-subtitle {
        position: absolute;
        top: rem-calc(40);
        margin-left: 4%;
    }
}

.page {
    &[data-querystring="cgid=INT_Woman_Todays-promo"] {
        @include breakpoint(large up) {
            padding-top: rem-calc(120);
        }
    }
}

.page {
    &[data-querystring="cgid=INT_Man_Today's-promo"] {
        @include breakpoint(large up) {
            padding-top: rem-calc(120);
        }
    }
}


.slider-todays-promo-absolute-title{

    .wrapper-slider-image-title-absolute{
        position: relative;
        @include breakpoint (large up){
            width: calc(50% - 19px);
        }

        .wrapper-title-box-shadow {
            position: absolute;
            top: 0;
            left: 0;
            text-align: left;
            right: 0;
            height: rem-calc(113);
            z-index: 2;;
            background: -moz-linear-gradient(91deg, rgba(2, 2, 3, 0) 19%, rgba(0, 0, 0, 0.5) 100%);
            /* ff3.6+ */
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.5)), color-stop(81%, rgba(2, 2, 3, 0)));
            /* safari4+,chrome */
            background: -webkit-linear-gradient(91deg, rgba(2, 2, 3, 0) 19%, rgba(0, 0, 0, 0.5) 100%);
            /* safari5.1+,chrome10+ */
            background: -o-linear-gradient(91deg, rgba(2, 2, 3, 0) 19%, rgba(0, 0, 0, 0.5) 100%);
            /* opera 11.10+ */
            background: -ms-linear-gradient(91deg, rgba(2, 2, 3, 0) 19%, rgba(0, 0, 0, 0.5) 100%);
            /* ie10+ */
            background: linear-gradient(359deg, rgba(2, 2, 3, 0) 19%, rgba(0, 0, 0, 0.5) 100%);
            /* w3c */
            &.no-box-shadow {
                background: none;
            }
            h1 {
                text-align: left;
                position: absolute;
                @include breakpoint (small down) {
                    top: rem-calc(19);
                    font-size: rem-calc(18);
                    letter-spacing: rem-calc(0.38);
                }
                @include breakpoint (medium only) {
                    top: rem-calc(20);
                    left: rem-calc(20);
                }
                @include breakpoint (large up) {
                    top: rem-calc(39);
                    left: rem-calc(30);
                    font-size: rem-calc(16);
                    letter-spacing: rem-calc(0.34);
                }
                @include breakpoint (small up) {
                    font-weight: $global-weight-dark
                }
            }
        }
    }

    .slider-todays-promo {
        h4 {
            margin-top: rem-calc(50);
            font-weight: $global-weight-dark;
            line-height: rem-calc(21);
            @include breakpoint (small down) {
                font-size: rem-calc(14);
            }
            &.title-slide-grey {
                font-size: rem-calc(16);
                line-height: rem-calc(24);
                color: $medium-light-gray;
                @include breakpoint (small down) {
                    margin-top: rem-calc(29);
                }
            }
        }

        .todays-promo-grid-style{
            @include breakpoint (medium down){
                padding: rem-calc(0 10);
            }
        }

        .wrapper-slider-image-title {
            position: relative;
            .wrapper-title-box-shadow {
                position: absolute;
                top: 0;
                left: 0;
                text-align: left;
                right: 0;
                height: rem-calc(113);
                z-index: 2;
                background: -moz-linear-gradient(91deg, rgba(2, 2, 3, 0) 19%, rgba(0, 0, 0, 0.5) 100%);
                /* ff3.6+ */
                background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.5)), color-stop(81%, rgba(2, 2, 3, 0)));
                /* safari4+,chrome */
                background: -webkit-linear-gradient(91deg, rgba(2, 2, 3, 0) 19%, rgba(0, 0, 0, 0.5) 100%);
                /* safari5.1+,chrome10+ */
                background: -o-linear-gradient(91deg, rgba(2, 2, 3, 0) 19%, rgba(0, 0, 0, 0.5) 100%);
                /* opera 11.10+ */
                background: -ms-linear-gradient(91deg, rgba(2, 2, 3, 0) 19%, rgba(0, 0, 0, 0.5) 100%);
                /* ie10+ */
                background: linear-gradient(359deg, rgba(2, 2, 3, 0) 19%, rgba(0, 0, 0, 0.5) 100%);
                /* w3c */
                &.no-box-shadow {
                    background: none;
                }
                h1 {
                    top: rem-calc(23);
                    left: rem-calc(29);
                    text-align: left;
                    position: absolute;
                }
            }

            .wrapper-title-bottom {
                position: absolute;
                bottom: rem-calc(0);
                left: 0;
                right: 0;
                h4 {
                    text-align: center;
                    margin: 0;
                    padding-bottom: rem-calc(70);
                    font-size: rem-calc(20);
                    @include breakpoint (small down) {
                        font-size: rem-calc(18);
                        line-height: rem-calc(20);
                        padding-bottom: rem-calc(20);
                    }
                }
                h2 {
                    padding-bottom: rem-calc(20);
                    text-align: center;
                    margin: 0;
                    font-size: rem-calc(30);
                    @include breakpoint (small down) {
                        font-size: rem-calc(22);
                        line-height: rem-calc(25);
                        padding-bottom: rem-calc(10);
                    }
                }
                .read-more {
                    @include breakpoint (small down) {
                        text-align: center;
                        margin: rem-calc(20 0 30 0);
                    }
                    .button-link-anchor {
                        img {
                            margin: 0 auto;
                        }
                    }
                }
            }
            img {
                &.position-img-promo {
                    @include breakpoint (small down) {
                        height: 95vh;
                        object-fit: cover;
                    }
                }
            }
        }

        .wrapper-slider-text-right {
            margin-right: 11%;
            @include breakpoint (small down) {
                margin-right: rem-calc(18);
                margin-left: rem-calc(18);
            }

            p {
                font-size: rem-calc(14);
                line-height: rem-calc(19);

                &.special-size {
                    font-size: rem-calc(16);
                    line-height: rem-calc(21);
                    font-weight: $global-weight-dark;
                }

                &.final-claim {
                    font-size: rem-calc(14);
                    line-height: rem-calc(24);
                    font-weight: $global-weight-dark;
                    margin-bottom: rem-calc(40);
                }

                &.no-margin {
                    margin-bottom: 0;
                }

                &.center-m {
                    @include breakpoint (small down) {
                        text-align: center;
                    }
                }

            }

            a {
                font-size: rem-calc(12);
                line-height: rem-calc(17);
                font-weight: $global-weight-normal;

            }

            ul {
                list-style-position: inside;
                margin-left: 0;
            }
        }


        .slick-next,
        .slick-prev {
            top: 50vh;
            right: rem-calc(25);
            background-color: $light-gray;
            height: rem-calc(60);
            width: rem-calc(40);
            &:before {
                color: $medium-dark-gray;
            }
            z-index: 2;
        }

        .slick-prev {
            left: rem-calc(0);
        }

        .slick-next {
            right: rem-calc(0);
        }

        ul{
            &.slick-dots{
                z-index: 3;
            }
        }

        /* PRODUCT CATEGORY */
        .wrapper-slider-text-right-product {
            margin-right: 11%;

            @include breakpoint (small down) {
                margin-right: 0;
            }
        }

        .product {
            margin-bottom: 9%;

            .image-container {
                .js-quickview-link {
                    float: left;
                }
            }

            .tile-body {
                text-align: left;

                .pdp-link {
                    line-height: rem-calc(18);
                    min-height: rem-calc(38);
                    font-size: rem-calc(14);
                    font-weight: $global-weight-normal;

                    @include breakpoint (small down) {
                        line-height: rem-calc(15);
                        font-size: rem-calc(13);
                        min-height: rem-calc(30);
                    }
                }

                .price {
                    line-height: rem-calc(20);
                    font-size: rem-calc(14);

                    @include breakpoint (small down) {
                        line-height: rem-calc(19);
                        font-size: rem-calc(13);
                        margin-top: rem-calc(4);
                    }
                }
            }
        }

        .js-todays-promo-button-copy {
            background-color: $button-promo-copy;
            color: $black;
        }
        .js-todays-promo-button-copy, .js-todays-promo-button-products {
            @include breakpoint (small down) {
                margin-bottom: 0;
                margin-right: 0;
                height: rem-calc(45);
                padding-top: rem-calc(13);
                padding-bottom: rem-calc(13);
                font-size: rem-calc(13);
                line-height: 1.08;
                letter-spacing: rem-calc(0.01);
            }
        }

        &.old-todays-promo{
            .product{
                width: 50%;
                float: left;
                .image-container {
                    width: 88%;
                    margin: 0 auto;
                    text-align: center;
                }
                .tile-body {
                    width: 88%;
                    margin: 0 auto;
                }
            }
        }

        .titleTodayPromo {
            text-align: center;
            margin-bottom: rem-calc(38);
            margin-top: rem-calc(32);
        }
    }
}


a.today-promo-stycky-button {
    min-width: rem-calc(70);
    padding: rem-calc(0 16);
    text-align: center;
    height: rem-calc(35);
    line-height: rem-calc(35);
    left: 0;
    top: 50vh;
    z-index: 1;
    position: fixed;
    transform: rotate(270deg);
    transform-origin: left top;

}

.modalFindYourSize {
    .modalContent .content-asset {
        .grid-container {
            padding: 0;
        }

        h4 {
            display: block;
            font-size: rem-calc(16);
            line-height: rem-calc(24);
            letter-spacing: 0.04em;
            margin-bottom: rem-calc(24);
            margin-top: 0;
        }

        h5 {
            display: block;
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            letter-spacing: 0.04em;
            margin-bottom: rem-calc(4);
        }

        .table-scroll {
            overflow-x: auto;
            padding-bottom: rem-calc(24);

            &::-webkit-scrollbar {
                width: rem-calc(3);
                height: rem-calc(3);
            }
            &::-webkit-scrollbar-thumb {
                background: $charcoal;
            }
            &::-webkit-scrollbar-track {
                background: $cloud;
            }
            & {
                scrollbar-face-color: $charcoal;
                scrollbar-track-color: $cloud;
            }
            scroll-behavior: smooth;
            -webkit-overflow-scrolling: touch;
        }

        .table-find-your-size-table {
            margin-bottom: 0;
            font-size: rem-calc(12);
            letter-spacing: 0.04em;

            tr {
                height: rem-calc(50);

                th {
                    font-weight: $global-weight-normal;
                    font-size: rem-calc(12);
                    line-height: rem-calc(18);
                }
            }
        }

        .boxGrayMoreInformationPdp {
            padding: rem-calc(24 16);

            p {
                display: block;
                font-size: rem-calc(16);
                line-height: rem-calc(24);
                letter-spacing: 0.04em;
                margin: rem-calc(0 0 16);
            }

            a {
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                letter-spacing: 0.04em;
            }
        }
    }
}
