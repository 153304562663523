@import "../mixins/helpers";
.web-form-request-page, .web-form-request-page-world, .web-form-request-modal {
    .tingle-modal-box{
        overflow: hidden;        
        min-height: unset;
        max-height: rem-calc(700);
        width: rem-calc(704);
        border-radius: rem-calc(4);
        &:has(.contact-form-success.hidden) {
            min-height: rem-calc(540);
        }
        @include breakpoint(medium down){
            height: 100vh;
            max-height: unset;
            top: 0;
            position: absolute;
            width: 100vw;
            max-width: unset;
            box-shadow: 0 0 rem-calc(1) $black;
            border-radius: 0;
        }
        .tingle-modal-box__content{ 
            width: -webkit-fill-available;
            min-height: unset;
            overflow-y: auto;
            padding: rem-calc(0 12);
            margin: rem-calc(32 20 0 20);
            &:has(.contact-form-success.hidden) {
                min-height: rem-calc(508);
            }
            &::-webkit-scrollbar {
                width: rem-calc(2);
            }
            &::-webkit-scrollbar-thumb {
                background: $fossil; 
            }
            @include breakpoint(large up){
                max-height: rem-calc(636);
                .cc-webFormRequest{
                    &-contactFormNextStep{
                        margin-bottom: rem-calc(10);
                    }
                    &-contactSubTypeCell{
                        width: rem-calc(520);
                        padding: 0;
                        .chosen-container {
                            .chosen-drop {
                                max-height: rem-calc(156);
                            }
                            .chosen-results {
                                max-height: rem-calc(124);
                            }
                        }
                    }
                }
            }
            @include breakpoint(medium down){
                padding: rem-calc(35 20);
                margin: rem-calc(32 0);
                height: 100%;
            }
            .close-button{
                position: absolute;
                top: rem-calc(7);
                right: rem-calc(10);
                width: rem-calc(24);
                max-height: rem-calc(24);
                margin-right: 0;
                @include breakpoint(medium down){
                    top: rem-calc(5);
                    right: rem-calc(7);
                }
            }
        }
        &.min-height-unset {
            min-height: unset;
        }
        .cc-formRequestTitle{
            font-size: rem-calc(24);
            line-height: rem-calc(31);
            margin-top: 0;
            margin-bottom: rem-calc(8);
        }
        .spacer.medium{
            height: 0;
        }
        .cc-webRequestModalCLose{
            max-height: rem-calc(24);
        }
        .cc-webFormRequest{
            &-preContactSection {
                margin-bottom: rem-calc(32);
            }
            &-formGroupContentFields{
                width: 100%;
                @include breakpoint (large up){
                    width: rem-calc(520);
                }
            }
            &-contactFormNextStep-cell{
                position: absolute;
                bottom: rem-calc(32);
                height: rem-calc(44);
            }
            &-contactFormRecapContainer,
            &-contactFormQuestionContainer,
            &-contactFormDescriptionContainer{
                width: 100%;
                margin-left: 0;
                margin-right: 0;
            }
            &-contactPersonalInfo{
                display: contents;
                &-firstContainer{
                    display: block;
                    width: calc(50% - #{rem-calc(8)});
                    margin-right: rem-calc(16);
                }
                &-secondContainer{
                    display: block;
                    width: calc(50% - #{rem-calc(8)});
                }
            }
            #recaptcha{
                transform: scale(0.75);
                transform-origin: 0 0;
            }
            &-requiredFieldInfoCell{
                @include breakpoint(large up) {
                    margin-top: rem-calc(8);
                }
            }
        }
        &.form-dimensions {
            max-height: 85vh;
            .tingle-modal-box__content { 
                max-height: calc(85vh - #{rem-calc(64)});
            }
            @include breakpoint(medium down) {
                max-height: 100vh;
                .tingle-modal-box__content { 
                    max-height: calc(100vh - #{rem-calc(64)});
                }
            }
        }
        .contact-form-success {
            margin-bottom: rem-calc(32);
        }
        .contact-form-error {
            margin-bottom: rem-calc(16);
        }
    }// Tingle modal box end

    // Rules applied both on modal && Contact Page
    .cc-webFormRequest{
        &-formGroupContent{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: rem-calc(0 16);
            gap: rem-calc(24);
            @include breakpoint(medium down) {
                margin-bottom: rem-calc(70);
                padding: 0;
            }
        }
        &-contactButton {
            margin-top: rem-calc(24);
        }
        &-formSupportDescription{
            text-align: center;
            margin-bottom: rem-calc(40);
        }
        &-selectDropdown{
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            letter-spacing: rem-calc(0.56);
            padding-right: rem-calc(55);
            background-position-x: calc(100% + #{rem-calc(30)});
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &-contactFormNextStep{
            width: 17.376%;
            margin-right: 0;
            @include breakpoint(medium down){
                width: 100%;
                bottom: 0;
                &-cell{
                    background: $white;
                    border-top: rem-calc(1) solid $cloud;
                    position: relative;
                    bottom: 0;
                    min-height: rem-calc(75);
                    z-index: 1;
                }
            }
        }
        &-contactForm{
            @include breakpoint(medium down){
                display: flex;
                flex-direction: column;
            }
            .form-content{
                @include flexGenerator (column, space-between, center);
                &.hidden{
                    display: none;
                }
            }
        }
        &-contactSubTypeCell,
        &-preContactSection{
            width: 100%;
            @include breakpoint (large up){
                width: rem-calc(520);;
                height: unset;
                margin-top: 0;
            }
        }
        &-contactFormStepTwo{
            .fix-margin-grid{
                .text-center{
                    margin-bottom: rem-calc(40);
                }
                .cc-webFormRequest{
                    &-contactFormRecap{
                        position: relative;
                        margin-bottom: rem-calc(24);
                        display: flex;
                        flex-direction: column;
                        &_edit {
                            @include breakpoint(medium down) {
                                margin-top: rem-calc(8);
                            }
                            @include breakpoint(large up) {
                                position: absolute;
                                right: rem-calc(16);
                                top: rem-calc(16);
                            }
                        }
                    }
                }
            }
            @include breakpoint(large up){
                .fix-margin-grid{
                    margin: 0;
                }
                .spacer.medium-custom{
                    height: rem-calc(16);
                }
                .cc-webFormRequest{
                    #recaptcha{
                        transform: scale(0.83);
                        transform-origin: 0 0;
                    }
                }
                &-margin-bottom {
                    margin-bottom: rem-calc(16);
                }
                &-left-8 {
                    margin-left: rem-calc(8);
                }
                &-right-8 {
                    margin-right: rem-calc(8);
                }
                .cell.large-5 {
                    width: calc(50% - #{rem-calc(8)});
                    margin-bottom: rem-calc(24);
                }

            }

            @include breakpoint (medium down){
                .form-fields-item {
                    margin-bottom: rem-calc(16);
                }
            }

            .cc-webFormRequest{
                &-contactFormRecap{
                    padding: rem-calc(16);
                    background-color: $off-white;
                }
                &-buttonSend{
                    @include breakpoint(medium down){
                        margin-top: rem-calc(40);
                        margin-bottom: 0;
                        height: rem-calc(44);
                    }
                }
            }
        }
        &-moreInfoTextForm-cell {
            margin-top: rem-calc(32);
            text-align: center;
        }
        &-buttonSend{
            min-width: rem-calc(81);
            width: unset;
            margin: rem-calc(40 0 20 0);
            @include breakpoint (medium down){
                width: 100%;
            }
            &-cell{
                text-align: center;
            }
        }
        &-successMessageButtons{
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: rem-calc(16);
            width: 100%;
            @include breakpoint (medium up) {
                width: unset;
            }
        }
    }
    .cc-formRequestTitle{
        text-align: center;
        margin-top: rem-calc(40);
        margin-bottom: rem-calc(16);
        @include breakpoint (medium down){
            padding: rem-calc(0 16);
            margin-top: rem-calc(32);
            font-size: rem-calc(24);
        }
    }
    .chosen-drop{
        z-index: 1;
        &, & .chosen-results{
            li{
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                letter-spacing: 0.04em;
                &.disabled-result {
                    color: $fossil;
                }
            }
        }
    }
    .contact-form-success{
        padding: rem-calc(0 16);
        text-align: center;
        .cc-webFormRequest{
            &-resultMessageTitle{
                margin: rem-calc(8 0);
                line-height: rem-calc(28);
                @include breakpoint(medium down){
                    padding: 0;
                    margin: rem-calc(16 0);
                }
            }
            &-resultMessageDescription p{
                text-align: center;
                margin-bottom: rem-calc(40);
                @include breakpoint(medium down){
                    padding: rem-calc(0 20);
                }
            }
        }
    }
    .recaptcha{
        display: flex;
        justify-content: center;
        margin-top: rem-calc(16);
        #recaptcha{
            width: fit-content;
        }
    }
    .no-robot{
        color: #DD2B2E;
        @include breakpoint(medium down){
            margin-top: rem-calc(15) !important;
        }
    }
}
.web-form-request-modal{
    @include breakpoint(medium down){
        background: transparent;
        .cc-webFormRequest{
            &-buttonSend{
                margin-bottom: rem-calc(70);
            }
        }
    }
    .hide-in-modal{
        display: none;
    }
    .recaptcha-error-modal{
        font-weight: $global-weight-bold;
        text-transform: uppercase;
        font-size: rem-calc(10);
    }
}
.page[data-action="Newsletter-Contact"]{
    @include breakpoint (large up) {
        padding-top: rem-calc(175);
    }
    .fix-height{
        min-height: calc(100vh - #{rem-calc(124)});    
        align-content: flex-start;
        .height-100{
            height: 100%;
        }
        @include breakpoint(medium down){
            min-height: calc(100vh - #{rem-calc(110)});
            .height-100{
                height: 100%;
            }
        }
    }
    .height-100{
        width: 100%;
    }
    .web-form-request-page, .web-form-request-page-world {
        padding-top: 0;
        @include breakpoint(large up){
            margin-bottom: rem-calc(25);
        }
        @include breakpoint(medium down){
            padding-top: rem-calc(15);
        }
        .hide-in-landing{
            display: none;
        }
        .no-robot-cell{
            @include breakpoint(large up){
                width: 70% !important;
            }
            .no-robot{
                @include breakpoint(large up){
                    text-align: center;
                }
            }
        }
        .hide-in-lp{
            display: none;
        }
        .show-in-lp{
            display: block;
        }
        .contact-form-success{
            padding-top: rem-calc(40);
            .cc-webFormRequest{
                    &-resultMessageDescription{
                    @include breakpoint(large up){
                        width: 100%;
                    }
                }
            }
        }
        .cc-webFormRequest{
            &-contactFormRecapContainer,
            &-contactFormQuestionContainer,
            &-contactFormDescriptionContainer{
                margin-right: 0;
                margin-left: 0;
                width: 100%;
            }
            &-contactPersonalInfo{
                width: 100%;
                display: flex;
                @include breakpoint (medium down){
                    display: block;
                }
                &-firstContainer{
                    width: 50%;
                    margin-right: rem-calc(16);
                    @include breakpoint (medium down){
                        width: 100%;
                        margin-right: 0;
                    }
                    & > *{
                        margin-bottom: rem-calc(24);
                        @include breakpoint (medium down){
                            margin-bottom: rem-calc(16);
                        }
                    }
                }
                &-secondContainer{
                    width: 50%;
                    @include breakpoint (medium down){
                        width: 100%;
                    }
                    & > *{
                        margin-bottom: rem-calc(24);
                        @include breakpoint (medium down){
                            margin-bottom: rem-calc(16);
                        }
                    }
                }
            }
            &-contactFormNextStep{
                @include breakpoint(medium down){
                    margin: 0;
                }
            }
            &-contactFormQuestionContainer,
            &-contactFormDescriptionContainer{
                margin-bottom: rem-calc(16);
                @include breakpoint (medium down){
                    margin-bottom: rem-calc(8);
                }
            }
        }
    }
    .web-form-request-page{
        width: 42.152%;
        @include breakpoint (medium down){
            width: 100%;
            padding-top: 0;
        }
    }
    .cc-webFormRequest{
        &-formGroupContentFields{
            width: 100%;
            @include breakpoint (large up){
                width: rem-calc(520);
                height: unset;
            }
        }
    }
}
.visibility-hidden{
    visibility: hidden;
}
// multilanguage popup
.center {
    margin: auto;
    width: 60%;
    padding: rem-calc(10);
    min-width: rem-calc(200);
}

.faq-section-wrapper {
    background-color: $off-white;
    padding: rem-calc(24);
    .description {
        margin-bottom: rem-calc(8);
    }
    
    .accordion.accordion-page {
        margin-bottom: 0;
        background-color:#f7f7f7;
        .accordion-item {
            letter-spacing: rem-calc(0.13);
            &.active {
                .accordion-title::after {
                    right: 0;
                    padding-bottom: rem-calc(8);
                    font-size: rem-calc(30);
                }
            }
            &:first-child {
                border-top: 0;
            }
            .accordion-title {
                padding-left: 0;
                background-color:#f7f7f7;
                border-top: 0;
                font-size: rem-calc(12);
                letter-spacing: rem-calc(0.48);
                font-weight: $global-weight-black;
                &::after {
                    right: 0;
                    font-size: rem-calc(24);
                }
                span.strong{
                    font-weight: bold;
                }
            }
            .accordion-content {
                padding: 0;
                background-color:#f7f7f7;
                & > * {
                    margin-bottom: rem-calc(16);
                    font-size: rem-calc(12);
                    line-height: rem-calc(18);
                    letter-spacing: rem-calc(0.48);
                }
            }
        }
    }
}
.cc-faqMoreButton-wrapper {
    background-color: $off-white;
    padding-bottom: rem-calc(16);
    .contact-form-next-step {
        border: rem-calc(1) solid $cloud;
    }
}
.contact-link {
    text-decoration: underline;
    font-weight: normal;
    &:hover {
        cursor: pointer;
    }
}
