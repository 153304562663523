@import '../setup/settings';
.footer {
    & .close-button {
        position: absolute;
        top: rem-calc(10);
        right: rem-calc(10);
        font-size: 2rem;
    }
    &-contacts {
        background-color: $more-light-gray;
        .form-footer-center-small-general {
            @include breakpoint(medium down) {
                justify-content: center;
            }
        }
        .input-group {
            margin-bottom: 0;
        }
        .footer-box {
            .title-box-contacts {
                font-size: rem-calc(22);
                letter-spacing: rem-calc(1);
                line-height: rem-calc(28);
                margin-bottom: rem-calc(6);
                @include breakpoint(medium down) {
                    font-size: rem-calc(20);
                    font-weight: $global-weight-dark;
                }
                &.strong {
                    @include breakpoint(medium down) {
                        font-size: rem-calc(20);
                        font-weight: $global-weight-dark;
                    }
                }
            }
            .text-box-contacts {
                font-size: rem-calc(14);
                letter-spacing: rem-calc(0.4);
                line-height: rem-calc(23);
            }
            .input-newsletter-world,
            .input-newsletter {
                outline-width: 0;
            }
            .input-newsletter {
                max-width: rem-calc(410);
            }
            .store-locator-footer-container {
                max-width: rem-calc(410);
            }
            .button-arrow-right {
                padding: 0;
                svg {
                    width: rem-calc(6);
                    height: rem-calc(10);
                }
            }
        }
        .form-footer-center-small {
            @include breakpoint(medium down) {
                justify-content: left;
            }
        }
        .box-hide-for-small-general {
            @include breakpoint(medium down) {
                display: none;
            }
        }
    }
    .prehome-country-selector {
        border-top: rem-calc(1) solid $border-gray;
        .box-footer-legal-right {
            @include breakpoint(medium down) {
                display: block;
            }
        }
    }
    .footer-contacts-pre-home {
        .footer-contacts {
            background-color: $white;
        }
        .footer-box {
            margin: 0;
            padding-top: rem-calc(29);
            padding-bottom: rem-calc(10);
            @include breakpoint(medium down) {
                padding: rem-calc(18);
                padding-bottom: 0;
                &:first-child {
                    border-bottom: rem-calc(1) solid $border-gray;
                }
                .title-box-contacts {
                    font-size: rem-calc(17);
                    text-align: left;
                    line-height: rem-calc(24);
                }
            }
        }
        .box-hide-for-small-general {
            @include breakpoint(medium down) {
                display: block;
            }
        }
    }
}
.scrollbar-custom {
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: rem-calc(5);
    }
    &::-webkit-scrollbar-thumb {
        background-color: $black;
        border-radius: rem-calc(0);
        height: rem-calc(200);
    }
}

.footer-bottom-checkout {
    padding: rem-calc(17);
    border-top: rem-calc(1) solid $border-gray;
    position: relative;
    background: $white;
    margin-top: rem-calc(36);
    p {
        margin-bottom: 0;
        line-height: rem-calc(17);
    }
}

.footer-bottom-fix {
    margin-bottom: rem-calc(-150);
}

.footer-container-nopadding-mobile {
    @include breakpoint(medium down) {
        padding: 0;
    }
}

.wrapper-gray-footer-prehome {
    border-top: rem-calc(1) solid $border-gray;
}

hr {
    width: 91%;
    height: rem-calc(1);
    background: $white;
    margin-bottom: rem-calc(17);
    margin-top: 0;
    margin-left: 0;
}

.mobileHrWidth {
    @include breakpoint(medium down) {
        width: 77% !important;
        margin-left: rem-calc(18);
    }
}

.mobileWidth {
    @include breakpoint(medium down) {
        width: 100% !important;
    }
}

.buttonDim {
    width: rem-calc(164);
    height: rem-calc(40);
    color: $white;
    @include breakpoint(medium down) {
        width: 100% !important;
    }
}

.buttonLang {
    width: rem-calc(164);
    height: rem-calc(40);
    margin-left: 80%;
    color: black;
    background-color: white;
    border-color: black;
    @include breakpoint(medium down) {
        width: 100% !important;
    }
}

.img-popup-newsletter {
    width: 100%;
    object-fit: cover;
    object-position: top;
    height: rem-calc(500);
    @include breakpoint(medium down) {
        width: 100%;
        object-fit: cover;
        object-position: top;
        height: rem-calc(195) !important;
    }
}

.maxDim {
    max-width: unset;
    max-height: unset;
    overflow: hidden;

    @include breakpoint (medium down) {
        max-width: unset;
        max-height: unset;
    }

    @include breakpoint (small down) {
        max-width: 100%;
        max-height: 100%;
    }
}

.marginMandatory {
    margin-bottom: rem-calc(30);
}

.marginNews {
    margin-bottom: rem-calc(9);
}

.marginPromoProfile {
    margin-bottom: rem-calc(13);
}

.marginPrivacy {
    margin-bottom: rem-calc(14);
}

.bottomButton {
    bottom: rem-calc(-34);
}

.fontMr {
    font-size: rem-calc(14) !important;
}

.fontLittle {
    font-size: rem-calc(12) !important;
    margin-top: rem-calc(17);
    margin-bottom: rem-calc(26);
}

.imgPopUpDimensions {
    @include breakpoint(medium down) {
        width: 100%;
        height: rem-calc(210);
    }
}

.customWidth {
    width: 61%;
    @include breakpoint(medium down) {
        width: 100% !important;
    }
}

.mrMrs {
    margin-bottom: rem-calc(27) !important;
    font-size: rem-calc(14) !important;
    margin-right: rem-calc(105) !important;
    @include breakpoint(medium down) {
        margin-right: rem-calc(0) !important;
        padding: rem-calc(0 18);
    }
}

.checkboxMarginMobileDesktop {
    @include breakpoint(large up) {
        width: 30%;
    }
}

.checkboxMarginMobileDesktopTargeted {
    @include breakpoint(large up) {
        width: 30%;
    }
}

.mandatoryField {
    margin-top: rem-calc(-12) !important;
}

.selectMobileLabelCountry {
    @include breakpoint(medium down) {
        width: 100% !important;
    }
}

.mobileLabelCountry {
    @include breakpoint(medium down) {
        width: 100% !important;
    }
}

.labelMail {
    margin-right: rem-calc(22);
    width: rem-calc(200);
    @include breakpoint(medium down) {
        width: 100% !important;
    }
}

.labelNewsletterMail {
    border-bottom-color: rgb(130, 130, 130);
    padding-left: rem-calc(5);
    margin-right: rem-calc(22);
    width: rem-calc(200);
    @include breakpoint(medium down) {
        width: 100% !important;
    }
}

.labelCountry {
    width: rem-calc(200);
    border: rem-calc(1) solid black;
}

.form-error,
.unknown-email-domain {
    .is-visible {
        margin-top: rem-calc(-3) !important;
    }
}

.mailCountryFlex {
    display: flex;
    height: rem-calc(36) !important;
}

.img-popup {
    padding: 0;
}

.cc-newsletterFormTitle {
    padding: 0;
}

// This class is related with the Content Asset 'preferences-cell-image'
.newsletterImg,
.preferences-cell-image {
    width: rem-calc(289);
    height: 100%;
    min-height: rem-calc(385);
    object-fit: cover;
    @include breakpoint(medium down) {
        width: 100%;
        // Need to override the inline style of content asset 'preferences-cell-image'
        height: rem-calc(500) !important;
        object-fit: cover;
        object-position: top;
        border-radius: rem-calc(5);
    }

    @include breakpoint(small down) {
        height: rem-calc(185) !important;
        min-height: unset;
    }
}

.newsletter-popup {
    // Modal
    &.tingle-modal {
        z-index: 1000; //override footer
        @include breakpoint(medium down) {
            overflow-y: hidden;
        }
        .tingle-modal-box {
            overflow-x: hidden;
            overflow-y: auto;
            border-radius: rem-calc(4);
            @include breakpoint(medium down) {
                // the design height was 75vh;
                max-height: 80dvh;
                height: 100%;
                margin-top: auto;
                width: 100%;
                padding: rem-calc(16);
            }
            @include breakpoint(small down) {
                max-height: 100dvh;
            }
            &__content {
                padding: 0;

                .cc-imgPopup {
                    width: unset;
                    height: unset;
                    @include breakpoint(medium down) {
                        width: unset;
                        margin-top: rem-calc(32);
                    }
                    @include breakpoint(small down) {
                        margin-top: unset;
                    }
                }
                .newsletter-popup-grid {
                    display: flex;
                    flex-flow: unset;
                    @include breakpoint(medium down) {
                        overflow-x: hidden;
                        overflow-y: auto;
                        max-height: 100%;
                        padding-top: rem-calc(32);
                        display: unset;
                    }
                }
                .title {
                    margin: rem-calc(32 0 16 32);
                    font-family: $body-font-family-cursive;
                    width: rem-calc(343);
                    @include breakpoint(medium down) {
                        margin: rem-calc(32 0 16 0);
                        width: 100%;
                    }
                }
                .cc-highlightNewsletter {
                    color: #8f6660;
                    width: 100%;
                }
            }
            .newsletter-email-input {
                @include breakpoint(medium down) {
                    margin-left: 0;
                    margin-top: rem-calc(32);
                }
            }
            .cc-newsletterFormTitle-mobile {
                padding: 0;
                margin: rem-calc(16 0);
                @include breakpoint(medium down) {
                    margin: rem-calc(16 0);
                }
            }
            #email {
                width: rem-calc(343);
                @include breakpoint(medium down) {
                    width: 100%;
                    margin-bottom: rem-calc(4);
                }
            }
            .cc-stepZero {
                margin-left: rem-calc(32);
                height: 100%;
                @include breakpoint(medium down) {
                    margin-left: 0;
                }
                &-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    height: 100%;

                    &-field-and-error {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;

                        @include breakpoint(medium down) {
                            gap: rem-calc(8);
                            width: 100%;
                        }

                        .form-fields-item {
                            width: 100%;
                        }
                    }
                }
                .cc-bottom-buttons {
                    position: unset;
                    bottom: 0;
                    left: rem-calc(16);
                    right: rem-calc(16);
                    margin-bottom: rem-calc(32);

                    @include breakpoint(medium down) {
                        width: 100%;
                        margin-bottom: 0;
                    }
                    @include breakpoint( large up) {
                        left: unset;
                        right: unset;
                    }
                }
            }
            .cc-stepOnePointOne {
                .cc-directOpenDesktop {
                    font-family: $body-font-family-cursive;
                    padding: rem-calc(32 32 0 0);
                    margin-bottom: rem-calc(24);
                    margin-left: rem-calc(32);
                }
                .cc-notDirectOpen {
                    font-family: $body-font-family-cursive;
                    padding: rem-calc(32 32 0 32);
                }
            }
            .cc-stepOnePointTwo {
                padding: rem-calc(32 32 0 32);
                margin-bottom: rem-calc(32);
                background: $off-white;
                @include breakpoint(medium down) {
                    background: $white;
                    padding: 0;
                }
                &-preferencesButtons {
                    display: flex;
                }
            }
            .mobileLabelCountry {
                margin-bottom: rem-calc(8);
                @include breakpoint(medium down) {
                    padding: 0;
                }
            }
            #email-errors {
                font-size: rem-calc(12);
                font-family: $body-font-family;
                font-weight: $global-weight-normal;
                line-height: 150%;
                letter-spacing: rem-calc(0.48);
                display: flex;
                justify-content: flex-start;
                align-items: unset;
                gap: rem-calc(8);
                width: rem-calc(343);
                margin-bottom: rem-calc(8);

                @include breakpoint(medium down) {
                    width: 100%;
                }

                .cc-orderCancellation-feedbackMessage_icon svg {
                    width: rem-calc(16);
                    height: rem-calc(16);
                }
            }

            .error-border{
                border: rem-calc(1) solid #FF949E;
                border-radius: rem-cacl(2);
            }

            #newsletterCountry,
            #newsletterGender {
                background-color: $white;
                margin-bottom: 0;
                width: rem-calc(308);
                @include breakpoint(medium down) {
                    width: unset;
                }
            }
            .form-fields-item__label--select {
                margin-bottom: rem-calc(32);
            }
            .newsletters-select-options {
                display: inline-flex;
                justify-content: space-between;
                padding: rem-calc(0 32);
                @include breakpoint(medium down) {
                    display: block;
                    padding: 0;
                }
            }
            .newsletter-popup-country {
                width: rem-calc(304);
                margin-right: rem-calc(16);
                @include breakpoint(medium down) {
                    width: unset;
                    margin-right: 0;
                }
            }
            .newsletter-popup-gender {
                width: rem-calc(304);
                @include breakpoint(medium down) {
                    width: unset;
                }
            }
            .marginPromoProfile {
                @include breakpoint(medium down) {
                    margin-top: rem-calc(18);
                }
            }
            .marginNews,
            .marginPromoProfile {
                color: $charcoal !important;
                margin-bottom: rem-calc(16);
                @include breakpoint(medium down) {
                    margin-bottom: rem-calc(32);
                }
            }
            .fontLittle {
                font-size: rem-calc(12) !important;
                margin-top: 0;
                margin-bottom: rem-calc(32);
            }
            .buttonDim {
                height: rem-calc(44);
                @include breakpoint(medium down) {
                    width: 91%;
                }
            }
            .cc-proceedStepZeroDesktop {
                width: rem-calc(343);
            }
            .cc-proceedStepZeroMobile {
                @include breakpoint(medium down) {
                    margin-right: 0;
                    max-width: 100%;
                }
            }
            .marginPrivacy {
                a {
                    text-decoration: underline;
                }
                @include breakpoint(medium down) {
                    margin: rem-calc(18 0 32 0);
                }
            }
            .mobilePadding {
                @include breakpoint(medium down) {
                    padding: 0;
                }
            }
            .cc-noThanks {
                max-width: rem-calc(343);
                background-color: $white;
                color: $charcoal;
                margin-top: rem-calc(2);
                @include breakpoint(medium down) {
                    padding: 0;
                    max-width: 100%;
                }
            }
            .cc-noThanksDirectOpen {
                @include breakpoint(medium down) {
                    margin-top: rem-calc(45);
                    padding: 0;
                    color: $charcoal;
                    background-color: $white;
                }
            }
            .cc-popupNavigationButtons {
                @include breakpoint(medium down) {
                    display: inline-flex;
                }
            }
            .cc-proceedStepOnePointOne,
            .cc-proceedStepOnePointTwo {
                @include breakpoint(medium down) {
                    min-width: 61.414444vw;
                    height: rem-calc(44);
                }
            }
            .cc-backButtonStepOnePointOne {
                @include breakpoint(medium down) {
                    max-width: rem-calc(44);
                    height: rem-calc(44);
                    margin-right: rem-calc(16);
                }
            }
            .cc-backButtonStepOnePointTwo {
                @include breakpoint(medium down) {
                    max-width: rem-calc(44);
                    height: rem-calc(44);
                    margin-right: rem-calc(16);
                }
            }
            .cc-newsletterModal {
                &-noteText {
                    margin: rem-calc(8 0 32 0);
                    a {
                        text-decoration: underline;
                    }
                }
            }
            .cc-backStepOnePointOne {
                @include breakpoint(medium down) {
                    background-color: white;
                    border: rem-calc(1) solid $charcoal;
                    border-radius: rem-calc(2);
                }
            }
            .cc-backStepOnePointTwo {
                @include breakpoint(medium down) {
                    background-color: white;
                    border: rem-calc(1) solid $charcoal;
                    border-radius: rem-calc(2);
                }
            }
            .image-back-button {
                content: url('../../images/int-arrow-left.svg');
            }
            .cc-directOpenMobile {
                &.title {
                    margin-left: rem-calc(32);
                    @include breakpoint(medium down) {
                        margin-left: 0;
                    }
                    &.heading {
                        font-family: $body-font-family-cursive;
                        margin: rem-calc(0 0 32 0) !important;
                    }
                }
            }
            .cc-borderFrame {
                @include breakpoint(medium down) {
                    height: rem-calc(2);
                    width: rem-calc(100);
                    border-radius: rem-calc(5);
                    position: fixed;
                    top: rem-calc(16);
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: #e9e9e9;
                }
            }
        }
        .tingle-modal-box.cc-confirmSubscriptionModal {
            width: rem-calc(500);
            height: rem-calc(268);
            @include breakpoint(medium down) {
                width: 100vw;
                min-height: rem-calc(291);
            }
        }
        .checkboxMarginMobileDesktop {
            @include breakpoint(medium down) {
                display: flex;
                flex-direction: column;
            }
        }

        .checkboxMarginMobileDesktopTargeted {
            @include breakpoint(medium down) {
                display: flex;
                flex-direction: column;
            }
        }
        .cc-directEmailButtons {
            .cc-backButtonStepOnePointOne {
                height: 0;
            }
            .cc-directEmailProceed {
                width: 100%;
            }
            .cc-noThanksDirectOpen {
                margin-top: rem-calc(2);
                @include breakpoint(medium down) {
                    width: 100%;
                }
            }
        }
        .cc-subscriptionSuccess {
            padding: rem-calc(0 32);
            @include breakpoint(medium down) {
                padding: 0;
            }
            &-title {
                font-family: $body-font-family-cursive;
                margin-top: rem-calc(32);
                @include breakpoint(medium down) {
                    margin-top: 0;
                }
            }
            &-paragraph {
                p {
                    margin-bottom: rem-calc(32);
                    @include breakpoint(medium down) {
                        padding-right: rem-calc(10);
                    }
                }
            }
            &-close {
                margin-bottom: rem-calc(32);
                &.buttonDim {
                    width: 100% !important;
                    margin-right: 0;
                }
            }
        }
        .close {
            line-height: 0;
            &-icon {
                width: rem-calc(16);
                height: rem-calc(16);
                @include breakpoint(large up) {
                    width: rem-calc(24);
                    height: rem-calc(24);
                }
            }
        }
        .newsletterCompleteImage {
            margin: 0;
        }
    }
    &__content {
        @include breakpoint(medium down) {
            margin-left: 0;
        }
        .registerNewsletter{
            height: 100%;
        }
        html.flexbox & {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        @include breakpoint(medium down) {
            padding: 0;
        }
        .title {
            margin-bottom: rem-calc(16);
            @include breakpoint(large up) {
                margin-top: rem-calc(16);
                margin-bottom: rem-calc(16);
            }
            @include breakpoint(medium down) {
                margin-top: rem-calc(5) !important;
            }
            html.flexbox & {
                margin-top: rem-calc(33);
            }
        }
        form {
            .mrmrsDescription {
                display: none;
                margin: 0;
                p {
                    margin: 0;
                }
            }
            .mrmrsChoice {
                margin-bottom: rem-calc(10);
                @include breakpoint(medium down) {
                    margin-top: rem-calc(22);
                }
            }
            .boolean-field {
                &:last-of-type {
                    padding-right: 0;
                }
            }
            .button {
                width: 100%;
                background-color: $charcoal;
            }
            .mandatory-hint {
                display: none;
                margin: 0;
                p {
                    margin: 0;
                }
            }
        }
        .newsletter-email-input{
            padding: rem-calc(32);
            width: fit-content;
        }
        .input-error-group {
            @include breakpoint(large up) {
                text-align: left;
                height: rem-calc(20);
                margin-bottom: rem-calc(24);
            }
        }
    }
    &__picture {
        background: $light-gray;
        img {
            display: block;
            width: 100%;
            // Modernizr
            html.objectfit & {
                height: 100%;
                object-fit: cover;
                padding: rem-calc(16);
                background-color: white;
            }
        }
    }
}

.already-registered-box {
    padding-top: rem-calc(32);
    margin-bottom: rem-calc(8);
    width: 65%;
    padding-left: rem-calc(32);
    @include breakpoint (medium down) {
        padding-top: unset;
        width: unset;
        padding-left: unset;
    }
}
.already-registered-title {
    font-family: $body-font-family-cursive;
    font-weight: $global-weight-normal;
    font-size: rem-calc(24);
    color: $charcoal;
    line-height: rem-calc(31.2);
    padding: rem-calc(32 32 0 0);
    margin-bottom: rem-calc(24);
}
.already-registered-text {
    font-family: $body-font-family;
    font-weight: $global-weight-normal;
    font-size: rem-calc(14);
    line-height: rem-calc(21);
    color: $charcoal;
    margin-left: rem-calc(32);
    @include breakpoint (medium down) {
        margin-left: unset;
    }
}
.written-email-label {
    margin-top: rem-calc(8);
    margin-bottom: rem-calc(32);
    @include breakpoint (medium down) {
        margin-left: unset;
    }
}
.already-registered-links {
    &.arl-privacy-cookie {
        .already-registered-pp-cp {
            margin-bottom: rem-calc(16);
            a {
                text-decoration: underline;
            }
        }
    }
}
.background-button {
    background: $off-white;
    padding: rem-calc(32 32 0 32);
    @include breakpoint (medium down) {
        background: $white;
    }
}

.newsletter-complete-page {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: rem-calc(64);
    .title {
        color: $charcoal;
        font-family: $body-font-family-cursive;
        font-size: rem-calc(32);
        font-style: normal;
        font-weight: $global-weight-normal;
        line-height: 130%;
        margin-right: rem-calc(65);
        margin-bottom: rem-calc(16);
        @include breakpoint(medium down) {
            margin-right: rem-calc(30);
            margin-bottom: rem-calc(24);
        }
    }
    .cc-highlight-newsletter-complete-page {
        color: #8f6660;
    }
    .dark {
        color: $charcoal;
        font-family: $body-font-family;
        font-size: rem-calc(14);
        font-style: normal;
        font-weight: $global-weight-normal;
        line-height: 150%;
        letter-spacing: rem-calc(0.56);
    }
    .cc-newsletterConfirm {
        &-imageContainer {
            @include breakpoint(medium down) {
                width: 100%;
            }
        }
        &-breadcrumbs {
            margin: rem-calc(32 0);
            @include breakpoint(medium down) {
                margin: rem-calc(32 0 24 0);
            }
        }
        &-description {
            margin: auto;
            padding-top: rem-calc(64);
            width: 37.905%;
            @include breakpoint(medium down) {
                margin: 0;
                width: 100%;
                padding: rem-calc(0 16 32 0);
            }
        }
        &-recommendationSuggestedProducts {
            width: 100%;
            height: auto;
        }
    }
}

// .............footer Customer support ............
.tingle-modal-form-request {
    .close-button-modal {
        img {
            width: rem-calc(14);
        }
    }
    .form-request-title {
        font-size: rem-calc(20);
        margin-bottom: rem-calc(30);
        @include breakpoint(medium down) {
            font-size: rem-calc(22);
        }
    }
    .cc-webFormRequest {
        &-contactForm {
            @include breakpoint(medium down) {
                padding-bottom: rem-calc(30);
            }
            .medium-custom {
                height: 2.9rem;
            }
            .no-robot {
                color: #ff0000;
                text-transform: uppercase;
                font-size: rem-calc(12);
                font-weight: bold;
                padding-top: rem-calc(12);
                height: 0;
                @include breakpoint(medium down) {
                    padding-bottom: rem-calc(20);
                }
            }
            .recaptcha {
                @include breakpoint(medium down) {
                    margin-top: rem-calc(16);
                }
            }
        }
    }
    .text-area-web-form-request {
        border: rem-calc(1) solid $medium-dark-gray;
        resize: none;
    }
    .cc-webFormRequest {
        &-requiredFieldInfoCell {
            display: flex;
            align-items: flex-end;
        }
    }
    .more-info-text-form {
        margin-bottom: 0;
    }
    .link-margin-right-form {
        margin-right: rem-calc(10);
    }
    @include breakpoint(medium down) {
        &.tingle-modal {
            &.tingle-modal--visible {
                justify-content: flex-end;
                overflow-y:scroll;
                visibility: visible;
                width: 100%;
                background: rgba(0,0,0,0.75);
                .tingle-modal-box {
                    margin-top: rem-calc(0);
                    height: auto;
                    max-height: inherit;
                    box-shadow: none;
                    margin-bottom: 0;
                    .tingle-modal-box__content {
                        overflow-y: scroll;
                        max-height: 100vh;
                    }
                }
            }
        }
    }
}

.webform-request-resize {
    resize: none;
}

.seo-description-full {
    padding: rem-calc(0 40);
    text-align: center;
    background-color: #f2f2f2;
    padding-top: rem-calc(80);
    padding-bottom: rem-calc(80);
}

#recaptcha iframe {
    @media only screen and (min-width: 320px) {
        max-width: 100%;
        transform: scale(0.8);
        -webkit-transform: scale(0.8);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }
    @media only screen and (min-width: 360px) {
        max-width: 100%;
        transform: scale(0.95);
        -webkit-transform: scale(0.95);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }
    @media only screen and (min-width: 412px) {
        max-width: 100%;
        transform: scale(1.23);
        -webkit-transform: scale(1.23);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }
    @media only screen and (min-width: 375px) {
        max-width: 100%;
        transform: scale(0.99);
        -webkit-transform: scale(0.99);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }
    @include breakpoint(large up) {
        max-width: 100%;
        transform: scale(0.98);
        -webkit-transform: scale(0.98);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }
}

.footer-social-ww {
    .icon-footer-follow-mobile {
        margin-right: rem-calc(40);
        &:last-of-type {
            margin-right: 0;
        }
    }
}

.footer-contacts {
    &.world-footer {
        .box-img-newsletter-ww {
            .image-ww-container {
                width: 100%;
                margin-top: -15%;
                margin-bottom: -15%;
                img {
                    width: 100%;
                }
            }
        }
        .footer-box {
            @include breakpoint(medium down) {
                padding-right: rem-calc(30);
                padding-left: rem-calc(30);
            }
        }
    }
}
.privacyFlagEnable {
    .tingle-modal-box {
        /* The slider */
        .sliderToggle {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $cloud;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            width: rem-calc(48);
            height: rem-calc(24);
            @include breakpoint(medium down) {
                width: rem-calc(80);
                height: rem-calc(40);
            }
        }
        .sliderToggle:before {
            position: absolute;
            content: '';
            height: rem-calc(19);
            width: rem-calc(19);
            left: rem-calc(2);
            top: rem-calc(2.5);
            background-color: $white;
            box-shadow: 2px 3px 6px rgba($color: #000000, $alpha: 0.16);
            transition: 0.4s;
            border-radius: 50%;
            @include breakpoint(medium down) {
                height: rem-calc(19);
                width: rem-calc(19);
                left: rem-calc(2);
                top: rem-calc(2.5);
            }
        }
        input:checked + .sliderToggle:before {
            transform: translateX(rem-calc(24));
            @include breakpoint(medium down) {
                transform: translateX(rem-calc(24));
            }
        }
        input:checked + .sliderToggle {
            background-color: $charcoal;
        }
        .sliderToggle.round {
            border-radius: rem-calc(34);
        }
        /* The switch - the box around the slider */
        .switchToggle {
            position: relative;
            display: inline-block;
            width: rem-calc(47);
            height: rem-calc(28);
        }
    }
}

#grayOnDisableDescription {
    .disactivate {
        pointer-events: none;
        color: $charcoal;
        border-bottom-color: #e8e8e8;
    }
}

.cc-preFooter {
    border-top: rem-calc(1) solid $cloud;
    @include breakpoint(medium down) {
        border-top: rem-calc(1) solid rgba(0, 0, 0, 0.2);
        border-bottom: rem-calc(1) solid rgba(0, 0, 0, 0.2);
    }
    &-box {
        align-items: center;
        justify-content: space-between;
        padding: rem-calc(40 32);
        @include breakpoint(medium down) {
            padding: rem-calc(32 16);
        }
        &:not(:last-of-type) {
            @include breakpoint(large up) {
                border-right: rem-calc(1) solid $cloud;
            }
            @include breakpoint(medium down) {
                border-bottom: rem-calc(1) solid $cloud;
            }
        }
        &_content {
            align-items: center;
            &_icon {
                width: rem-calc(16);
                height: rem-calc(16);
                margin-right: rem-calc(8);
            }
            &_text {
                font-size: rem-calc(16);
                line-height: 150%;
            }
        }
    }
}
.cc-footer {
    background-color: $off-white;
    position: relative;
    &-boxTop {
        padding: rem-calc(80 32 0 32);
        @include breakpoint(medium down) {
            padding: rem-calc(56 16 0 16);
        }
        &.cc-preHomeFooter-ww {
            @include breakpoint(large up) {
                padding: rem-calc(80 32);
            }
        }
        &_followUs {
            width: 18.47222222222222vw;
            @include breakpoint(medium down) {
                width: 100%;
                padding-bottom: rem-calc(28);
            }
            &.cc-preHomeFooter {
                @include breakpoint(large up) {
                    padding-top: rem-calc(16);
                }
            }
            &_image {
                width: 34.6499999999vw;
                max-width: rem-calc(130);
                margin-bottom: rem-calc(64);
                max-height: rem-calc(36.8);
                @include breakpoint(large up) {
                    width: rem-calc(130);
                    margin-bottom: rem-calc(32);
                    height: 4.599999999999vh;
                }
            }
            &_social {
                margin-right: rem-calc(16);
                display: inline-block;
                &_image {
                    @include breakpoint(medium down) {
                        width: rem-calc(30);
                    }
                }
            }
        }
        &_contact {
            margin-left: 9.16666666666667vw;
            width: 26.45833333333333vw;
            font-family: $body-font-family;
            @include breakpoint(medium down) {
                margin-left: 0;
                width: 100%;
            }
            &.cc-preHomeFooter-ww {
                @include breakpoint(large up) {
                    margin: 0 9.16666666666667vw;
                }
            }
            &_title {
                line-height: 135%;
                margin: rem-calc(16 0 0 0);
                @include breakpoint(medium down) {
                    margin: rem-calc(36 0 0 0);
                }
            }
            &_input {
                background: none;
                border: 0;
                border-bottom: rem-calc(1) solid rgba(0, 0, 0, 0.2);
                margin-top: rem-calc(32);
                padding: rem-calc(0 0 12 0);
                border-radius: 0;
                @include breakpoint(medium down) {
                    margin-top: rem-calc(16);
                }
                &:focus {
                    border: none;
                    border-bottom: rem-calc(1) solid $charcoal;
                    background: none;
                }
            }
            &_buttonArrow {
                padding: 0;
                position: absolute;
                right: 0;
                bottom: rem-calc(22);
                width: rem-calc(16);
                svg {
                    width: rem-calc(16);
                    height: rem-calc(16);
                }
            }
        }
        &_links {
            padding: rem-calc(16 0 0 0);
            gap: rem-calc(16);
            width: 26.52777777777778vw;
            @include breakpoint(medium down) {
                padding: rem-calc(18 0 0 0);
                width: 100%;
            }
            &_boxlinks {
                width: 54.09722222222222vw;
                margin-left: 26.5277777777778vw;
                padding: rem-calc(0 16);
                gap: rem-calc(16);
                background: transparent;
                @include breakpoint(medium down) {
                    width: 100%;
                    margin-left: 0;
                    padding: rem-calc(4 0 0 0);
                    gap: 0;
                }
                &.cc-preHomeFooter {
                    @include breakpoint(large up) {
                        width: 81.73611111111111vw;
                        margin-left: 0;
                    }
                }
                &_boxes {
                    font-size: rem-calc(14);
                    &.accordion-item {
                        @include breakpoint(large up) {
                            &.accordion-item {
                                border-bottom: none;
                            }
                        }
                    }
                    &:first-child {
                        border-top: none;
                    }
                    .cc-footerLinks {
                        &-content {
                            &_title {
                                font-size: rem-calc(16);
                                &.accordion-title::after {
                                    right: 0;
                                    top: 60%;
                                    @include breakpoint(large up) {
                                        display: none;
                                    }
                                }
                                &.accordion-title {
                                    padding: rem-calc(0 0 16 0);
                                    @include breakpoint(medium down) {
                                        padding: rem-calc(24 0 12 0);
                                    }
                                }
                            }
                            &_box {
                                margin: rem-calc(12 0 0 0);
                                @include breakpoint(large up) {
                                    display: block !important;
                                    margin: rem-calc(0);
                                }
                                &_text {
                                    padding-bottom: rem-calc(24);
                                    @include breakpoint(large up) {
                                        padding-bottom: rem-calc(6);
                                        font-size: rem-calc(12);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &-links {
        padding: rem-calc(52 32 80);
        @include breakpoint(medium down) {
            padding: rem-calc(8 16 0 16);
        }
        &_boxlinks {
            width: 56.32vw;
            margin-left: 26.5277777777778vw;
            padding: rem-calc(0 0 0 16);
            gap: rem-calc(16);
            background: transparent;
            position: relative;
            @include breakpoint(medium down) {
                width: 100%;
                margin-left: 0;
                padding: rem-calc(4 0 0 0);
                gap: 0;
            }
            &.cc-preHomeFooter {
                @include breakpoint(large up) {
                    width: 81.73611111111111vw;
                    margin-left: 0;
                    padding: rem-calc(0);
                }
            }
            .cc-footerQR {
                margin-top: rem-calc(32);
                @include breakpoint(large up) {
                    position: absolute;
                    left: -26.5277777777778vw;
                    top: 0;
                    margin-top: 0;
                }
                #qrcode,
                #qrcode1 {
                    padding: 0 !important; /* To override content asset inline style rule */
                }
            }
            &_boxes {
                &.accordion-item {
                    @include breakpoint(large up) {
                        &.accordion-item {
                            border-bottom: none;
                        }
                    }
                }
                &:first-child {
                    border-top: none;
                }
                .cc-footerLinks {
                    &-content {
                        &_title {
                            font-size: rem-calc(16);
                            line-height: rem-calc(24);
                            letter-spacing: rem-calc(0.56);
                            @include breakpoint(large up) {
                                font-size: rem-calc(14);
                                line-height: rem-calc(21);
                            }
                            &.accordion-title::after {
                                right: 0;
                                top: 60%;
                                @include breakpoint(large up) {
                                    display: none;
                                }
                            }
                            &.accordion-title {
                                padding: rem-calc(0 0 16 0);
                                @include breakpoint(medium down) {
                                    padding: rem-calc(24 0 12 0);
                                }
                            }
                        }
                        &_box {
                            margin: rem-calc(12 0 0 0);
                            padding: 0;
                            @include breakpoint(large up) {
                                display: block !important;
                                height: unset !important;
                                margin: rem-calc(0);
                            }
                            &_text {
                                font-size: rem-calc(12);
                                line-height: rem-calc(18);
                                letter-spacing: rem-calc(0.48);
                                padding-bottom: rem-calc(24);
                                @include breakpoint(large up) {
                                    padding-bottom: rem-calc(6);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &-changeCountrySocial {
        padding: rem-calc(0 0 0 32);
        border-top: rem-calc(1) solid $cloud;
        align-items: center;
        @include breakpoint(medium down) {
            margin-top: rem-calc(142);
            &.cc-preHomeWw-links {
                margin-top: rem-calc(114);
            }
        }
        &_companyInfo {
            padding-right: rem-calc(30);
            @include breakpoint(medium down) {
                width: 100%;
                position: absolute;
                bottom: rem-calc(101);
                left: rem-calc(16);
            }
            p {
                margin: 0;
                font-size: rem-calc(10);
                line-height: rem-calc(15);
                letter-spacing: rem-calc(0.4);
                font-family: $body-font-family;
            }
        }
        &_boxSmall {
            padding: rem-calc(16 32 16 16);
            border-left: rem-calc(1) solid $cloud;
            width: 16.04166666666667vw;
            cursor: pointer;
            font-size: rem-calc(14);
            @include breakpoint(medium down) {
                border-left: none;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 50%;
            }
            &_iconToggle {
                width: rem-calc(11);
                height: rem-calc(16);
                position: absolute;
                top: 32%;
                right: rem-calc(32);
                transform: rotate(0);
                &.cc-countryIconToggle-translate {
                    transform: rotate(180deg);
                }
            }
        }
    }
    &-overlay {
        width: 100%;
        left: 0;
        bottom: rem-calc(54);
        background-color: $off-white;
        position: absolute;
        z-index: 4;
        border-top: rem-calc(1) solid $cloud;
        padding: rem-calc(64 32 32 32);
        @include breakpoint(medium down) {
            bottom: rem-calc(0);
            z-index: 801;
            padding: rem-calc(16);
            border: none;
            position: fixed;
        }
        &-container {
            @include breakpoint(medium down) {
                padding: rem-calc(50 16 0 16);
                height: 71.92118226600985vh;
                overflow-y: scroll;
            }

            &_closeButton {
                @include increaseButtonSize();
                position: absolute;
                right: rem-calc(16);
                top: rem-calc(16);
                @include breakpoint(large up) {
                    top: rem-calc(64);
                    right: rem-calc(32);
                }

                &-icon {
                    width: rem-calc(16);
                    height: rem-calc(16);
                    @include breakpoint(large up) {
                        width: rem-calc(24);
                        height: rem-calc(24);
                    }
                }
            }

            &_title {
                margin-bottom: rem-calc(32);
                display: flex;
                align-items: center;
                @include breakpoint(medium down) {
                    justify-content: space-between;
                }
            }
            &_onlineStoreElement {
                margin-left: rem-calc(24);
                color: $fossil;
                font-size: rem-calc(10);
                align-items: center;
                &_image {
                    height: rem-calc(16);
                    width: rem-calc(16);
                    margin-left: rem-calc(6);
                }
            }
        }
        &-country {
            gap: rem-calc(32);
            @include breakpoint(medium down) {
                gap: rem-calc(24);
            }
            &_column {
                @include breakpoint(large up) {
                    &:not(:last-of-type) {
                        padding-right: rem-calc(32);
                        border-right: rem-calc(1) solid $cloud;
                    }
                }
                &_item {
                    font-size: rem-calc(12);
                    &:not(:last-of-type) {
                        margin-bottom: rem-calc(8);
                    }
                    @include breakpoint(medium down) {
                        &:not(:last-of-type) {
                            margin-bottom: rem-calc(24);
                        }
                    }
                    &_image {
                        float: right;
                        margin-top: rem-calc(5);
                    }
                }
            }
        }
        &-keepInTouch {
            border-top: rem-calc(1) solid $cloud;
            align-items: center;
            justify-content: space-between;
            @include breakpoint(large up) {
                padding: rem-calc(32 32 0);
                margin: rem-calc(32 -32 0);
            }
            @include breakpoint(medium down) {
                padding: rem-calc(32 0);
                border-top: rem-calc(1) solid $cloud;
                margin-top: rem-calc(32);
            }
            &_text {
                font-size: rem-calc(16);
                margin-bottom: 0;
            }
            &_panel {
                width: 20.625vw;
                @include breakpoint(medium down) {
                    width: 100%;
                }
                &_inputBox {
                    margin-bottom: 0;
                    &_input {
                        background: none;
                        border: 0;
                        border-bottom: rem-calc(1) solid rgba(0, 0, 0, 0.2);
                        margin: 0;
                        padding: rem-calc(0 0 12 0);
                        border-radius: 0;
                        @include breakpoint(medium down) {
                            margin-top: rem-calc(16);
                        }
                        &:focus {
                            border: none;
                            border-bottom: rem-calc(1) solid $charcoal;
                            background: none;
                        }
                    }
                }
                &_buttonArrow {
                    padding: 0;
                    position: absolute;
                    right: 0;
                    bottom: rem-calc(17);
                    svg {
                        width: rem-calc(16);
                        height: rem-calc(16);
                    }
                }
            }
        }
    }
    &-overlayLanguage {
        width: 16.04166666666667vw;
        left: rem-calc(-1);
        bottom: rem-calc(54);
        background-color: $off-white;
        position: absolute;
        z-index: 4;
        border-top: rem-calc(1) solid $cloud;
        border-left: rem-calc(1) solid $cloud;
        padding: rem-calc(32 16);
        &-container {
            &_text {
                margin-bottom: 0;
                @include breakpoint(medium down) {
                    margin: rem-calc(32 0 8 0);
                }
            }
            &_checkBox {
                margin-top: rem-calc(24);
                margin-bottom: 0;
                padding: 0;
                display: inline-flex;
                @include breakpoint(medium down) {
                    margin: rem-calc(16 0 8 0);
                }
                & input[type='checkbox']:checked ~ .checkbox-label {
                    line-height: 1;
                    border-bottom: rem-calc(1) solid $charcoal;
                    color: $charcoal;
                }
            }
            .cc-footer-overlay-container_closeButton {
                top: rem-calc(-32);
            }
        }
    }
    &-overlayBlur {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.75);
        bottom: 0;
        right: 0;
        left: 0;
        height: 100%;
        z-index: 800;
        display: none;
    }
    @include breakpoint(medium down) {
        &-backgroundOverlay {
            overflow-y: hidden;
            position: fixed;
            bottom: 0;
        }
    }
}
.localities-container {
    box-shadow: none !important;
    border: none !important;
}
.cc-footerSlim {
    padding: rem-calc(10);
    border-top: rem-calc(1) solid $cloud;
    margin-top: rem-calc(70);
    position: relative;
    @include breakpoint(medium down) {
        padding: rem-calc(8 16);
    }
    p {
        font-size: rem-calc(10);
        line-height: rem-calc(15);
        font-weight: 400;
        margin: 0;
    }
}

.newsletter-popup {
    .maxDim {
        @include breakpoint(large up) {
            height: 100%;
            .content-asset {
                height: 100%;
            }
        }
    }
}
#ae_launcher.ae-cta-position-preset-right-lower {
    @include breakpoint(medium down) {
        bottom: rem-calc(90) !important;
    }
}

.hide-error {
    visibility: hidden;
}