// SLICK ARROW CSS FOR ALL CAROUSEL EXCEPT PDP AND QUICKVIEW
.carousel-einstein-recently-viewed,
.hp-four-slider-tabs,
.hp-four-slider,
.hp-hero-banner-title-cta,
.js-hp-magnetic-hero-banner-title-cta,
.carousel-select-color,
.carousel-select-size,
.js-three-slider-arrow,
.js-three-slider-dots,
.js-four-slider-arrow,
.js-six-slider-arrow {

    .slick-prev,
    .slick-next {
        position: absolute;
        display: block;
        height: rem-calc(20);
        width: rem-calc(20);
        line-height: 0;
        font-size: 0;
        cursor: pointer;
        //background: black;
        //color: #000000;
        padding: 0;
        border: none;
        outline: none;

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: rem-calc(23);
            height: rem-calc(36);
            background-size: 50% 50%;
            background-repeat: no-repeat;
        }
    }

    .slick-prev {
        @include breakpoint (medium down) {
            left: rem-calc(-25);
            z-index: 500;
        }

        @include breakpoint (small down) {
            left: rem-calc(-25);
            z-index: 500;
        }

        &:before {
            background-image: url('../images/ico-arrow-left.svg');
            background-size: rem-calc(14);
            background-position: rem-calc(0 6);

        }
    }

    .slick-next {
        @include breakpoint (medium down) {
            right: rem-calc(-30);
            z-index: 500;
        }

        @include breakpoint (small down) {
            right: rem-calc(-30);
            z-index: 500;
            display: none;
        }

        &:before {
            background-image: url('../images/ico-arrow-right.svg');
            background-size: rem-calc(14);
            background-position: 8% 6px;
        }
    }

}

/* arrow for Carousel (no PDP and quickview) */

// GENERIC COLOR AND DIMENSION (ONLY)
.slick-dots {
    li {
        button {
            &:before {
                width: rem-calc(6);
                height: rem-calc(6);
                border: rem-calc(1) solid $more-light-gray;
                background: $more-light-gray;

            }
        }

        &.slick-active {
            button {
                &:before {
                    width: rem-calc(6);
                    height: rem-calc(6);
                    border: rem-calc(1) solid $black;
                    background: $black;

                }
            }
        }
    }
}

// GENERIC HREF FOR DIV
a {
    &.all-div-block {
        display: block;
        padding: 0;
        border: 0;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }
    }
}


.component3img3link3price {

    text-align: center;
    margin: 0 auto;
    width: 100%;
    text-align: center;

    @include breakpoint (small down) {
        width: 80%;
    }

    @include breakpoint (medium down) {
        width: 80%;
    }

    h3 {
        font-weight: 500;
        text-align: left;

        @include breakpoint (small down) {
            font-size: rem-calc(18);
        }
    }

    /*  .slick-dots {
        position: relative;
        bottom: rem-calc(16);
        margin-top: rem-calc(20);
    //  right: rem-calc(20);
        width: inherit;
        @include breakpoint (small down) {
            right: inherit;
            width: 100%;
            bottom: 0;
        }
        li  {
            button {
                &:before {
                    width: rem-calc(6);
                    height: rem-calc(6);
                    border: rem-calc(1) solid $more-light-gray;
                    background: $more-light-gray;
                    @include breakpoint (small down) {
                        border: rem-calc(1) solid $more-light-gray;
                        background: $more-light-gray;
                        opacity: 1;
                    }
                }
            }
            &.slick-active {
                button {
                    &:before {
                        width: rem-calc(6);
                        height: rem-calc(6);
                        border: rem-calc(1) solid $black;
                        background: $black;
                        @include breakpoint (small down) {
                            border: rem-calc(1) solid $black;
                            background: $black;
                            opacity: 1;
                        }
                        }
                }
            }


        }


    } */

    .three-slider-arrow {

        // width: 80%;
        .product {
            margin: rem-calc(18);

            @include breakpoint (small down) {
                margin: rem-calc(9);

            }

            .pdp-link {
                font-size: rem-calc(13);
                font-weight: 400;
                text-align: left;
                padding-top: rem-calc(4);
            }

            .price-container {
                font-size: rem-calc(13);
                font-weight: 500;
                text-align: left;
                padding-top: rem-calc(15);

            }
            /*
            .product-tile__strip {
                display: none;
            }*/

            .promo-message {
                height: rem-calc(21);
            }

        }
    }

    /*     .slick-list{ } */
}

//--------

@keyframes auto-progress {
    0% {
        width: 0%;
    }

    100% {
        width: 98%;
    }
}

.progress-bar {
    background-color: #1a1a1a;
    height: 1px;
    width: 450px;
    margin: 50px auto;
    border-radius: 5px;
    box-shadow: 0 1px 5px #000 inset, 0 1px 0 #444;
}


.progress-bar-inner {
    display: block;
    height: 4px;
    width: 0%;
    background-color: black;
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(255, 255, 255, .5) inset;
    position: relative;
    animation: auto-progress 10s infinite linear;
}

//------------


.slider-todays-promo {
    &.slick-dotted {
        .slick-dots {
            li {
                button {
                    &:before {
                        background: none;

                    }
                }
            }
        }
    }

    .slick-dots {
        display: table;
        width: 44%;
        position: absolute;
        bottom: auto !important;

        @include breakpoint (small down) {
            top: rem-calc(14.5);
        }
        @include breakpoint (medium only) {
            top: rem-calc(16.5);
            left: rem-calc(20);
        }
        @include breakpoint (large up) {
            top: rem-calc(26.5);
            left: rem-calc(30);
        }

        li {
            display: table-cell;

            button {
                width: 98% !important;
                height: rem-calc(1) !important;
                background: $black;

                &:before {
                    content: '';
                    width: 0 !important;
                    height: rem-calc(1);
                    border-radius: 0;
                    border: 0;
                    background: none;
                    opacity: 1;
                }
            }

            &.slick-active {
                button {
                    &:before {
                        content: '';
                        width: 98% !important;
                        height: rem-calc(3) !important;
                        border-radius: 0;
                        background: $black;
                        margin-top: 0;
                        animation: auto-progress 7s linear;
                    }
                }
            }
        }

        &.slick-dots-white {
            li {

                &.slick-active button:before {
                    background: $white;
                }

                button {
                    &:before {
                        background: $white;
                    }
                }
            }
        }
    }
    /* TEMPORIZZATORE */
}

//HOMEPAGE - Magnetic slider
.js-hp-magnetic-hero-banner-title-cta {
    position: relative;
    height: calc(100vh - #{$padding-top-page-home-gender-d});
    overflow: hidden;

    @include breakpoint (small down) {
        height: calc(100vh - #{$padding-top-page-home-gender-m});
        padding-bottom: rem-calc(30);
    }

    .slick-list {
        height: 100%;

        .slick-track {
            height: 100%;
            max-height: calc(100vh - #{$padding-top-page-home-gender-d});

            @include breakpoint (small down) {
                max-height: 100%;
            }

            .slick-slide {
                height: 100%;
                position: relative;
                overflow: hidden;
                border: none;

                .slider-content-wrapper{
                    position: relative;
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                    
                    .clickable-link {
                        z-index: 1;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: top;
                        }
                    }

                    .slider-center-content {
                        position: absolute;
                        left: 10%;
                        right: 10%;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        bottom: 80px;
                        background-color: transparent;
                        z-index: 2;

                        @include breakpoint (small down) {
                            left: 5%;
                            right: 5%;
                        }

                        @supports (-webkit-touch-callout: none) {
                            bottom: rem-calc(102);
                        }

                        @media screen and (width: $iPhone11-Pro_XR) {
                            bottom: 120px;
                        }

                        .slider-center-inner-content{
                            text-align: center;
                            margin-bottom: 44px;
                            width: 432px;
                            
                            @include breakpoint (small down) {
                                width: 100%;
                                margin-bottom: 0px;
                            }

                            .slider-cta-wrapper{
                                width: 100%;
                                display: flex;
                                justify-content: center;

                                @include breakpoint (small down) {
                                    flex-direction: column;
                                }
                            }
                        }

                        p {
                            font-size: 36px;
                            font-weight: 400;
                            line-height: 42px;
                            letter-spacing: 0.5;
                            margin: 0 auto;
                            text-align: center;
                            margin-bottom: 27px;

                            &.white {
                                color: $white;
                                font-weight: 600;
                            }

                            &.black {
                                color: $black;
                                font-weight: 600;
                            }

                            @include breakpoint (small down) {
                                font-size: 24px;
                                line-height: 30px;
                                margin-bottom: 6px;
                            }
                        }

                        .button {
                            font-size: 14px;
                            font-weight: 500;
                            text-transform: uppercase;
                            padding: 15px 44px;
                            text-transform: uppercase;
                            background-color: $white;
                            color: $black;
                            margin: 0 rem-calc(6);
                            border-radius: 2px;

                            @include breakpoint (small down) {
                                margin: 16px 0px;
                            }

                        }
                    }
                }
            }
        }
    }

    .slick-dots {
        position: absolute;
        bottom: rem-calc(30) !important;
        display: flex;
        flex-direction: column;
        width: auto;

        @media screen and (min-width:1025px) {
            top: 30vh;
            left: 96%;
        }
        @media screen and (max-width:1024px) {
            top: 25vh;
            left: 90%;
        }

        li {
            button {
                position: relative;
                padding: 0px;
                &:before {
                    position: absolute;
                    width: auto !important;
                    background: $white !important;
                    opacity: 1 !important;
                    left: 42%;
                    right: 42%;
                }
            }

            &.slick-active {
                button {
                    position: relative;
                    padding: 0px;
                    &:before {
                        position: absolute;
                        width: auto !important;
                        background: $white !important;
                        opacity: 1 !important;
                        left: 32%;
                        right: 32%;
                    }
                }
            }
        }
    }

    &:not(.slick-initialized){
        .slider-center-content {
            display: none;
       }
    }
}


// HOMEPAGE OLd
.hp-hero-banner-title-cta {
    height: calc(100vh - #{$padding-top-page-home-gender-d});

    @include breakpoint (small down) {
        height: calc(100vh - #{$padding-top-page-home-gender-m});
    }

    .slick-list {
        height: 100%;

        .slick-track {
            height: 100%;

            .slick-slide {
                height: 100%;
                position: relative;
                overflow: hidden;

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                    z-index: 1;
                }

                .slider-center-content {
                    position: absolute;
                    bottom: rem-calc(60);
                    text-align: center;
                    left: 20%;
                    right: 20%;
                    z-index: 2;

                    @include breakpoint (small down) {
                        left: 5%;
                        right: 5%;
                    }

                    p {
                        font-size: rem-calc(35);
                        font-weight: 500;
                        line-height: rem-calc(40);
                        letter-spacing: 0.5;
                        margin: 0 auto;
                        text-align: center;
                        padding-bottom: rem-calc(15);

                        &.white {
                            color: $white;
                            font-weight: 600;
                        }

                        @include breakpoint (small down) {
                            font-size: rem-calc(24);
                            color: $white;
                            line-height: rem-calc(26);
                        }
                    }

                    .button {
                        width: rem-calc(186);
                        font-size: rem-calc(14);
                        text-transform: uppercase;
                        padding: rem-calc(15);
                        line-height: rem-calc(20);
                        text-transform: uppercase;


                        @include breakpoint (small down) {
                            width: rem-calc(131);
                            height: rem-calc(40);
                            padding: rem-calc(10);
                        }

                    }
                }
            }
        }
    }

    .slick-dots {
        position: absolute;
        bottom: rem-calc(30) !important;

        li {
            button:before {
                background: $white !important;
                opacity: 1 !important;

            }

            &.slick-active button:before {
                background: $white !important;
                opacity: 1 !important;

            }
        }
    }
}


// HOMEPAGE 2
.hp-image-category-title-no-full-height {
    height: calc(90vh - #{$padding-top-page-home-gender-d});
    position: relative;

    @include breakpoint (small down) {
        height: calc(90vh - #{$padding-top-page-home-gender-m});

    }

    .content-wrapper {
        height: 100%;
        position: relative;
        overflow: hidden;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 1;
            object-position: top;
        }

        .wrapper-center-content {
            position: absolute;
            top: 45%;
            text-align: center;
            left: 20%;
            right: 20%;
            z-index: 2;

            @include breakpoint (small down) {
                left: 5%;
                right: 5%;
            }

            p {
                font-size: rem-calc(35);
                font-weight: 500;
                line-height: rem-calc(40);
                letter-spacing: 0.5;
                margin: 0 auto;
                text-align: center;
                padding: 0;

                &.white {
                    color: $white;
                    font-weight: 600;
                }

                @include breakpoint (small down) {
                    font-size: rem-calc(25);
                    color: $white;
                    line-height: rem-calc(28);
                }
            }

        }
    }
}

.hp-three-image-title {
    height: 100%;

    .content-wrapper {
        height: 100%;
        position: relative;
        padding-left: rem-calc(18);
        padding-right: rem-calc(18);

        @include breakpoint (small down) {
            height: calc(50vh - #{$padding-top-page-home-gender-m});
            padding-left: 0;
            padding-right: 0;
            margin-bottom: rem-calc(1);
        }

        img {
            width: 100%;
            height: 100%;
            z-index: 1;

            &:first-child {
                @include breakpoint(medium down) {
                    display: none;
                }
            }

            @include breakpoint (small down) {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: 100% 0;
                z-index: 1;
            }
        }

        .wrapper-center-content {
            position: absolute;
            top: 45%;
            text-align: center;
            left: 20%;
            right: 20%;
            z-index: 2;

            @include breakpoint (small down) {
                left: 5%;
                right: 5%;
            }

            p {
                font-size: rem-calc(25);
                font-weight: 500;
                line-height: rem-calc(28);
                letter-spacing: 0.5;
                margin: 0 auto;
                text-align: center;
                padding: 0;

                @include breakpoint (small down) {
                    font-size: rem-calc(25);
                    color: $white;
                    line-height: rem-calc(28);
                }

                &.white {
                    color: $white;
                    font-weight: 600;
                }
            }
        }
    }
}

.hp-two-block-img-title-cta {
    @include breakpoint (small down) {
        padding: 0;
    }

    .content-wrapper {
        &.add-margin-left {
            margin-right: rem-calc(18);

            @include breakpoint (small down) {
                margin-left: 0;
                margin-right: 0;
            }
        }

        &.add-margin-right {
            margin-left: rem-calc(18);

            @include breakpoint (small down) {
                margin-left: 0;
                margin-right: 0;
            }
        }

        .img-wrapper {
            height: auto;
            margin: 0 auto;
            text-align: center;

            @include breakpoint (small down) {
                min-height: rem-calc(195);
                height: auto;
                position: relative;
            }

            img {
                width: 100%;
                height: 100%;
                z-index: 1;

                @include breakpoint (small down) {
                    // position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: 100% 0;
                    z-index: 1;
                }
            }
        }

        .wrapper-center-content {
            text-align: center;
            position: relative;
            padding-top: rem-calc(15);
            margin: 0 auto;
            margin-bottom: rem-calc(30);
            max-width: rem-calc(402);
            width: auto;

            h1 {
                padding-bottom: rem-calc(15);
                margin-bottom: 0;

                @include breakpoint (small down) {
                    line-height: rem-calc(26);
                }
            }

            p {
                font-size: rem-calc(20);
                line-height: rem-calc(23);
                padding-bottom: rem-calc(23);
                margin-bottom: 0;
                font-weight: 500;

                @include breakpoint (small down) {
                    font-size: rem-calc(18);
                }
            }
        }
    }
}

.hp-four-slider-tabs {
    .wrapper-image {
        position: relative;
        margin: rem-calc(0 18);

        @include breakpoint (small down) {
            margin: rem-calc(0 4);
        }

        p {
            position: absolute;
            font-size: rem-calc(18);
            line-height: rem-calc(22);
            color: $white;
            bottom: rem-calc(18);
            left: rem-calc(20);
            right: rem-calc(16);
            font-weight: 500;

            @include breakpoint (small down) {
                font-size: rem-calc(15);
                left: rem-calc(10);
                right: rem-calc(10);
                line-height: rem-calc(20);
                margin-bottom: rem-calc(10);
                bottom: 0;
                padding-bottom: 0;
            }
        }
    }
}

.hp-tabs {
    @include breakpoint (small down) {
        padding: 0;
    }

    .container-tabs ul.tabs {
        margin-bottom: rem-calc(5);
        background-image: none;
        border-bottom: rem-calc(1) solid $border-table-gray;

        @include breakpoint (small down) {
            overflow-x: auto;
            white-space: nowrap;
        }

        li.current {
            border-bottom: rem-calc(2) solid $black;
        }
    }
}

.background-component-hp-four-slider {
    padding: rem-calc(30 0);
    display: flex;
    vertical-align: middle;
    flex-direction: column;
    justify-content: center;
    background: #F9F9F9;

    .title-home-component {
        font-size: rem-calc(30);
        padding-bottom: rem-calc(30);
        text-align: center;
    }

    .inner-price-container {
        display: flex;
        flex-direction: row;

        .sales {
            order: 2;
        }

        .strike-through {
            order: 1;
            margin-right: rem-calc(4);
        }
    }

    .price-container {
        .double-currency-clearfix{
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
        .double-currency-clearfix > .list{
            display: inline-block;
            margin-left: rem-calc(4);
        }
    }

}
.page[data-action="Cart-Show"] {
    .background-component-hp-four-slider-cart {
        padding-top: rem-calc(48);
        @include breakpoint(medium down) {
            padding-top: rem-calc(40);
        }
    }
}

.background-component-hp-four-slider-cart {
    padding-top: rem-calc(64);
    padding-right: rem-calc(166);
    padding-left: rem-calc(166);
    display: flex;
    vertical-align: middle;
    flex-direction: column;
    justify-content: center;
    background: transparent;

    @include breakpoint (small down) {
        padding-right: rem-calc(28);
        padding-left: rem-calc(28);
    }

    .title-component-four-product {
        font-size: rem-calc(20);
        padding-bottom: rem-calc(20);
        text-align: center;
        @include breakpoint (small down) {
            overflow: hidden;
        }
    }
}

.hp-four-slider {
    .slick-list {
        @include breakpoint (large up) {
            margin: rem-calc(0 50);
        }
    }

    .slick-prev {
        left: rem-calc(25);
    }

    .slick-next {
        right: rem-calc(25);
    }

    .wrapper-image {
        margin: rem-calc(0 18);

        @include breakpoint (small down) {
            margin: rem-calc(0 4);
        }

        .pdp-link,
        .price-container {
            font-weight: 400;
        }

        .pdp-link {
            margin-top: rem-calc(5);
            font-weight: 400;
            height: rem-calc(40);
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

a {
    &.product-tile {
        outline: 0;
    }
}