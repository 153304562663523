.titleMiniCart {
    font-size: rem-calc(14);
    margin-bottom: rem-calc(6) !important;
}

.cc-mainHeader-minicart {
    position: relative;
    display: inline-block;
    @include breakpoint(medium down) {
        &.open {
            position: fixed;
            display: block;
            width: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 3000;
            h6 {
                font-weight: $global-weight-dark;
            }
        }
    }

    .closeButton {
        position: absolute;
        right: rem-calc(17);
        top: rem-calc(3);
        font-size: rem-calc(23);
        font-weight: 400;
        z-index: 6;
    }
    .wishlist-minicart {
        width: 91.4666vw;
        border-radius: rem-calc(4);
        @include breakpoint(large up) {
            width: max-content;
        }
        .closeButton {
            right: rem-calc(8);
            width: rem-calc(16);
            height: rem-calc(16);
        }
        .cc-titleMiniCart {
            text-align: center;
            background: $off-white;
            padding: rem-calc(8 0);
            border-radius: rem-calc(4);
            &-title {
                margin: 0;
            }
        }
        .card-body {
            padding: rem-calc(16);
        }
        .cc-miniWishlist {
            gap: 16px;
            flex-flow: row nowrap;
            &-image {
                aspect-ratio: 0.67;
                width: 9.4086021vw;
                @include breakpoint(large up) {
                    width: 2.4305vw;
                }
                .product-image {
                    height: 100%;
                    border-radius: rem-calc(4);
                }
            }
            &-productText {
                flex-flow: column;
                justify-content: center;
                &_title {
                    line-height: rem-calc(21);
                }
                &_title,
                &_size {
                    margin: 0;
                }
            }
            &-icon {
                position: relative;
                width: rem-calc(24);
                height: rem-calc(24);
                align-self: center;
            }
        }
    }

    .promo {
        margin-left: rem-calc(-10);
        margin-right: rem-calc(-10);
        margin-top: rem-calc(-10);
        text-align: left;
        padding-left: rem-calc(20);
        padding-top: rem-calc(17);
        padding-bottom: rem-calc(12);
        font-size: rem-calc(12);
        padding-right: rem-calc(30);
    }

    .cart.approaching {
        &::before {
            border-bottom-color: transparent;
        }

        &::after {
            border-bottom-color: #d2def5;
        }

        .promo {
            background: #d2def5;
        }
    }
    .cart.promoapplicable {
        &::before {
            border-bottom-color: transparent;
        }

        &::after {
            border-bottom-color: #f5d2d3;
        }

        .promo {
            background: #f5d2d3;
        }
    }
    .cart.promoapplied {
        &::before {
            border-bottom-color: transparent;
        }
        &::after {
            border-bottom-color: $success;
        }

        .promo {
            background: $success;
        }
    }
    .cart {
        background-color: $white;
        box-shadow: $light-box-shadow;
        position: relative;

        &:after,
        &:before {
            bottom: 100%;
            border: solid transparent;
            content: ' ';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        &:after {
            right: 18px;
            border-color: transparent;
            border-bottom-color: $white;
            border-width: rem-calc(10);
            margin-left: rem-calc(-10);
        }

        &:before {
            right: 17px;
            border-color: transparent;
            border-bottom-color: $border-gray;
            border-width: rem-calc(11);
            margin-left: rem-calc(-11);
        }

        .banner-add-bonus {
            margin-left: rem-calc(-10);
            margin-right: rem-calc(-10);
            margin-top: rem-calc(-10);
            text-align: left;
            padding-left: rem-calc(20);
            padding-top: rem-calc(11);
            padding-bottom: rem-calc(11);
            padding-right: rem-calc(34);
            font-size: rem-calc(12);
            line-height: rem-calc(12);
            color: $black;
            background-color: $success;
        }
        .banner-no-add-bonus {
            margin-left: rem-calc(-10);
            margin-right: rem-calc(-10);
            margin-top: rem-calc(-10);
            text-align: left;
            padding-left: rem-calc(20);
            padding-top: rem-calc(17);
            padding-bottom: rem-calc(12);
            font-size: rem-calc(12);
            color: $alert;
            background-color: $alert-light;
        }
    }

    .int-minicart-cross-box {
        background-color: #f2f0eb;
        padding: rem-calc(19 60);
        @include breakpoint(medium down) {
            padding: rem-calc(19 42);
        }
        .miniCartCrossSellContainer-header {
            text-align: center;
            font-size: rem-calc(14);
            font-weight: 500;
            margin-bottom: rem-calc(19);
        }
        .miniCartCrossSellContainer-carousel {
            display: flex;
            flex-direction: row;
            justify-content: center;
            .product {
                margin-right: rem-calc(18);
                max-width: 33%;
                width: 33%;
                text-align: left;
                &:last-of-type {
                    margin-right: 0;
                }
                .tile-body {
                    margin-top: rem-calc(2);
                    .promo-message {
                        display: none !important;
                    }
                }
                .product-tile__name {
                    .pdp-link {
                        text-align: left;
                        line-height: rem-calc(18);
                        max-height: rem-calc(55);
                        overflow: hidden;
                        .link {
                            font-size: rem-calc(12);
                            font-weight: 400;
                        }
                    }
                }
                .price-container {
                    text-align: left;
                    .price {
                        span {
                            font-size: rem-calc(14);
                            font-weight: 500;
                        }
                        .js-discount-msg {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .wishlist-minicart {
        &:after {
            display: none;
        }

        &:before {
            display: none;
        }
    }
    .text-attributes-product-mini {
        margin: 0;
        margin-bottom: rem-calc(2);
    }
    .line-item-product-name {
        line-height: rem-calc(20);
    }

    .card-body {
        padding-top: rem-calc(9);
        padding-bottom: rem-calc(15);
        padding-right: rem-calc(15);
        padding-left: 0;
    }
    @include breakpoint(medium down) {
        .only-border-bottom.lighter {
            border: none;
        }
    }
    .popover {
        position: absolute;
        top: rem-calc(53);
        left: auto;
        right: rem-calc(-18);
        display: none;
        z-index: 100;
        box-shadow: rem-calc(0 8.571043968200684 17.142087936401367 0) rgba(0, 0, 0, 0.15);
        @include breakpoint(medium up) {
            max-width: rem-calc(30.44rem);
            min-height: rem-calc(4.25rem);
            &:before {
                left: auto;
                border-left: rem-calc(8) solid transparent;
                border-right: rem-calc(8) solid transparent;
                border-bottom: rem-calc(10) solid $white;

                position: relative;
                right: rem-calc(10);
                bottom: rem-calc(10);
            }
        }
        @include breakpoint(medium down) {
            left: rem-calc(16);
            right: unset;
            &.show {
                position: relative;
                display: block;
                width: 100%;
                min-width: 100%;
                max-width: 100%;
                min-height: rem-calc(20);
                height: 100vh;
                margin: 0;
                overflow-y: scroll;
                z-index: 3000;
                box-shadow: $shadow-default;
            }
        }
        &.show {
            display: block;
            height: auto;
            overflow-y: auto;
        }
        .card {
            border: none !important;
            margin-bottom: 0;
        }
    }
    .estimated-total {
        margin-top: rem-calc(0);
        padding: rem-calc(0 10);
        font-size: rem-calc(16);
        @include breakpoint(medium down) {
            display: none;
        }
        .double-currency-margin {
            margin-bottom: 0;
        }

        .grand-total-double-currency {
            font-size: rem-calc(12);
            line-height: rem-calc(18);
        }
        .grand-total-convertion-rate {
            overflow: hidden;
            font-size: rem-calc(12);
            line-height: rem-calc(18);
        }
    }
    .button-grid-minicart {
        padding: rem-calc(0 10);
    }
    .checkoutButtons {
        a,
        button {
            width: 100%;
            min-width: 100%;
            margin: 0;
        }
    }
    &.custom-right-fix {
        right: rem-calc(50);
        .popover {
            right: 0;
            .text-attributes-product-mini {
                font-size: rem-calc(14);
                line-height: rem-calc(20);
                span.small {
                    font-size: rem-calc(14);

                    #item-with-img {
                        font-size: rem-calc(14);
                        img {
                            width: rem-calc(14) !important;
                            height: rem-calc(14) !important;
                            vertical-align: text-top;
                        }
                    }
                }
                #miniCartImage {
                    vertical-align: text-top;
                }
                .customize__icon {
                    img {
                        width: rem-calc(16) !important;
                        height: rem-calc(16) !important;
                        vertical-align: text-top;
                    }
                }
            }
        }
    }
}
.product-line-item {
    .minicart-card-image {
        padding-right: rem-calc(20);
        .product-image {
            width: 111px;
        }
    }
    .notification-check-add {
        letter-spacing: rem-calc(0.5);
    }
}

.layer-minicart-modal {
    z-index: 2006;
}

.layer-bonus-modal {
    z-index: 2100;

    .close-button-info {
        position: absolute;
        right: rem-calc(16);
        @include breakpoint (large up) {
            right: rem-calc(32);
        }
    }

    .tingle-modal-box {
        @include breakpoint (small down) {
            height: 75vh;

            .tingle-modal-box__content {
                overflow-x: hidden;
            }
        }

        &.minicart-style {
            @include breakpoint (large up) {
                width: rem-calc(382);
            }
        }
    }
    .cc-pendingBonusProductModal-image {
        margin-bottom: rem-calc(24);
        margin-top: rem-calc(40);

        @include breakpoint (large up) {
            margin-top: rem-calc(32);
        }

        img {
            height: rem-calc(222);
            width: rem-calc(150);
            @include breakpoint (large up) {
                height: rem-calc(250);
                width: rem-calc(169);
            }
        }
    }
    .cc-pendingBonusProductModal-title {
        font-size: rem-calc(18);
        line-height: 135%;
        margin-bottom: rem-calc(24);
        @include breakpoint (large up) {
            font-size: rem-calc(24);
            line-height: 130%;
        }
    }
    .cc-pendingBonusProductModal-subtitle {
        font-size: rem-calc(14);
        line-height: 150%;
        letter-spacing: rem-calc(0.56);
        margin-bottom: rem-calc(40);
    }
    .cc-pendingBonusProductModal-buttonsContainer {
        border-top: rem-calc(1) solid $cloud;
        bottom: 0;
        display: flex;
        flex-direction: column;
        gap: rem-calc(10);
        left: 0;
        padding: rem-calc(16 0);
        position: relative;
        width: 100%;
        @include breakpoint (large up) {
            left: unset;
            position: fixed;
            width: calc(100% - 4rem);
        }
    }
}

.layer-complete-your-promo-modal {
    z-index: $zIndex-layerAboveRightModal;
}

.cc-low-z-index {
    z-index: 2007;
}

.cc-productPromo {
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    border-radius: rem-calc(4);
    padding: rem-calc(16);
    margin-top: rem-calc(32);
    & + & {
        margin-top: rem-calc(16);
    }
    @include breakpoint(large up) {
        width: 100%;
    }
    &-textWrapper {
        flex: 1;
        padding-right: rem-calc(8);
        &_text {
            color: $charcoal;
            margin: 0;
        }
        &_name {
            margin-bottom: rem-calc(8);
            .cc-womanSite & {
                background: $blush;
            }
            .cc-manSite & {
                background: $rust;
            }
        }
    }
    &-completeYourPromo {
        &-link {
            border: rem-calc(1) solid $cloud;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: rem-calc(4);
        }
    }
}
.suggestions-container {
    margin-top: rem-calc(32);
    padding-bottom: rem-calc(48);
    @include breakpoint(large up) {
        margin-top: rem-calc(24);
        padding-bottom: rem-calc(40);
    }
    .slick-track {
        margin-left: rem-calc(-4);
    }
    .slick-list {
        margin-right: rem-calc(-16);
        @include breakpoint(large up) {
            margin-right: rem-calc(-32);
        }
    }
    .cc-minicart-suggestedForYou {
        &_title {
            margin-bottom: rem-calc(16);
            font-size: rem-calc(getFontSize('captionS'));
            line-height: rem-calc(getLineHeight('captionS'));
            letter-spacing: rem-calc(getLetterSpacing('captionS'));
        }
        &-content {
            margin: 0 rem-calc(4);
            .cc-prodTile {
                &-badge,
                &-colors,
                &-isGreen,
                &-imageContainer-label {
                    display: none;
                }

                &-name {
                    display: block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-right: rem-calc(4);
                }
            }

            .cc-isOmnibusActive {
                gap: 0;
                display: grid;
            }

            .cc-omnibusStack {
                margin-top: rem-calc(4);
                &-sales {
                    display: none;
                }
                &-item {
                    &_regularPrice {
                        display: none;
                    }
                }
            }
        }
    }
}
.cc-bonusProduct {
    border: rem-calc(1) solid $cloud;
    border-radius: rem-calc(4);
    padding: rem-calc(16);
    gap: rem-calc(16);
    align-items: center;
    margin-bottom: rem-calc(0);
    margin-top: rem-calc(24);
    cursor: pointer;
    
    &_image {
        .product-image {
            aspect-ratio: 0.67;
            display: block;
            object-fit: cover;
            width: 14.4vw;
            @include breakpoint(large up) {
                width: 3.75vw;
            }
        }
    }
    &_text {
        flex: 1;
        &-text {
            &-description {
                color: $fossil;
            }
            p {
                margin: 0;
            }
        }
    }
    &_button {
        &-wrapper {
            position: relative;
            display: block;
            width: rem-calc(24);
            height: rem-calc(24);
            background-color: $white;
            border: rem-calc(1) solid $cloud;
            border-radius: rem-calc(200);
            &-icon {
                position: absolute;
                width: rem-calc(12);
                height: rem-calc(12);
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    &Modal {
        &_image {
            .product-image {
                aspect-ratio: 0.67;
                display: block;
                object-fit: cover;
                width: 12.708333vw;
                border-radius: rem-calc(4);
            }
        }
    }
}
.cc-personalizeDetails {
    display: flex;
    align-items: center;
    gap: rem-calc(4);
    .cc-cart & {
        gap: rem-calc(8);
    }
}
.cc-minicart {
    .tingle-modal-box__content {
        flex-grow: 1;
        @include breakpoint(large up) {
            padding: rem-calc(48 32 0);
        }
        padding: rem-calc(16 16 0);
    }
    .container {
        display: flex;
        flex-direction: column;
        height: 100%;

        .cc-bonusProduct {
            margin-bottom: rem-calc(16);
            margin-top: rem-calc(0);
            @include breakpoint (large up) {
                margin-top: rem-calc(-32);
            }
        }

        .cc-bonusProduct_image {
            .product-image {
                border-radius: rem-calc(4);
                height: rem-calc(75);
                width: rem-calc(50);
            }
        }
    }
    &Overflow {
        flex: 1 1 0;
    }
    &-closeIcon {
        position: absolute;
        right: rem-calc(16);
        top: rem-calc(16);
        line-height: 0;

        @include breakpoint(large up) {
            &-icon {
                width: rem-calc(16);
                height: rem-calc(16);
            }
        }
    }
    &-title {
        display: flex;
        
        margin-bottom: 0;
        align-items: center;
        margin-top: rem-calc(32);
        @include breakpoint(large up) {
            margin-top: 0;
        }
        &_icon {
            position: relative;
            display: block;
            width: rem-calc(20);
            height: rem-calc(20);
            border-radius: rem-calc(20);
            background-color: $charcoal;
            &-content {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    &-totalItems {
        color: $charcoal;
        margin-left: rem-calc(4);
        margin-top: rem-calc(-16);
    }
    &-product {
        &-price {
            order: 2;

            .cc-cartPrice {
                font-size: rem-calc(getFontSize('micro'));
                line-height: rem-calc(getLineHeight('micro'));
                letter-spacing: rem-calc(getLetterSpacing('micro'));
            }

            .cc-cartPrice_percentage {
                display: none;
            }

            .cc-loyaltyPrice {
                display: none;
            }
        }
    }
    &-productWrapper {
        margin-bottom: rem-calc(16);
        position: relative;
        &-left {
            display: flex;
            align-items: flex-start;
            gap: rem-calc(16);
        }
        &-image {
            width: rem-calc(50);
            border-radius: rem-calc(4);
            flex: none;
        }
        &-productInfo {
            p {
                margin: 0;

                &.cc-productName {
                    @include truncateRow(1);
                    max-width: rem-calc(200);
                }
            }
            .item-attributes,
            .line-item-price-info {
                color: $fossil;
            }
        }
        &-delete {
            padding: rem-calc(5);
            border-radius: rem-calc(200);
            border: rem-calc(1) solid $cloud;
            line-height: 0;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }
        &-container {
            display: flex;
            flex-flow: row nowrap;
            column-gap: rem-calc(16);
            align-items: center;
            justify-content: space-between;

            &_addToCart {
                color: $charcoal;
                margin: rem-calc(8 0 0);
            }
        }
    }
    &-loyaltyMessage {
        display: flex;
        column-gap: rem-calc(8);
        margin: rem-calc(4 0 0 24);
        &_content {
            p {
                margin: 0;
            }
        }
    }
    
    &_checkoutButtons {
        display: flex;
        flex-direction: column;
        row-gap: rem-calc(8);
        border-top: rem-calc(1) solid $cloud;
        padding: rem-calc(16);
        position: sticky;
        bottom: 0;
        background-color: $white;
        width: calc(100% + #{rem-calc(32)});
        margin: auto rem-calc(-16 0);
        @include breakpoint(large up) {
            padding: rem-calc(16 32);
            width: calc(100% + #{rem-calc(64)});
            margin: auto rem-calc(-32 0);
        }
        &_orderTotal,
        &_goToBag {
            width: 100%;
            a {
                width: 100%;
                margin: 0;
            }
        }
        &_orderTotal {
            &-content {
                p {
                    margin: 0;
                }
            }
            &_account {
                text-decoration: underline;
            }
        }
        &_checkoutButtons {
            display: flex;
            flex-direction: column;
            row-gap: rem-calc(8);
            border-top: rem-calc(1) solid $cloud;
            padding: rem-calc(16 32 16 16);
            position: sticky;
            bottom: 0;
            background-color: $white;
            width: 100vw;
            margin-left: rem-calc(-16);
            @include breakpoint(large up) {
                width: calc(100% + #{rem-calc(78)});
                margin-left: rem-calc(-32);
            }
            &_orderTotal,
            &_goToBag {
                width: 100%;
                a {
                    width: 100%;
                    margin: 0;
                }
            }
            &_orderTotal {
                &-content {
                    p {
                        margin: 0;
                    }
                }
            }
        }
        &-product {
            display: flex;
            align-items: baseline;
            column-gap: rem-calc(8);
        }
    }
    &-product {
        display: flex;
        align-items: baseline;
        column-gap: rem-calc(8);
        flex-wrap: wrap;
    }
    .cc-bonusProductCard {
        .cc-minicart-productWrapper-container_productInfo {
            flex: 0.9;
            @include breakpoint(large up) {
                flex: 0.7;
            }
        }
    }
    .cc-minicart-productWrapper-first-icon {
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 1.5rem;
        background-color: $charcoal;
        border: 0;
        padding: 0;
    }
    .cc-cart-prodSummary {
        margin-top: rem-calc(24);
        &-title {
            margin-bottom: rem-calc(16);
        }
    }
    .cc-cart-promoContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        @include breakpoint(large up) {
            padding: 0;
        }

        &-progressBar {
            background-color: $cloud;
            height: rem-calc(3);
            border-radius: rem-calc(5);
            margin-top: rem-calc(16);
            margin-bottom: rem-calc(16);
            position: relative;
            &-currentProgress {
                background-color: $mocha;
                height: rem-calc(5);
                transition: width 0.3s ease-in-out;
                position: absolute;
                top: rem-calc(-1);
                border-radius: rem-calc(5);
                max-width: 100%;
            }
        }
        &-transportIcon {
            width: rem-calc(16);
            height: rem-calc(16);
            display: block;
            margin-right: rem-calc(8);
            content: '';
            background-size: contain;
        }
        &-discountContent {
            margin: 0;
        }
        &_shippingTitle {
            display: flex;
            gap: rem-calc(8);

            .cc-cart-promoContainer-title {
                display: none;
            }
        }
        &-item.reached-discounts {
            i {
                display: none;
            }
    
            .cc-cart-promoContainer-discountContent, p {
                color: $mocha;
            }
        }
    }

    &_loyaltyTracker {
        .cc-cart-promoContainer-progressBar-currentProgress {
            background-color: $dijon;
        }
    }

    .cc-productWrapper {
        margin-top: rem-calc(16);
        margin-bottom: rem-calc(16);
        &:first-of-type {
            margin-top: rem-calc(0);
        }
    }
    .cc-cartEmpty {
        margin-top: rem-calc(32);
        @include breakpoint(large up) {
            margin-top: 0;
        }
        &_title {
            justify-content: center;
            row-gap: rem-calc(16);
            margin-bottom: rem-calc(16);
            text-align: center;
            padding: rem-calc(0 32);
            &-icon {
                display: block;
            }
            &-header {
                font-size: rem-calc(getFontSize('textL'));
                line-height: rem-calc(getLineHeight('textL'));
                letter-spacing: rem-calc(getLetterSpacing('textL'));
            }
        }
        &_buttons {
            row-gap: rem-calc(8);

            .cc-goToWishlist {
                margin-bottom: rem-calc(32);
            }
        }
        &_title,
        &_buttons {
            display: flex;
            flex-direction: column;
        }

        .cc-slotCarousel {
            margin-bottom: 0;
            &-title {
                margin-bottom: rem-calc(16);
                font-size: rem-calc(getFontSize('captionS'));
                line-height: rem-calc(getLineHeight('captionS'));
                letter-spacing: rem-calc(getLetterSpacing('captionS'));
                font-family: $body-font-family;
                width: 100%;
            }
            &-carouselContainer {
                padding-left: 0;
                margin-bottom: rem-calc(48);
                width: 100%;
            }
            &-carousel {
                margin-left: 0;
                padding-left: 0;
                overflow-x: unset;
                .slick-list {
                    margin-right: rem-calc(-16);
                    padding-right: rem-calc(24);
                    @include breakpoint(large up) {
                        margin-right: rem-calc(-32);
                    }
                }
            }
            .cc-prodTile {
                margin: rem-calc(0 4);
                min-width: unset;
                &-name {
                    display: block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-right: rem-calc(4);
                }
                &-colors,
                &-badge,
                &-quickAdd-button {
                    display: none;
                }
                &-body {
                    margin-top: rem-calc(8);
                }
            }
        }
    }
}
.container-complete-your-promo-modal {
    position: absolute;
    right: 0;
    flex-direction: column;
    @include breakpoint(large up) {
        top: 0;
        max-width: 38vw;
        max-height: 100vh;
        bottom: unset;
    }
    bottom: 0;
    max-height: 75vh;
    max-width: 100vw;
    height: 100%;
    width: 100%;
    .tingle-modal-box__content {
        padding: 0;
        .content-title {
            font-size: rem-calc(20);
            font-weight: 500;
            line-height: rem-calc(26);
            letter-spacing: rem-calc(0.4);
            margin-bottom: rem-calc(16);
        }
        .close {
            top: rem-calc(20);
            right: rem-calc(20);
            @include breakpoint(medium down) {
                font-size: rem-calc(20);
                position: unset;
                img {
                    position: absolute;
                    top: rem-calc(20);
                    right: rem-calc(20);
                }
            }
        }
        .modal-description {
            letter-spacing: rem-calc(0.28);
            line-height: rem-calc(20);
        }
        .recap-methods-close {
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            letter-spacing: rem-calc(0.24);
        }
        ul {
            margin: 0;
            &.dashed {
                list-style-type: none;
                & > li {
                    margin-bottom: rem-calc(14);
                    letter-spacing: rem-calc(0.24);
                    line-height: rem-calc(18);
                    &:last-of-type {
                        margin-bottom: rem-calc(24);
                    }
                }
            }
        }
    }
    .cc-completeYourPromo {
        position: relative;

        &-closeButton {
            @include increaseButtonSize();
            line-height: 0;
            position: absolute;
            right: rem-calc(16);
            top: rem-calc(16);

            @include breakpoint(large up) {
                right: rem-calc(18);
                top: rem-calc(18);
            }

            &-icon {
                height: rem-calc(16);
                width: rem-calc(16);
                @include breakpoint(large up) {
                    height: rem-calc(24);
                    width: rem-calc(24);
                }
            }
        }

        &-back {
            cursor: pointer;
        }

        &-header {
            margin-bottom: rem-calc(32);
            &_promoName {
                @include flexGenerator(row, space-between, center);
                margin-bottom: rem-calc(16);
            }

            &-title {
                flex: 1 1 0;
                display: flex;
                gap: rem-calc(8);
            }

            &-link {
                flex: 0 0 auto;
            }

            .marketing-budge {
                .cc-womanSite & {
                    background-color: $promo;
                }
                .cc-manSite & {
                    background-color: $rust;
                }
            }
        }
        &-productCards {
            display: flex;
            flex-flow: column;
            gap: rem-calc(8);
            .crosssell-view {
                position: relative;
                .cc-prodTile {
                    flex-direction: row;
                    border: rem-calc(1) solid $cloud;
                    border-radius: rem-calc(4);
                    @include breakpoint(large up) {
                        border: none;
                        flex-direction: column;
                    }
                    &-body {
                        flex: 1 1 0;
                        @include breakpoint(large up) {
                            flex: none;
                        }
                    }
                    &-badge,
                    &-colors {
                        display: none;
                    }
                    .price-container {
                        text-align: left;
                    }
                    &-imageContainer {
                        &-image {
                            display: block;
                            object-fit: cover;
                            @include breakpoint(medium down) {
                                border-radius: rem-calc(4 0 0 4);
                            }
                        }
                        @include breakpoint(medium down) {
                            position: unset;
                            width: 21.3333vw;
                        }
                    }
                    &-quickAdd-button {
                        right: rem-calc(16);
                        bottom: rem-calc(16);
                        @include breakpoint(medium down) {
                            border: rem-calc(1) solid $cloud;
                        }
                    }
                    &-quickAdd-modal {
                        &-container {
                            height: 100%;
                            row-gap: rem-calc(24);
                        }
                    }
                }
            }
        }
        &-showAllMobile {
            margin: rem-calc(32 0 16);
            @include breakpoint(medium down) {
                margin-bottom: 0;
            }
        }
    }
}
.veiled-for-small-only_higher {
    .cc-prodTile-quickAdd-modal-back {
        display: block;
    }
}
.cc-quick-buy-sizes {
    flex: 20%;
    &:last-of-type {
        flex-grow: 0;
    }
}

.cc-colorEditModal,
.cc-sizeEditModal {
    padding: rem-calc(16);
    position: absolute;
    height: auto;
    overflow-y: auto;
    background-color: $white;
    box-shadow: rgba(0, 0, 0, 0.1) rem-calc(0 2 8 0);
    border-radius: rem-calc(4);
    z-index: 10;
    border: rem-calc(1) solid $cloud;
    width: 100%;
    &-colorDropdown {
        list-style-type: none;
        margin: 0;

        .inner-price-container span {
            /* Could not used typography since same template is shared by multiple different sections */
            font-size: rem-calc(12);
            line-height: rem-calc(16);
        }
    }
    &-soldOut {
        line-height: 0;
        margin-left: auto;
    }
    &-variationColor {
        .cc-quickUpdateModal-color-swatches {
            display: flex;
            flex-direction: row;
            gap: rem-calc(8);
        }
        .js-color-variation {
            display: flex;
            align-items: center;

            .color-option-span {
                width: rem-calc(44);
                height: rem-calc(44);
                display: inline-flex;
                align-items: center;
                justify-content: center;
                @include breakpoint(large up) {
                    width: rem-calc(32);
                    height: rem-calc(32);
                }
            }
        }
        .js-color-variation[class~='selected'] {
            .swatch-circle {
                outline: rem-calc(1) solid $charcoal;
                outline-offset: rem-calc(4);
                @include breakpoint(large up) {
                    outline-offset: rem-calc(2);
                }
            }
        }
        &-swatches {
            border-radius: 50%;
            width: rem-calc(32);
            height: rem-calc(32);
            background-size: cover;
            @include breakpoint(large up) {
                width: rem-calc(24);
                height: rem-calc(24);
            }
        }
        &_colorName {
            margin-left: rem-calc(10);
            @include truncateRow(1);
            width: rem-calc(158);

            .cc-bonusProductModal-content & {
                display: none;
            }
        }
    }
}
.cc-colorEditModal {
    width: rem-calc(247);
    @include breakpoint(large up) {
        padding: rem-calc(16 0);
    }
    &-notAvailableSize {
        display: flex;
        column-gap: rem-calc(4);
        background-color: $lemon;
        width: fit-content;
        margin-top: rem-calc(4);
        text-transform: none;
        @include breakpoint(large up) {
            margin-top: 0;
        }

        &-container {
            @include breakpoint(large up) {
                margin-top: rem-calc(16);
                padding: rem-calc(16 10 6);
                border-top: rem-calc(1) solid $cloud;
            }
        }
    }
    &-variationColor {
        @include breakpoint(large up) {
            padding: rem-calc(0);
            &_sales {
                border-top: rem-calc(1) solid $cloud;
                padding-top: rem-calc(16);
                margin-top: rem-calc(16);
            }
        }
    }
}

.cc-horizontal-splitter {
    margin-top: rem-calc(17);
    width: 100%;
}

.cc-quickUpdateModal {
    &-closeIcon {
        @include increaseButtonSize();
        display: block;
        line-height: 0;

        @include breakpoint(large up) {
            display: none;
        }
    }

    .tingle-modal-box {
        &__content {
            padding: rem-calc(48 16 16);
        }
    }

    .color-option {
        @include breakpoint(medium down) {
            width: rem-calc(44);
            height: rem-calc(44);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .cc-colorContainer {
        padding-bottom: rem-calc(32);
    }

    & &-back {
        margin-bottom: rem-calc(24);
        height: rem-calc(24);
    }

    & &-colorLabels {
        margin-bottom: rem-calc(24);
    }

    & &-color {
        display: block;
        margin-bottom: rem-calc(4);

        &-wrapper {
            gap: rem-calc(8);

            &:not(:first-child) {
                margin-top: rem-calc(24);
            }

            .cc-intProductDetail-priceWrapper {
                margin-bottom: 0;
            }
        }

        &-swatches {
            display: flex;
            flex-flow: wrap;
            gap: rem-calc(8);
        }
    }

    & &-colorName {
        display: none;
    }

    & &-sizeWrapper {
        margin: 0;
        display: flex;
        flex-flow: wrap;
        gap: rem-calc(6 8);
        margin-bottom: rem-calc(24);

        &-title {
            margin-bottom: rem-calc(24);
        }

        &-btn {
            width: calc(25% - (#{rem-calc(24)} / 4));
            flex: none;
            margin-bottom: 0;
            opacity: 1;
            border-color: $cloud;

            &.disabled {
                border-color: $cloud;
                .cc-quickUpdateModal-sizeWrapper-btn-link {
                    opacity: 1;
                    color: $fossil;
                }
            }

            &-label {
                display: flex;
                align-items: center;
                gap: rem-calc(2);

                .cc-alertNotify {
                    line-height: 0;
                }
            }

            &_selected a {
                color: $white;
            }
        }
    }

    .cc-hideInQuickUpdate,
    .cc-colorSoldOut {
        display: none;
    }
}

.cc-color-line-item {
    
    .color-option-span {
        @include breakpoint(large up) {
            display: flex;
        }
    }
}

.cc-bonusProductModal {
    z-index: $zIndex-layerAboveRightModal;
    &-title {
        display: none;
    }

    &-content {
        display: flex;
        flex-direction: column;
        height: 100%;

        &-grid {
            flex: 1 1 0;
            padding-bottom: rem-calc(40);

            .cc-productWrapper-image-container {
                margin-top: rem-calc(40);

                @include breakpoint (large up) {
                    margin-top: rem-calc(16);
                }

                img {
                    height: rem-calc(222);
                    width: rem-calc(150);
                    border-radius: rem-calc(4);
                    @include breakpoint (large up) {
                        height: rem-calc(250);
                        width: rem-calc(169);
                    }
                }
            }

            .cc-sizeEditModal-dropdownMenu {
                padding-bottom: rem-calc(16);

                .cc-quickUpdateModal-sizeWrapper-btn {
                    height: rem-calc(40);
                    margin-bottom: rem-calc(0);
                    width: calc((100% - (#{rem-calc(18)})) / var(--lines, 4));

                    &.disabled {
                        border-color: $cloud;
                        a {
                            color: $fossil;
                            opacity: 1;

                            .cc-alertNotify {
                                display: none;
                            }
                        }
                    }
                }
            }

            .cc-bonusProductModal-promo {
                display: none;
            }
        }

        &_column {
            flex-direction: column;
        }
    }

    .cc-variationAttributeTitle {
        margin-bottom: rem-calc(8);
        color: $fossil;
        display: block;
    }

    .cc-colorEditModal-variationColor {
        border: 0;
        padding-top: 0;

        .cc-quickUpdateModal-color-prices {
            display: none;
        }

        .cc-prices {
            display: none;
        }
    }

    .cc-color-swatch:not(:empty) {
        line-height: 0;
        margin-right: rem-calc(8);
    }

    .cc-colorEditModal-variationColor-swatches {
        display: inline-block;
    }

    .cc-sizeEditModal {
        &-dropdownMenu {
            margin: 0;
            display: flex;
            flex-flow: row wrap;
            gap: rem-calc(6);
            justify-content: flex-start;
            .size-button {
                width: calc(25% - (#{rem-calc(18)} / 4));
                margin: 0;
                min-width: unset;
                opacity: 1;
                border-color: $cloud;

                &--selected a {
                    color: $white;
                }

                &.disabled a {
                    color: $fossil;
                }
            }
        }
    }

    &-close {
        @include increaseButtonSize();
        cursor: pointer;
        line-height: 0;
        position: absolute;
        right: rem-calc(16);
        top: rem-calc(16);
        z-index: 1;

        &-icon {
            height: rem-calc(16);
            width: rem-calc(16);
            @include breakpoint(large up) {
                height: rem-calc(24);
                width: rem-calc(24);
            }
        }
    }

    &-image {
        width: 50%;
        margin-bottom: rem-calc(16);
        aspect-ratio: 0.6666666667;
        object-fit: cover;
        @include breakpoint(large up) {
            margin-bottom: rem-calc(32);
        }
    }

    &-accordionList {
        display: flex;
        flex-direction: column;
        gap: rem-calc(24);

        &.accordion.initialized .accordion-item {
            border-bottom: 0;
            .accordion-title {
                padding: rem-calc(11 16);
                border: rem-calc(1) solid $cloud;
                border-radius: rem-calc(4);
                display: flex;
            }
            .cc-colorEditModal {
                width: 100%;
            }
        }

        .cc-colorLabel {
            display: block;
            font-size: rem-calc(14);
            letter-spacing: rem-calc(0.56);
            margin-bottom: rem-calc(4);
        }

        .cc-quickUpdateModal-sizeWrapper-title.accordion-title {
            padding: rem-calc(0 0 16 0);

            &::after {
                display: none;
            }
        }
    }

    &-promo {
        margin-bottom: rem-calc(16);
        @include breakpoint(large up) {
            margin-bottom: rem-calc(24);
        }
    }

    &-confirmButton {
        padding: rem-calc(16 32);
        background-color: $white;
        border-top: rem-calc(1) solid $cloud;
        position: sticky;
        margin: rem-calc(0 -32);
        width: calc(100% + #{rem-calc(64)});

        @include breakpoint (large up) {
            padding: rem-calc(16);
        }

        &-btn {
            margin-bottom: rem-calc(16);
        }
    }

    .cc-hideInBonusProductModal {
        display: none;
    }

    .tingle-modal-box {

        &.minicart-style {
            @include breakpoint (large up) {
                width: rem-calc(382);
            }
        }
        
        &__content {
            @include breakpoint (small down) {
                overflow-x: hidden;
            }
            @include breakpoint(large up) {
                padding: rem-calc(48 32 0);
            }
        }
    }

    .choice-of-bonus-product {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
}
.cc-freeProductModal {
    z-index: $zIndex-layerAboveRightModal;

    &-text {
        cursor: pointer;
    }

    &-close {
        @include increaseButtonSize();
        position: absolute;
        top: rem-calc(16);
        right: rem-calc(16);
        line-height: 0;

        &-icon {
            width: rem-calc(16);
            height: rem-calc(16);
            @include breakpoint(large up) {
                width: rem-calc(24);
                height: rem-calc(24);
            }
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        @include breakpoint(large up) {
            flex-direction: row;
        }

        &-image {
            width: rem-calc(183);
            margin-bottom: rem-calc(16);
            @include breakpoint(large up) {
                margin-bottom: 0;
                width: rem-calc(289);
                flex: none;
            }
        }

        &-info {
            flex: 1 1 0;
            display: flex;
            flex-direction: column;
            @include breakpoint(large up) {
                padding: rem-calc(40 32 32);
            }
        }
    }

    &-title {
        margin-bottom: rem-calc(8);
        @include breakpoint(large up) {
            margin-bottom: rem-calc(16);
        }
    }

    &-description {
        margin-bottom: rem-calc(16);
        @include breakpoint(large up) {
            margin-bottom: rem-calc(32);
        }
    }

    &-button {
        margin-top: auto;
    }

    .tingle-modal-box {
        @include breakpoint(large up) {
            width: rem-calc(696);
        }
        &__content {
            padding: rem-calc(48 16 32);
            height: 100%;
            @include breakpoint(large up) {
                padding: 0;
            }
        }
    }
}

.cc-cart-bonusProductText {
    cursor: pointer;
}

.cc-omnibusStack {
    display: flex;
    flex-direction: column;

    &-item {
        font-size: rem-calc(10);
        line-height: rem-calc(15);
        color: $fossil;
    }

    &-strikeThrough {
        text-decoration: line-through;
    }

    &-sales {
        color: $cherry;
    }
}