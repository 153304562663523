body.tingle-enabled {
    position: absolute;
    overflow: hidden;
    left: 0;
    right: 0;
    top: 0 !important;
}

html.html-tingle-enabled {
    overflow: hidden;
}

.modal {
    display: none;
}

.tingle-modal {
    z-index: 1050;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    background: rgba($black, 0.4);
    cursor: default;
    transition: opacity 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
    &.tingle-modal--visible {
        visibility: visible;
        opacity: 1;
        .tingle-modal-box__footer {
            bottom: 0;
        }
        .tingle-modal-box {
            transform: translateY(0);
            opacity: 1;
        }
    }
    &__close,
    &__closeLabel {
        display: none;
    }
    &--noClose {
        &.tingle-modal__close {
            display: none;
        }
    }
    @include breakpoint(small down) {
        &.privacy-policy-marketing-popup {
            .form-request-title {
                width: 85%;
                padding-right: rem-calc(26);
            }
        }
    }
    @include breakpoint(small down) {
        &.privacy-policy-profiling-popup {
            .form-request-title {
                width: 85%;
                padding-right: rem-calc(26);
            }
        }
    }
}

.tingle-modal-box {
    position: relative;
    flex-shrink: 0;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
    max-width: rem-calc(700);
    max-height: 90vh;
    background: #fff;
    cursor: auto;
    overflow-y: auto;
    transform: translateY(50px);
    opacity: 0;
    transition: all 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
    &__content {
        border-radius: rem-calc(4);
        padding: rem-calc(16 16 0 16);
        width: 100%;
        @include breakpoint(large up) {
            padding: rem-calc(32 32 0 32);
        }
        .close {
            position: absolute;
            top: rem-calc(16);
            right: rem-calc(16);
            font-size: 2em;
            cursor: pointer;
        }
        button {
            margin-bottom: 0;
            &.js-apply-coupon {
                margin-bottom: rem-calc(42);
            }
        }
        .bottom-buffer {
            margin-bottom: 10vh;
        }
    }
    .modal__closeIcon {
        position: absolute;
        top: rem-calc(8);
        right: rem-calc(8);
        font-size: 2em;
        cursor: pointer;
        &.small_icon {
            width: rem-calc(13);
            height: rem-calc(13);
        }
    }
    &__footer {
        width: auto;
        cursor: auto;
        text-align: right;
        &:after {
            display: table;
            clear: both;
            content: '';
        }
        &--sticky {
            position: fixed;
            bottom: -200px; // TODO: find a better way
            z-index: 10001;
            opacity: 1;
            transition: bottom 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
        }
        button {
            margin-bottom: 0;
        }
    }

    &.rightModalAttribute {
        position: absolute;
        min-width: 100vw;
        max-height: 75vh;
        min-height: unset;
        bottom: 0;
        display: flex;
        @include breakpoint(large up) {
            min-height: 100%;
            min-width: unset;
            right: 0;
            transform: translateY(0) translateX(100%);
            transition: transform 0.6s ease;
            width: 100%;

            max-width: rem-calc(430);

            &_sizeSmall {
                max-width: rem-calc(382);
            }
            &_sizeMedium {
                max-width: rem-calc(430);
            }
            &_sizeLarge {
                max-width: rem-calc(528);
            }
            &_sizeXtraLarge {
                max-width: rem-calc(1548);
            }

            &.cc-modalClosing {
                transform: translateX(100%);
            }
        }
    }
}


.quickview-modal.quickview-modal--intimissimi {
    .tingle-modal-box {
        &.rightModalAttribute {
            bottom: unset;
            top: 0;
            height: 100%;
            max-height: 100%;
            width: rem-calc(430);
            max-width: 100%;
            min-width: rem-calc(430);
            right: 0;

            @include breakpoint(small down) {
                width: 100%;
                max-width: 100%;
                min-width: 100%;
            }
        }

        .tingle-modal-box__content {
            max-height: 100%;
            height: 100%;
            padding: rem-calc(64 0 32);

            @include breakpoint(small down) {
                padding: rem-calc(56 0 16);
            }

            .wrapper-images-carousel-quick {
                padding: rem-calc(0 0 0 32);

                @include breakpoint(small down) {
                    padding: rem-calc(0 0 0 16);
                }
            }

            .close-custom {
                width: rem-calc(24);
                height: rem-calc(24);
                top: rem-calc(16);
                right: rem-calc(16);
                padding: 0;
                position: absolute;

                svg {
                    width: rem-calc(24);
                    height: rem-calc(24);
                    color: $black;
                }
            }

            .product-quickview--get-product.product-quickview {
                height: 100%;
                padding-bottom: rem-calc(124);
                overflow-y: auto;

                .wrapper-quickview {
                    display: block;

                    .wrapper-images-carousel-quick,
                    .wrapper-product {
                        width: 100%;
                        max-width: 100%;
                    }

                    .wrapper-product {
                        padding: rem-calc(0 32);

                        @include breakpoint(small down) {
                            padding: rem-calc(0 16);
                        }
                    }

                    .wrapper-images-carousel-quick {
                        padding-right: 0;
                        padding-left: 0;
                        position: relative;
                        padding-bottom: rem-calc(32);

                        @include breakpoint(small down) {
                            padding-bottom: rem-calc(24);
                        }

                        .slick-list {
                            padding-left: rem-calc(32);
                            padding-right: rem-calc(24);

                            @include breakpoint(small down) {
                                padding-left: rem-calc(16);
                                padding-right: rem-calc(8);
                            }
                        }

                        .slick-track {
                            margin-left: 0;
                            margin-right: 0;
                            padding-left: 0;
                            padding-right: 0;
                        }
                    }
                }

                .product-content-size:not(.cc-sizeBox-panel) hr {
                    display: none;
                }

                .product-name,
                .marketing-messages,
                .product-main-price,
                .wishlist-button-wrapper {
                    display: none;
                }

                .update-cart-product-global {
                    color: $white;
                    margin-top: 0;
                }

                .cc-intProductDetail-colors-heading {
                    margin-bottom: 0;

                    .label {
                        font-size: rem-calc(14);
                        font-weight: 400;
                        line-height: rem-calc(21);
                        letter-spacing: 0.04em;
                        margin-bottom: rem-calc(4);
                    }
                }

                .selected-color-value {
                    display: block;

                    span {
                        font-size: rem-calc(16);
                        font-weight: 400;
                        line-height: rem-calc(24);
                        letter-spacing: 0.04em;
                    }
                }

                .pdp-size-box h4 {
                    margin-bottom: rem-calc(24);
                    letter-spacing: 0.04em;
                }

                .mobile-attributes-container {
                    .cc-intProductDetail-attributes-wrapper {
                        width: 100%;

                        &.cc-sales-attributes {
                            margin-top: 0;
                        }
                    }
                }

                .selected-size {
                    font-size: rem-calc(16);
                    line-height: rem-calc(24);
                    letter-spacing: 0.04em;
                }

                .margin-wrapper-description.show-for-large {
                    display: none;
                }

                .product-content-size {
                    .label-wrapper {
                        width: calc(25% - #{rem-calc(5)});
                        flex: 0 0 calc(25% - #{rem-calc(5)});
                        height: rem-calc(40);
                        border-radius: rem-calc(2);
                        overflow: hidden;
                    }

                    .radio-label {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        font-size: rem-calc(12);
                        font-weight: 400;
                        line-height: rem-calc(18);
                        letter-spacing: 0.04em;
                        border-radius: rem-calc(2);
                        border: 1px solid $border-lighter-gray;

                        svg {
                            margin-right: 0;
                        }
                    }
                }

                .product-content-size:not(.cc-sizeBox-panel) label.swatch-boolean input[type='radio']:checked+.radio-label {
                    border-color: $charcoal;
                }

                .selectSizeQuickView {
                    letter-spacing: 0.04em;
                }

                .slider-quick-modal {
                    margin-right: 0;
                }

                .primary-images .carousel-inner {
                    overflow: hidden;
                    display: block;

                    .carousel-item {
                        img {
                            @include breakpoint(medium down) {
                                height: auto;
                                width: rem-calc(269);
                                border-radius: rem-calc(4);
                                padding: 0;
                            }
                        }

                        .product-image {
                            margin: rem-calc(0 8 0 0);
                            display: block;

                            img {
                                border-radius: rem-calc(4);
                            }
                        }
                    }

                    .slick-track .carousel-item:first-child {
                        img {
                            padding-left: 0;
                        }
                    }
                }

                .product-content-size:not(.cc-sizeBox-panel) label:last-of-type {
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    display: block;
                }

                .wrapper-product {
                    padding-top: rem-calc(24);
                }

                .carousel-select-color {
                    padding-top: 0;
                    margin-bottom: rem-calc(24);

                    div {
                        margin-left: 1px;
                    }
                }

                .carousel-select-color, .carousel-select-size {
                    border-top: 0;
                    @include flexGenerator(column, flex-start);
                    gap: rem-calc(24);
                }

                .product-content-size {
                    border-bottom: 0 !important;
                }

                .color-variation {
                    width: rem-calc(24);
                    height: rem-calc(24);
                    position: relative;
                    margin: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .swatch-circle,
                    .swatch-rectangle,
                    .swatch-button {
                        width: rem-calc(18);
                        height: rem-calc(18);
                    }

                    .swatch-circle {
                        border-radius: rem-calc(10);
                        background-size: cover;
                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            border-radius: rem-calc(10);
                            border: 0;
                            outline: 0;
                            outline-offset: rem-calc(3);
                            outline: rem-calc(1) solid transparent;
                        }

                        &.selected {
                            border-color: $charcoal;
                            &:after {
                                border-radius: rem-calc(10);
                                border: rem-calc(2) solid $white;
                                outline-color: $charcoal;
                                box-shadow: 0;
                                border: 0;
                            }
                        }
                    }

                    .swatch-circle.unselectable {
                        cursor: unset;
                        opacity: 1;
                    }
                }

                .see-product-details {
                    text-align: center;
                    margin-top: rem-calc(16);

                    a {
                        text-transform: unset;
                        font-size: rem-calc(12);
                        font-weight: 400;
                        line-height: rem-calc(18);
                        letter-spacing: 0.04em;
                    }
                }

                .wrapper-inner-quick-bottom {
                    background-color: $white;
                    position: absolute;
                    width: 100%;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    padding: rem-calc(16);
                    border-top: 1px solid $border-lighter-gray;
                    z-index: 2;
                }

                .cc-intProductDetail-size-fewItems {
                    margin-bottom: rem-calc(24);
                    display: flex;
                    align-items: center;
                    @include breakpoint(large up) {
                        margin-bottom: rem-calc(16);
                    }
        
                    &::before {
                        content: '';
                        width: rem-calc(4);
                        height: rem-calc(4);
                        display: inline-block;
                        background-color: $mocha;
                        border-radius: 50%;
                        margin-right: rem-calc(4);
                    }
                }
            }
        }
    }

    .wrapper-inner-quick-quantity {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: rem-calc(2);
        border: 1px solid $border-lighter-gray;
        height: rem-calc(44);
        margin-right: rem-calc(8);

        button, svg {
            width: rem-calc(15);
            height: rem-calc(15);
            padding: rem-calc(0);
        }

        input {
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            letter-spacing: 0.04em;
            width: rem-calc(37);
            height: rem-calc(42);
            border: 0;
            padding: 0;
            margin: 0;
            text-align: center;

            &:focus {
                border: 0;
            }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }

        input[type=number] {
        -moz-appearance: textfield;
        }
    }
}
