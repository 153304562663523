@import "select";

[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
select,
select:not([multiple]),
textarea {
    background-color: $white;
    border: 1px solid $cloud;
    border-radius: rem-calc(2);
    padding: rem-calc(16 24);
    margin-bottom: rem-calc(8);
    height: unset;
    transition: all 0.25s ease-in-out;

    &, &::placeholder {
        color: $charcoal;
        font-size: rem-calc(16);
        line-height: rem-calc(24);
    }

    &.cc-isPrefilled:not(:focus):not(:disabled):not(.is-invalid-input) {
        background-color: $off-white;
    }

    &:focus {
        border: rem-calc(1) solid $charcoal;
        background-color: $white;
    }

    &:disabled {
        opacity: 1;
        background-color: $cloud;

        &, &::placeholder {
            color: $pebble;
        }
    }

    &.height-text-area {
        min-height: 7.76923rem;
        resize: none;
    }
}

.form-fields-item {
    &__label {
        position: relative;
        margin-bottom: rem-calc(8);
        background-color: $white !important;

        &.form-fields-item__label--select {
            display: flex;
            flex-direction: column;

            .chosen-container {
                order: 1;
            }

            .errors-list {
                order: 2;
            }
        }

        .toggle-password{
            cursor: pointer;
            position: absolute;
            top: rem-calc(49);
            right: rem-calc(24);
            background-image: url('../images/eye-closed.svg');
            width: rem-calc(24);
            height: rem-calc(24);
            z-index: 1;
            background-repeat: no-repeat;
            background-position: right;
            background-size: rem-calc(15);
            touch-action: manipulation;
            &--visible{
                background-image: url('../images/eye.svg');
            }
        }

        &.focused {
            .form-fields-item__clearIcon {
                opacity: 1;
            }
        }
    }

    &__clearIcon {
        content: url('../../images/ico-close.svg');
        cursor: pointer;
        position: absolute;
        right: rem-calc(16);
        top: 50%;
        @include fixWidth(rem-calc(16));
        @include fixHeight(rem-calc(16));
        opacity: 0;
        transition: opacity 0.25s ease-in-out;
        transform: translateY(-50%);
    }

    &__input-label {
        display: inline-block;
        margin-bottom: rem-calc(8);
        letter-spacing: 0.04em;
        color: $fossil;
    }

    &__helper-text {
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        color: $fossil;
        margin-bottom: rem-calc(4);

        &::before{
            width: rem-calc(16);
            height: rem-calc(16);
            background-size: rem-calc(14);
            background-repeat: no-repeat;
            display: inline-block;
            margin-right: rem-calc(7);
            top: rem-calc(5);
            position: relative;
        }

        &--pending {
            &::before{
                content: '';
                background: url('../images/chevrons-right.svg');
            }
        }

        &--valid {
            &::before{
                content: '';
                background: url('../images/circle-ok.svg');
            }
        }
    }
}

.form-control-label {
            display: inline-block;
        margin-bottom: rem-calc(8);
        letter-spacing: 0.04em;
        color: $fossil;
}

.label-inside {
    .input-label {
        position: absolute;
        left: $input-padding;
        top: 50%;
        transform: translateY(-50%);
        transition: all 250ms ease;
        pointer-events: none;
    }

    &.focused {
        .input-label {
            transform: none;
            left: 0;
            top: -40%;
            pointer-events: auto;
        }
    }
}

.form-error,
.invalid-feedback,
.unknown-email-domain {
    display: none;
    margin: rem-calc(-4 0 8);
    font-size: rem-calc(12);
    line-height: rem-calc(18);
    font-weight: $global-weight-normal;
    color: $charcoal;
}

.reset-password-email-error {
    line-height: rem-calc(18);
    font-size: rem-calc(12);
    font-weight: $global-weight-normal;
    letter-spacing: rem-calc(0.48);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: rem-calc(8);
    margin-top: rem-calc(4);

    .cc-orderCancellation-feedbackMessage_icon {
        width: rem-calc(16);
        height: rem-calc(16);
    }
}

.errors-list {
    &.filled {
        .form-error::before{
            content: '';
            background: url('../images/error.svg');
            width: rem-calc(16);
            height: rem-calc(16);
            background-size: rem-calc(14);
            background-repeat: no-repeat;
            display: inline-block;
            margin-right: rem-calc(7);
            top: rem-calc(4);
            position: relative;
        }
    }
}

input.is-invalid:not(:focus),
select.is-invalid:not(:focus),
textarea.is-invalid:not(:focus) {
    background-color: $white !important;
    border: 1px solid $failed;
    &, &::placeholder {
        color: $charcoal;
    }
}

select {

    option {
        &:checked,
        &:hover {
            box-shadow: 0 0 10px 100px $black inset;
        }
    }

    &.is-invalid + .chosen-container .chosen-single {
        border: 1px solid $failed;
        background: $white;
        color: $charcoal;
    }

    #checkout-main & {
        &#shippingState,
        &#billingState {
            &.is-invalid-input + .chosen-container:not(.chosen-with-drop) .chosen-single {
                border: 1px solid $failed;
                background: $white;
            }
        }
    }
}

label {
    &.inline {
        display: inline-block;
        margin-right: rem-calc(32);
    }
}

.is-invalid-label {
    select,
    input {
        background-color: $white !important;
        border: 2px solid $alert;
        margin-bottom: rem-calc(15);
    }
}

.parsley-error:not(:focus){
    border: rem-calc(1) solid $failed;
}

.parsley-errors-list {
    list-style-type: none;
    margin-left: 0;

    li {
        margin: rem-calc(4 0 8);
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        letter-spacing: rem-calc(0.48);
        font-weight: $global-weight-normal;
        color: $charcoal;
        &::before{
            content: '';
            background: url(../../images/error.svg);
            width: rem-calc(16);
            height: rem-calc(16);
            background-size: rem-calc(14);
            background-repeat: no-repeat;
            display: inline-block;
            margin-right: rem-calc(7);
            top: rem-calc(4);
            position: relative;
        }
    }
}

.parsley-errors-list{
    &.filled{
        .parsley-required{
            &::before{
                content: '';
                background: url(../../images/error.svg);
                width: rem-calc(16);
                height: rem-calc(16);
                background-size: rem-calc(14);
                background-repeat: no-repeat;
                display: inline-block;
                margin-right: rem-calc(7);
                top: rem-calc(4);
                position: relative;
            }
        }
    }
}

// Input Autocomplete
.autocomplete {
    /*the container must be positioned relative:*/
    position: relative;
    display: inline-block;

    input {
        margin-bottom: 0;
    }

    .parsley-errors-list {
        margin-top: $input-padding;
    }

    .autocomplete-items {
        position: relative;
        border: 1px solid $black;
        border-bottom: none;
        border-top: none;
        z-index: 101;
        /*position the autocomplete items to be the same width as the container:*/
        top: 100%;
        left: 0;
        right: 0;
    }

    .autocomplete-items div {
        padding: rem-calc(8);
        cursor: pointer;
        background-color: $white;
        border-bottom: 1px solid $black;
    }

    .autocomplete-items div:hover {
        /* When hovering an item */
        background-color: $more-light-gray;
    }

    .autocomplete-active {
        /* When navigating through the items using the arrow keys */
        background-color: $medium-gray !important;
        color: $white;
    }
}

input.fiscalCode {
    cursor: pointer;
}
#fiscalCodePT {
    cursor: initial;
}

.change-email-form-error {
    margin-top: -0.75rem;
    margin-bottom: 1.5rem;
    margin-left: 0;
    font-size: rem-calc(12);
    font-weight: $global-weight-normal;
    color: $alert;
}