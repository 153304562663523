.cc-breadcrumb {
    padding: rem-calc(20);
    list-style: none;
    margin: 0;
    display: flex;
    align-items: center;
    text-decoration: none;

    &-item {
        display: inline;
        font-size: rem-calc(12);
        line-height: rem-calc(18);
    }

    &-divider {
        width: rem-calc(4);
        height: rem-calc(4);
        background-color: $fossil;
        border-radius: 50%;
        margin: rem-calc(0 8);
        display: inline-block;
    }
}