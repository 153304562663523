.cc-scrollTopIcon {
    width: rem-calc(30);
    height: rem-calc(30);
    background: $black;
    border: rem-calc(1) solid $black;
    position: fixed;
    bottom: rem-calc(20);
    right: rem-calc(20);
    padding: rem-calc(9);
    font-size: rem-calc(20);
    line-height: rem-calc(10);
    cursor: pointer;
    z-index: 100;
    color: $white;
    &:hover {
        color: $white;
    }
}

// In the following pages the scroller should be hidden:
//  - PDP Mobile (overlaps the Add to Cart button)
//  - Cart Mobile/Desktop (overlaps the Checkout button)
//  - Checkout Mobile (overlaps the buttons)
@include breakpoint(small down) {
    .page[data-action="Product-Show"] + a.cc-scrollTopIcon,
    .page[data-action="Checkout-Begin"] a.cc-scrollTopIcon {
        display: none;
    }
}

.page[data-action="Cart-Show"] a.cc-scrollTopIcon {
    display: none;
}
