// MEDIA AVATAR images and text
.media {
    display: flex;
    align-items: flex-start;
    background: $off-white;
    padding: rem-calc(24 16);
    margin: rem-calc(0 8 24 8);
    border-radius: rem-calc(4);
    @include breakpoint (large up){
        margin: rem-calc(0 0 24 0);
    }

    a {
        font-weight: 500;
        display: inline;
    }

    img {
        margin: rem-calc(7 7 0 0);
    }

    svg {
        width: rem-calc(16);
        height: rem-calc(16);
        margin: rem-calc(0 6 0 0);
    }

    // CSS related with content asset
    .align-self-center {
        align-self: center !important;
    }

    .mr-7 {
        margin-right: rem-calc(7);
    }

    .media-body {
        flex: 1;
        margin-left: rem-calc(10);
    }

    .media-title {
        text-align: left;
        margin: 0;
    }

    span {
        margin-bottom: 0;
    }

}

.cc-eGiftCard{
    &-imageInfo{
        .media{
            @include breakpoint (large up){
                margin: rem-calc(0 0 24 0);
            }
        }
    }
}