.cc-registrationModal {
    .tingle-modal-box {
        @include breakpoint(medium up) {
            transform: translate(100%, 0);
        }
    }
    &.tingle-modal--visible .tingle-modal-box {
        @include breakpoint(medium up) {
            transform: translate(0, 0);
        }
    }

    .cc-remembermeToggler {
        flex: initial;
        padding-right: 0;

        .toggleButton__label {
            letter-spacing: 0.04em;
        }
    }

    .tingle-modal-box__content {
        position: relative;

        .modal-title-for-login.cc-registrationModal__headTitle {
            padding-left: rem-calc(40);
        }
    }

    .registration-modal {
        padding-bottom: rem-calc(48);
    }

    &__back {
        font-size: 0;
        min-height: 0;
        height: auto;
        padding: 0;
        margin: 0;
        position: absolute;
        top: rem-calc(59);
        left: rem-calc(16);

        @include breakpoint(large up) {
            top: rem-calc(51);
            left: rem-calc(32);
        }
    }

    &__body {
        margin-top: 0;
        padding: 0;
    }

    &__formBtn {
        margin-top: rem-calc(32);

        @include breakpoint(large up) {
            margin-top: rem-calc(24);
        }

        &--register {
            margin-top: rem-calc(40);

            .button {
                margin-bottom: rem-calc(16);
            }
        }

        &--loyalty {
            margin-top: 0;
        }

        &--loyaltyIntro {
            text-align: center;

            .button--tertiary {
                margin: rem-calc(8 0 0);
            }
        }
    }

    &__title {
        display: block;
        font-size: rem-calc(16);
        line-height: rem-calc(24);
        letter-spacing: 0.04em;
        color: $charcoal;
        text-align: left;
        margin: rem-calc(0 0 8);
    }

    &__description {
        display: block;
        font-size: rem-calc(14);
        line-height: rem-calc(21);
        letter-spacing: 0.04em;
        color: $charcoal;
        text-align: left;
        margin: rem-calc(0 0 8);
    }

    &__formWrp {
        margin-top: rem-calc(32);

        @include breakpoint(large up) {
            margin-top: rem-calc(24);
        }
    }

    .form-fields-item__label {
        margin-bottom: rem-calc(24);
    }

    &__cardFields {
        display: flex;
        flex-direction: row;
        margin: rem-calc(0 -8);
    }

    &__cardField {
        padding: rem-calc(0 8);
        flex: 1;

        &--pin {
            width: rem-calc(118);
            flex: 0 0 rem-calc(118);

            @include breakpoint(large up) {
                width: rem-calc(99);
                flex: 0 0 rem-calc(99);
            }
        }
    }

    &__phoneFields {
        display: flex;
        flex-direction: row;
        margin: rem-calc(0 -8);

        .form-fields-item__clearIcon {
            top: rem-calc(58) !important;
        }
    }

    &__phoneField {
        padding: rem-calc(0 8);
        flex: 1;

        &--prefix {
            width: rem-calc(118);
            flex: 0 0 rem-calc(118);

            @include breakpoint(large up) {
                width: rem-calc(99);
                flex: 0 0 rem-calc(99);
            }

            [type='text'].cc-isPrefilled:not(:focus):not(:disabled) {
                background: transparent !important;
            }

            .form-fields-item__input-label {
                white-space: nowrap;
                text-overflow: ellipsis;
                display: block;
                overflow: hidden;
            }
        }
    }

    &__infoRecap {
        display: block;
        margin: rem-calc(8 0 0);

        @include breakpoint(large up) {
            margin-top: 0;
        }

        &--final {
            margin-top: rem-calc(8);
        }

        &__label {
            display: block;
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            letter-spacing: 0.04em;
            text-align: left;
            color: $fossil;
            margin: 0;

            @include breakpoint(large up) {
                margin-bottom: rem-calc(4);
            }
        }

        &__value {
            display: block;
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            letter-spacing: 0.04em;
            text-align: left;
            margin: 0;
        }
    }

    &__infoTooltip.info-icon {
        position: absolute;
        top: rem-calc(50);
        right: rem-calc(24);

        .tooltip {
            transform: translate(-87%, -110%);
        }

        &.js-password-tooltip {
            width: rem-calc(64);
            height: rem-calc(56);
            z-index: 2;
            top: rem-calc(29);
            right: 0;

            .icon-info-black {
                width: rem-calc(64);
                height: rem-calc(56);
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
            }
        }
    }

    &__rememberMe {
        margin: rem-calc(24 0 32);

        @include breakpoint(large up) {
            margin: rem-calc(32 0);
        }
    }

    .policy-register-form {
        padding: rem-calc(32 0);
        border-top: rem-calc(1) solid $cloud;
        border-bottom: rem-calc(1) solid $cloud;
        margin-bottom: rem-calc(32);

        .prefill-privacy-flag {
            font-size: rem-calc(12);
            margin-bottom: rem-calc(32);
        }
    }

    &__finalFieldWrp {
        .form-fields-item__label {
            margin-bottom: rem-calc(32);

            @include breakpoint(large up) {
                margin-bottom: rem-calc(16);
            }
        }

        .chosen-container {
            margin-bottom: 0;
        }

        select.is-invalid-input:not(:focus) ~ .chosen-container .chosen-single{
            background-color: #fceaea !important;
            border: 1px solid $failed;
            &, &::placeholder {
                color: $charcoal;
            }
        }

        select {
            &.is-invalid-input ~ .chosen-container .chosen-single {
                border: 1px solid $failed;
                background: #fceaea;
                color: $charcoal;
                margin-bottom: rem-calc(8);
            }
        }
    }

    &__radio {
        &__text {
            display: block;
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            letter-spacing: 0.04em;
            text-align: left;
            margin: rem-calc(0 0 16);
        }

        &__wrp {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            margin: rem-calc(0 0 24);

            label {
                padding-right: rem-calc(64);
                flex: inherit;
                margin: 0;

                &:last-child {
                    padding-right: 0;
                }
            }

            &--loyalty {
                label {
                    padding-right: rem-calc(16);

                    &:last-child {
                        padding-right: 0;
                    }
                }
            }

            .errors-list {
                order: 1;
                width: 100%;
                margin-top: rem-calc(8);
            }
        }

        &__privacyPolicy {
            display: block;
            font-size: rem-calc(10);
            line-height: rem-calc(15);
            letter-spacing: 0.04em;
            text-align: left;
            margin: 0;

            a {
                color: inherit;
                font: inherit;
                text-decoration: underline;
            }
        }
    }

    &__loyalty {
        padding: rem-calc(24 16);
        background: $off-white;
        border: rem-calc(1) solid $dijon;
        border-radius: rem-calc(4);

        &__titleMain {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0;
        }
        .loyalty{
            &.policy-promotion-loyalty {
                border: none;
                padding: 0;
                margin: 0;
                .cc-registrationModal__loyalty__titleMain{
                    margin-bottom: rem-calc(16);
                }
                .my-intimissimi-card-register{
                    @include breakpoint(medium down) {
                        padding: 0;
                        margin-bottom: rem-calc(16);
                    }
                }
                & .text-register-privacy-policy {
                    &.loyalty-condition{
                        margin-bottom: rem-calc(16);
                    }
                }
                & .text-register-privacy-policy, & .cc-opposition_checkBox{
                    & .cc-toggleColor {
                        @include breakpoint(large up) {
                            color: $charcoal;
                        }
                    }
                }
            }
        }
        .cc-myIcon {
            display: block;
            flex: rem-calc(0 0 48);
            width: rem-calc(48);
            font-size: 0;

            svg {
                width: rem-calc(48);
                height: rem-calc(48);
            }
        }

        &__titleMainText {
            display: block;
            font-size: rem-calc(18);
            line-height: rem-calc(24);
            text-align: left;
            color: $dijon;
            flex: 1;
            margin: 0;
            padding-left: rem-calc(12);
        }

        &__body {
            margin-top: rem-calc(16);
        }

        &__title {
            display: block;
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            letter-spacing: 0.04em;
            color: $charcoal;
            text-align: left;
            margin: rem-calc(0 0 8);

            @include breakpoint(large up) {
                font-size: rem-calc(16);
                line-height: rem-calc(24);
            }
        }

        &__text {
            display: block;
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            letter-spacing: 0.04em;
            color: $charcoal;
            text-align: left;
            margin: rem-calc(0 0 16);

            a {
                font: inherit;
                color: inherit;
                text-decoration: underline;
            }
        }

        &__opposition {
            margin: 0;

            .checkbox-label {
                flex: initial;
                padding-right: rem-calc(8);
            }

            .info-icon {
                font-size: 0;
                line-height: 0;

                .tooltip {
                    transform: translate(-30%, -110%);
                }
            }
        }

        &__oppositionFinal {
            margin-top: rem-calc(32);
        }
        .login-datepicker{
            margin-bottom: rem-calc(16);
            .cc-toggleBorder{
                background-color: $off-white;
            }
        }
    }

    .cc-ty {
        &__icon {
            text-align: center;
            margin: rem-calc(0 0 16);

            .cc-icon {
                width: rem-calc(24);
                margin: 0 auto;
                font-size: 0;
            }
        }

        &__title {
            display: block;
            font-size: rem-calc(24);
            line-height: rem-calc(32);
            text-align: center;
            margin: rem-calc(0 0 16);
        }

        &__text {
            display: block;
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            letter-spacing: 0.04em;
            text-align: center;
            margin: 0;
        }

        .cc-registrationModal__formBtn {
            margin: rem-calc(40 0 0);
        }
    }

    &__dob {
        &.form-fields-item__label {
            background: transparent !important;
        }

        .icon-datepicker {
            position: absolute;
            top: rem-calc(50);
            right: rem-calc(24);
            width: rem-calc(16);
            height: rem-calc(16);
        }

        input {
            background: transparent;
        }
    }

    &__disclaimer {
        display: block;
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        letter-spacing: 0.04em;
        text-align: left;
        margin: rem-calc(32 0 -8);

        a {
            font-weight: $global-weight-normal;
            color: inherit;
            font: inherit;
        }
    }

    &__waiting {
        .opt-subtitle {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: rem-calc(0 0 8);
            font-size: 0;

            .opt-subtitle-icon {
                width: rem-calc(24);
            }

            .opt-subtitle-text {
                display: block;
                flex: 1;
                font-size: rem-calc(16);
                line-height: rem-calc(24);
                letter-spacing: 0.04em;
                text-align: left;
                padding-left: rem-calc(8);
                margin: 0;
            }
        }

        .opt-para {
            display: block;
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            letter-spacing: 0.04em;
            text-align: left;
            margin: rem-calc(0 0 32);
        }

        .opt-in-inputs {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin: rem-calc(0 -4 32);

            input {
                display: block;
                width: 100%;
                height: rem-calc(54);
                border: rem-calc(1) solid $pebble;
                border-radius: rem-calc(4);
                font-size: rem-calc(28);
                line-height: rem-calc(54);
                text-align: center;
                background: $off-white;
                margin: rem-calc(0 4);
                padding: 0;
                -moz-appearance: textfield;

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }

            &.invalid input {
                border-color: $apple;
            }
        }

        .opt-error {
            font-size: 0;
            text-align: left;
            color: $charcoal;
            margin: rem-calc(-28 0 28);

            .cc-iconError {
                display: inline-block;
                vertical-align: middle;
                margin-right: rem-calc(8);
            }

            span {
                display: inline-block;
                vertical-align: middle;
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                letter-spacing: 0.04em;
            }
        }
    }

    &--loyalty {
        .cc-registrationModal {
            &__description {
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                margin: rem-calc(0 0 16);

                a {
                    font: inherit;
                    color: inherit;
                    font-weight: $global-weight-normal;
                    text-decoration: underline;
                }

                &--loyaltyIntro {
                    margin-bottom: rem-calc(32);
                }

                &--loyalty {
                    font-size: rem-calc(14);
                    line-height: rem-calc(21);
                    margin-bottom: rem-calc(24);
                }
            }
        }
    }
}

.cc-loyaltyModal {
    &__features {
        padding: rem-calc(24 24 24 16);
        background: $off-white;
        border: rem-calc(1) solid $dijon;
        border-radius: rem-calc(4);
        margin: rem-calc(0 0 32);

        &__titleWrp {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0;
        }

        img {
            display: block;
            flex: rem-calc(0 0 48);
            width: rem-calc(48);
        }

        &__title {
            display: block;
            font-size: rem-calc(18);
            line-height: rem-calc(24);
            text-align: left;
            color: $dijon;
            flex: 1;
            margin: 0;
            padding-left: rem-calc(12);
        }

        &__text {
            display: block;
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            letter-spacing: 0.04em;
            text-align: left;
            margin: rem-calc(16 0);
        }

        &__item {
            display: flex;
            align-items: center;
        }

        &__list {
            margin: 0;
            padding: 0;

            li {
                padding: 0;
                margin: rem-calc(0 0 8);
                list-style-type: none;

                &:last-child {
                    margin: 0;
                }
            }

            img {
                display: block;
                width: rem-calc(20);
                height: rem-calc(20);
                margin: 0;
                flex: 0 0 rem-calc(20)
            }

            span {
                display: block;
                flex: 1;
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                letter-spacing: 0.04em;
                text-align: left;
                margin: 0;
                padding-left: rem-calc(8);
            }
        }
    }

    .cc-registrationModal {
        &__title {
            margin-top: rem-calc(32);
        }
    }
}

.tingle-modal {
    &.privacy-policy-marketing-popup,
    &.privacy-policy-profiling-popup {
        z-index: 2063;
    }
}
