.accordion,
.accordion-pdp {
    background: $white;
    list-style-type: none;
    margin: 0;

    &[disabled] &-title {
        cursor: not-allowed;
    }

    &-content {
        border: 0;
        display: none;
        padding: rem-calc(0 16 16);
    }

    &.initialized & {
        &-title {
            border: 0;
            cursor: pointer;
            display: block;
            margin: 0;
            padding: rem-calc(16);
            position: relative;

            &::after {
                content: url('../images/chevron-down.svg');
                line-height: 0;
                position: absolute;
                right: rem-calc(16);
                top: 50%;
                transform-origin: top;
                transform: translateY(-50%);
                transition: transform 0.4s ease-in-out;
            }
        }
        &-item {
            border-bottom: rem-calc(1) solid $cloud;
            &.active {
                .accordion-title {
                    &::after {
                        transform: rotateX(180deg) translateY(-50%);
                    }
                }
            }
        }
    }

    &.initialized.coupon-code & {
        &-title {
            &::after {
                right: 0;
            }
        }
    }

    &_details {
        &.initialized & {
            &-item {
                border-bottom: 0;
            }
            &-title {
                display: flex;
                align-items: center;
                cursor: pointer;
                @include breakpoint(large up) {
                    padding: rem-calc(24);
                    &::after {
                        content: none;
                    }
                }

                &_findInStore {
                    &::after {
                        content: none;
                    }
                }

                &-h3 {
                    display: flex;
                    align-items: center;
                    gap: rem-calc(10);
                }
            }
        }
    }

    &_customerLinks {
        margin-bottom: rem-calc(24);

        &:last-child {
            .accordion-item {
                border-bottom: 0;
            }
        }

        &.initialized & {
            &-title {
                padding: 0;
                margin-bottom: rem-calc(16);

                &::after {
                    right: 0;
                }
            }
            &-content {
                padding: rem-calc(0 0 16);
            }
        }
    }
}
