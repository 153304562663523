.inner-price-container {
    @include flexGenerator(row, flex-start, center);
    flex-wrap: wrap;
    gap: rem-calc(8);

    .discount-percentage {
        white-space: nowrap;
    }

    &.double-currency-clearfix {
        display: inline-block;
    }
}

.cc-priceSecondCurrency {
    @include flexGenerator(row, flex-start, center);
    gap: rem-calc(8);
    &-sales {
        color: $cherry;
    }
    &_inverted {
        flex-direction: row-reverse;
    }
}

.sales {
    &.sale {
        color: $sales;
    }
    &.loyalty {
        color: $sales;
    }
    &.loyaltysale {
        color: $loyalty;
    }
}

.cc-priceOmnibusLabel {
    font-size: rem-calc(10);
    line-height: rem-calc(15);
    color: $fossil;

    p.price-info {
        color: $fossil;
        margin: 0;
        display: inline-block;
        padding-left: rem-calc(2);
    }
}