@import "../setup/settings";

// Foundation cell modifiers
.cell {
    &--border-bottom {
        border-bottom: 1px solid $border-gray;
    }

    &--border-left {
        border-left: 1px solid $border-gray;
    }
}
